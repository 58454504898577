import { StarFilled } from "@ant-design/icons";
import { Button, Modal, Result, Skeleton } from "antd";
import React from "react";
import { useState } from "react";
import { Images } from "../../Images";

const CasioCardGameModal = (props) => {
  const [resize, setresize] = useState(true);
  const { result, gameName, showgameUrl, fullscreen } = props;

  const Title = () => {
    return (
      <>
        <div className="resize-btn">
          <img
            src={Images.resize}
            width="20px"
            onClick={() => setresize(!resize)}
            alt=""
          />
        </div>
        {resize ? (
          !fullscreen ? (
            <div className="text-center game-modal-header">
              <h1>{result?.name ? result?.name : gameName || "DEMO NAME"}</h1>
              <div className="d-flex justify-content-center align-items-center">
                {Array.from(Array(result?.rating || 5).keys()).map(() => {
                  return (
                    <>
                      <StarFilled style={{ color: "yellow", padding: "2px" }} />
                    </>
                  );
                })}
                <p className="mb-0">{result?.rating || "5.0"}</p>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <Modal
      visible={props.gamemodel}
      title={<Title />}
      footer={null}
      destroyOnClose={true}
      height={resize ? "100%" : "788px"}
      className="casino-play-now"
      onCancel={() => props.setgamemodel(false)}
    >
      <div className="casino-game-modal position-relative">
        <div className="text-center p-1 m-auto">
          {showgameUrl ? (
            showgameUrl?.ResponseCode === 0 ? (
              <iframe
                src={showgameUrl?.ResponseObject}
                className="casino-game-modal-iframe"
                width="100%"
                // height={resize ? "672px" : "788px"}
                title="Games"
              ></iframe>
            ) : (
              <Result
                status="500"
                title={
                  <span className="text-light">{showgameUrl?.Description}</span>
                }
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      window.location.reload("/");
                    }}
                  >
                    Try again
                  </Button>
                }
              />
            )
          ) : (
            <Skeleton
              title={"loading..."}
              size={"large"}
              rows={10}
              active
              round={true}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CasioCardGameModal;
