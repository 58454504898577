import { useContext, useState } from "react";
import BasicLayout from "../../../OceanBetBasicLayout";
import CasherNav from "../context/context";
import NavBar from "../_sharedComponents/NavBar/NavBar";
import AccVer from "./AccVer/AccVer";
import MainSection from "./MainSection/MainSection";
import "./profile.css";
import RightSection from "./RightSection/RightSection";
const Profile = () => {
  const { profileUrl } = useContext(CasherNav);
  const [editStatus, setEditStatus] = useState(false);
  const [settingSelect, setSettingSelect] = useState(true);
  const [navMobile, setNavMobile] = useState(true);

  const handleEditStatus = () => {
    setEditStatus((prev) => !prev);
  };

  const main = () => {
    if (profileUrl === "Settings" || profileUrl === "Responsible Gaming")
      return (
        <div className="profile-container gap-4">
          <MainSection
            editStatus={editStatus}
            handleEditStatus={handleEditStatus}
            settingSelect={settingSelect}
            setSettingSelect={setSettingSelect}
            navMobile={navMobile}
            setNavMobile={setNavMobile}
          />
          <RightSection
            editStatus={editStatus}
            handleEditStatus={handleEditStatus}
            settingSelect={settingSelect}
            setSettingSelect={setSettingSelect}
          />
        </div>
      );
    if (profileUrl === "AccountVerification")
      return <AccVer navMobile={navMobile} setNavMobile={setNavMobile} />;
  };

  return (
    <BasicLayout>
      <div className="main-container">
        <NavBar />
        <div>{main()}</div>
      </div>
    </BasicLayout>
  );
};

export default Profile;
