import { put, takeLatest, call } from "redux-saga/effects";
import { headeractions } from "../types";
import headerApi from "../api";
import { getError } from "../../../utils/helper";

function* getClientBalance(action) {
  try {
    const response = yield call(headerApi.getClientBalance, action.payload);
    yield put({
      type: headeractions.GET_CLIENT_BALANCE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in getClientBalance saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: headeractions.GET_CLIENT_BALANCE_ERROR, payload: customizeError });
    return false;
  }
}

export function* headerSaga() {
  yield takeLatest(headeractions.GET_CLIENT_BALANCE, getClientBalance);
}
