import "./forms.css";
import { Images } from "../../../../Images";
import { useState } from "react";

const Forms = ({ formEl, selected, setSelected }) => {
  const [open, setOpen] = useState(false);
  const { path } = Images;

  const displayForm = (
    <div onMouseLeave={() => setOpen(false)}>
      <div className="title_btn-container position-relative">
        <div className="title">{formEl.label}</div>
        <div onClick={() => setOpen(true)} className="dropdown">
          <div className="dropbtn">
            <div className="d-flex justify-content-between" data-e2e={selected}>
              {selected}
              <div>
                <img className="path-img" src={path} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`${open ? "opt-open" : ""} dropdown-content right-0`}>
          {formEl.option?.map((opt, i) => (
            <div key={i} onClick={() => setSelected(opt)} className="opt">
              {opt}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex gap-5 justify-content-between">
      <div className="d-flex">{displayForm}</div>
    </div>
  );
};

export default Forms;
