import React from "react";
import { privacyPolicy } from "../lib/constants";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";

const PrivacyPolicy = () => {
  return (
    <CommonFooterTermsAndConditions title={privacyPolicy[0]?.title} data={privacyPolicy} />
  );
};

export default PrivacyPolicy;
