import BasicLayout from "../../../OceanBetBasicLayout";
import { aboutUsContent } from "../lib/constants";
import "./AboutUs.css"

const NewAboutUs = () => {
  return (
    <BasicLayout>
      <div className="term-main-container">
          <div className="about-header term-title">About us</div>
          <div className="term-terms">
            {aboutUsContent?.map((content) => {
              return <p>{content}</p>;
            })}
          </div>
      </div>
    </BasicLayout>
  );
};

export default NewAboutUs;
