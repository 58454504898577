import { Layout } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";
import "../assets/css/custom.css";
import FooterComponent from "../Components/Footer/Footer";
import MobileNavbar from "../Components/Navbar/MobileNavbar/MobileNavbar";
import HeaderComponent from "../Components/Navbar/Navbar";
import { LogoComponent } from "./Logo";
import { SidebarMenuItemsWithRouter } from "./SidebarMenuWithRouter";
import LayoutComponentStyles, { LogoComponentStyle } from "./style";
import "./style.css";
const { Content, Sider } = Layout;

class OPLayout extends React.Component {
  state = {
    collapsed: false,
    contentMargin: 253,
  };

  renderLogo = () => (
    <>
      <LogoComponent style={LogoComponentStyle} />
    </>
  );
  renderSider = () => {
    return (
      <Sider
        id="sidebar-menu"
        className="sider-menu"
        width={120}
        style={LayoutComponentStyles.fixedSider}
        collapsible={false}
      >
        {this.renderLogo()}
        <SidebarMenuItemsWithRouter />
      </Sider>
    );
  };
  renderPageHeader = () => {
    const { history } = this.props;

    return <HeaderComponent history={history} />;
  };
  renderPageFooter = () => {
    return <FooterComponent />;
  };
  renderContent = () => {
    const { children } = this.props;
    return (
      <>
        <div id="main-content" style={{ marginTop: "59px" }}>
          <MobileNavbar />
          <Content>{children}</Content>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Layout style={LayoutComponentStyles.parentLayout} className="sidebar">
          {this.renderSider()}
          <Layout
            style={LayoutComponentStyles.mainLayout}
            className="hide-scrollbar main-layout-margin"
          >
            {this.renderPageHeader()}
            {this.renderContent()}
            {this.renderPageFooter()}
          </Layout>
        </Layout>
      </>
    );
  }
}

export default OPLayout;
