import { t } from "i18next";
import { currency_symbols } from "../../../../../../Components/Navbar/constantNavBar";
import { getItemFromLocalStorage } from "../../../../../../utils/localStorage";
const Balances = ({ i, value }) => {
  const { title, total, available } = value;
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  return (
    <div
      className={`${
        i === 3 ? "" : "bln-brd"
      } balance-container d-flex flex-column`}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <div className={`with-title ${i !== 0 ? "mt-2" : ""}`}>{title}</div>
        <div className="with-title">{total}{currency_symbols[CurrencyId] || "€"}</div>
      </div>
      <div className="d-flex align-items-baseline justify-content-between">
        <div className={`text-green ${i !== 0 ? "mt-2" : ""}`}>{t('Available')}</div>
        <div className="text-green">{available}{currency_symbols[CurrencyId] || "€"}</div>
      </div>
      {i === 0 && <div className="unused">{t('Unused')}</div>}
    </div>
  );
};

export default Balances;
