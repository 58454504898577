import { Tabs } from "antd";
import { useEffect, useState } from "react";
import BasicLayout from "../../../OceanBetBasicLayout";
import "./Support.css";
import ContactSocial from "./SupportRigth/ContactSocial/ContactSocial";
import SupportRight from "./SupportRigth/SupportRight";

const Support = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [mobileView, setmobileView] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth < 500) {
      setmobileView(true);
    } else {
      setmobileView(false);
    }
  }, [windowSize.innerWidth]);
  return (
    <BasicLayout>
      {mobileView ? (
        <div className="main-support">
          <div className="title-main-support">Support</div>
          <div className="px-4 pb-4">
            <Tabs defaultActiveKey="1">
              {/* <Tabs.TabPane tab="Contact Form" key="1">
                <SupportLeft />
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="Contact details" key="1">
                <ContactSocial />
              </Tabs.TabPane>
              <Tabs.TabPane tab="FAQ" key="2">
                <SupportRight />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      ) : (
        <div className="main-support">
          <div className="title-main-support"></div>
          <div className="row m-0 ">
            <div className="col-12 col-md-12 col-lg-8">
              <div className="row support-right">
                <div className="col-12 px-0">
                  <SupportRight />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="contact-details">Contact details</div>
                  <ContactSocial />
            </div>
          </div>
        </div>
      )}
    </BasicLayout>
  );
};

export default Support;

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
