import CasherNav from "../context/context";
import { useContext, useEffect, useState } from "react";
import MainHistory from "../History/Bets/Main/MainHistory";
import "./Balance.css";
import { Images } from "../../../Images";
import { t } from "i18next";
import { useSelector } from "react-redux";
import BasicLayout from "../../../OceanBetBasicLayout";
import NavBar from "../_sharedComponents/NavBar/NavBar";

const Balance = (props) => {
  const { balanceUrl, setNavURL } = useContext(CasherNav);
  const[check,setcheck]=useState(false)
  const { info } = Images;
  const [clientBalance, setclientBalance] = useState([]);
  const Balance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data?.Balances
  );

  const handleBalance = (e) => {
    let balance = {};
    switch (e.TypeId) {
      case 1:
        balance.type = "Unused Balance";
        balance.balance = e.Balance;
        break;
      case 2:
        balance.type = "Used Balance";
        balance.balance = e.Balance;
        break;
      case 3:
        balance.type = "Booking Balance";
        balance.balance = e.Balance;
        break;
      case 15:
        balance.type = "Affiliate Manager Balance";
        balance.balance = e.Balance;
        break;
      case 12:
        balance.type = "Bonus Balance";
        balance.balance = e.Balance;
        break;
      default:
    }
    setclientBalance((prevState) => [...prevState, balance]);
    setcheck(true)
  };


  useEffect(() => {
    // debugger
    if(!check){
    Balance?.map((e) => handleBalance(e));
    }
    //eslint-disable-next-line
  }, [Balance]);


  const navElBalance = [
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Balance"),
      dataIndex: "balance",
      key: "Balance",
      render: (text) => <p>{text}</p>,
    },
  ];
  const main = () => {
    if (balanceUrl === "Balance")
      return (
        <>
          <div className="d-flex flex-column gap-4">
            <div className="title-border"></div>
          </div>
          <MainHistory
            empty={"Balance-empty"}
            navEl={navElBalance}
            Payhistory={clientBalance}
          />
        </>
      );
  };

  return (
    <BasicLayout>
      <div className="main-container">
        <NavBar />
        <div className="history-main d-flex justify-content-between flex-column gap-4">
          <div className="d-flex justify-content-between">
            <div onClick={() => setNavURL("History")} className="balance-link">
              {t("Balance")}
            </div>
            <div className="balance-account d-flex align-items-center gap-2">
              <img src={info} alt="" />
              <div>Accounts</div>
            </div>
          </div>
          <div className="d-flex flex-column">{main()}</div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Balance;
