import "./MobileLogIn.css";
import { Images } from "../../../Images";
import { mobileLoginTxt } from "../constantNavBar";

const MobileLogIn = ({
  mobLogIn,
  setMobLogin,
  userlogin,
  mobLogged,
  showLoginModal,
  changeTheme,
  setregisterModal,
}) => {
  return (
    <div
      className={`${
        mobLogIn ? "mobileLogIn-block" : "mobileLogIn-none"
      } mobileLogIn-main`}
    >
      <div className="mobileLogIn-container d-flex flex-column align-items-center">
        <img
          className="mobileLogIn-close"
          src={Images.closeAnswer}
          alt="close-modal"
          onClick={() => setMobLogin(false)}
        />
        <img className="mobileLogIn-logo" src={Images.SidebarLogo} alt="logo" />
        <div className="mobileLogIn-welcome">Welcome</div>
        <div className="mobileLogIn-buttons-container d-flex flex-column">
          <button
            onClick={() => showLoginModal(changeTheme)}
            className="mobileLogIn-logInBtn"
            data-e2e="login-button"
          >
            Log in
          </button>
          <div>
            <button
              onClick={() => setregisterModal(true)}
              className="mobileLogIn-registerBtn"
              data-e2e="register-button"
            >
              Register
            </button>
          </div>
        </div>
        <div className="mobileLogIn-footer">
          {mobileLoginTxt.first}{" "}
          <span className="termsOfService">{mobileLoginTxt.second}</span>
        </div>
      </div>
    </div>
  );
};

export default MobileLogIn;
