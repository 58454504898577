import { t } from "i18next";
import "./blackNav.css";

const BlackNav = ({ navEl }) => {
  const displayNav = navEl.map((nav, i) => (
    <div
      key={i}
      className={`${i !== 2 && i !== 3 ? "nav-El" : "blackNav-hidden"} nav-El`}
    >
      {t(nav)}
    </div>
  ));

  return (
    <div className="blackNav-main">
      <div className="black_nav d-flex justify-content-between">
        {displayNav}
      </div>
    </div>
  );
};

export default BlackNav;
