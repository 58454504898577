import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import CasherNav from "../../../context/context";
import Forms from "../../../History/Forms/Forms";
import { currencyId } from "../../../lib/constants";

const FormPayment = ({ type }) => {
  const { partnerPayment, selectedCurrency, setSelectedCurrency } =
    useContext(CasherNav);
  const [formElDeposit, setFormElDeposit] = useState({ label: t('Currency') });

  useEffect(() => {
    if (partnerPayment.length > 0) {
      currencyId(type, partnerPayment, formElDeposit, setFormElDeposit);
    }
  // eslint-disable-next-line
  }, [partnerPayment, type]); 

  return (
    <div>
      <Forms
        formEl={formElDeposit}
        selected={selectedCurrency}
        setSelected={setSelectedCurrency}
      />
    </div>
  );
};

export default FormPayment;
