import React from "react";
import { Images } from "../../../Images";
import { SubscribeBackground } from "../style";
import "./Subscribe.css";

const Subscribe = ({ bg }) => {
  return (
    <div className={`${bg ? "" : "subscribe"} py-5`}>
      <div className="container">
        <div className="subscribeBackground" style={SubscribeBackground}>
          {/* <img src={Images.subscribe2} className="subscribe-2" alt="" /> */}
          <div className="position-absolute subscribe-body-inner ">
            <div className="container ">
              <div className="subscribe-content-main">
                <div className="subscribe-main-col">
                  <div className="">
                    <img
                      className="img-casino_2"
                      src={Images.casino_2}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="">
                      <h1>
                        Subscribe to a Ocean Casino and receive Exclusive offers
                      </h1>
                    </div>
                    <div className="">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <input type="text" placeholder="Enter email address" />
                  <div className="position-absolute custom-btn">
                    <button className="">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
