import { Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import browser from "browser-detect";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  generateNewTicket,
  getTicketHistory
} from "../../../modules/Casher/Services/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../Common/getIp";
import "./Mail.css";
const browserInfo = browser();

const NewTicket = (props) => {
  const { generateNewTicket, getTicketHistory } = props;

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");

  const onFinish = async (data) => {
    const { subject, ticket } = data;
    let payload = {
      Loader: true,
      Controller: "Client",
      PartnerId: PartnerId,
      Method: "OpenTicket",
      TimeZone: 5.5,
      RequestData: JSON.stringify({
        Subject: subject,
        Message: ticket,
      }),
      ClientId: ClientId,
      Token: Token,
    };
    await generateNewTicket(payload);
    let deviceIp = await getDeviceIP();
        saveActivity({
          EventPage: "Cashier",
          EventType: "Ticket_NewTicket",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: "",
          attribute3: "",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
    props.setmailmodel(false);
    setTimeout(() => {
      TicketHistory();
    }, 2000);
  };

  const TicketHistory = async () => {
    if (Token) {
      let payload = {
        Loader: true,
        Controller: "Client",
        PartnerId: PartnerId,
        Method: "GetClientTickets",
        TimeZone: 5.5,
        RequestData: JSON.stringify({
          SkipCount: 0,
          TakeCount: 10,
        }),
        ClientId: ClientId,
        Token: Token,
      };
      await getTicketHistory(payload);
    }
  };

  useEffect(() => {
    TicketHistory();
    //eslint-disable-next-line
  }, []);

  return (
    <Modal
      visible={props.mailmodel}
      footer={null}
      width="700px"
      destroyOnClose
      className=" login newticket"
      onCancel={() => props.setmailmodel(false)}
    >
      <div className="row m-0 mail-modal">
        <div className="col-12 col-md-12 mail-modal-header ">
          <h1>+ New Ticket</h1>
        </div>
        <div className="col-12 col-md-12 mail-body ">
          <div className="container">
            <div className=" row py-4">
              <Form name="login" onFinish={onFinish} autoComplete="off">
                <div className="col-12 col-md-12">
                  <h2>Subject</h2>
                  <Form.Item name="subject">
                    <Input placeholder="Text" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <h2>Ticket</h2>
                  <Form.Item name="ticket">
                    <TextArea placeholder="Write your text…" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <Form.Item>
                    <button className="w-100 border-0">Send</button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(null, {
  generateNewTicket,
  getTicketHistory,
})(NewTicket);
