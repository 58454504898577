import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { getItemFromLocalStorage } from '../utils/localStorage';


function LoadData(data, fallback, lng) {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: true,
            fallbackLng: fallback === undefined ? "en" : fallback,
            lng: lng === undefined ? getItemFromLocalStorage('i18nextLng'): lng,
            interpolation: {
                escapeValue: false,
            },
            resources: data,
        });
}
function ChangeLanguage(value) {
    return i18n.changeLanguage(value)
}
export const Localisation = {
    LoadData,
    ChangeLanguage,
}
