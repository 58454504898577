import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Images } from "../../../Images";
import routes from "../../../Route/URLs";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import { MENU_KEYS } from "../constantNavBar";
import { paths } from "./constant";
import "./mobileNavbar.css";

const MobileNavbar = () => {
  const location = useLocation();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [navActive, setNavActive] = useState(paths.indexOf(location.pathname));

  const routesGenerated = [
    {
      name: t("Item1"),
      key: MENU_KEYS.CASINO,
      url: routes.CASINO,
      icon: Images.Casino2,
      active: Images.ActiveCasino,
    },
    {
      name: t("Item4"),
      key: MENU_KEYS.LIVE_CASINO,
      url: routes.LIVE_CASINO,
      icon: Images.LiveCasino3,
      active: Images.ActiveLiveCasino,
    },
    {
      name: t("Item2"),
      key: MENU_KEYS.GAMES,
      url: routes.GAMES,
      icon: Images.Games4,
      active: Images.ActiveGames,
    },
    {
      name: t("Sports"),
      key: MENU_KEYS.SPORTS,
      url: routes.SPORTS,
      icon: Images.Sport5,
      active: Images.ActiveSports,
    },
    {
      name: t("In-Play"),
      key: MENU_KEYS.INPLAY,
      url: routes.INPLAY,
      icon: Images.Inplay6,
      active: Images.ActiveInplay,
    },
  ];

  const hostName = window?.location?.pathname;
  let ispresent = hostName.includes("/home-page");

  const activityTrack = async (route) => {
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: route?.name || "",
    });
  }
  return (
    <div
      className={
        ispresent ? "mobileNav-container d-none" : "mobileNav-container "
      }
    >
      <div className="mobileNav-main">
        {routesGenerated.map((route, i) => (
          <div key={i} onClick={() => {
            setNavActive(i)
            activityTrack(route)
          }}>
            <Link
              to={route.url}
              // className={themeMode === "dark" ? "" : "active-menu-img-light"}/
              className={`${
                navActive === i ? "active-menu-img active" : "inactive"
              }`}
            >
              <div className="mobileNav-link">
                <img
                  src={navActive === i ? route.active : route.icon}
                  alt="nav icon"
                  className={`mobileNav-img ${
                    themeMode === "light"
                      ? navActive === i
                        ? "active-menu-img-light"
                        : ""
                      : ""
                  }`}
                />
                <div className={`${navActive === i && "active"}`}>
                  {route.name}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNavbar;
