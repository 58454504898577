
export const LoginActions =  {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  GET_CLIENT_BY_TOKEN: 'GET_CLIENT_BY_TOKEN',
  GET_CLIENT_BY_TOKEN_SUCCESS: 'GET_CLIENT_BY_TOKEN_SUCCESS',
  GET_CLIENT_BY_TOKEN_ERROR: 'GET_CLIENT_BY_TOKEN_ERROR',
  LOGIN_MODAL: 'LOGIN_MODAL',
  LOGIN_MODAL_SUCCESS: 'LOGIN_MODAL_SUCCESS',
  LOGIN_MODAL_ERROR: 'LOGIN_MODAL_ERROR',
  REGISTER_MODAL: 'REGISTER_MODAL',
  REGISTER_MODAL_SUCCESS: 'REGISTER_MODAL_SUCCESS',
  REGISTER_MODAL_ERROR: 'REGISTER_MODAL_ERROR',
  LOGOUT:'LOGOUT',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_ERROR: "GET_COUNTRIES_ERROR",
  GET_CITIES: "GET_CITIES",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_ERROR: "GET_CITIES_ERROR",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE"
}