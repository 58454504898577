import { Modal } from "antd";
import browser from "browser-detect";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import CasherNav from "../../../context/context";
import { deposit } from "../../../Services/actions";
import "./paymentInfo.css";
const browserInfo = browser();

const PaymentInfo = () => {
  const dispacth = useDispatch();
  const { selectedCurrency, selectedPaymentMethod } = useContext(CasherNav);
  const [amount, setAmount] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [transactionUrl, setTransactionUrl] = useState("");
  const [errMsg, setErrMsg] = useState("");

  let Token = getItemFromLocalStorage("Token");
  const ClientId = getItemFromLocalStorage("Id");

  const handleDeposit = async () => {
    // eslint-disable-next-line
    if (amount == "" || amount == 0) {
      setErrMsg("Please enter valid amount");
    } else {
      setErrMsg("");
      let { CurrencyId, PartnerId, PaymentSystemId, Id } =
        selectedPaymentMethod;

      let payload = {
        Loader: true,
        Controller: "Document",
        Method: "CreateDepositRequest",
        Token: Token,
        ClientId: ClientId,
        PartnerId: PartnerId,
        RequestData: JSON.stringify({
          PartnerId: PartnerId,
          Amount: amount,
          Info: JSON.stringify({
            CardNumber: "",
            BankId: "",
            AccountType: "USDC",
            NameSurname: "",
            Amount: amount,
            WalletNumber: "",
            BankName: "",
            ToBankNumber: "",
            BankAccountNumber: "",
            ClientName: "",
            NationalId: "",
            TypeDocumentId: "",
            VoucherNumber: "",
            ActivationCode: "",
            SMSCode: "",
            MobileNumber: "",
            PromoCode: promoCode,
            OperatorCardNumber: "",
            Info: "",
            TransactionId: "",
            ExpirationDate: "",
          }),
          PaymentSystemId: PaymentSystemId,
          PaymentForm: "",
          ImageName: "",
          ClientId: Id,
          CurrencyId: CurrencyId,
          CashCode: "",
          BetShopId: "",
          BonusId: "",
        }),
        TimeZone: 5.5,
      };
      dispacth(deposit(payload));
      let deviceIp = await getDeviceIP();
      saveActivity({
        EventPage: "Cashier",
        EventType: "Deposit_Confirm",
        attribute1: getItemFromLocalStorage("UserName") || "",
        attribute2: PaymentSystemId,
        attribute3: CurrencyId,
        attribute4: amount,
        attribute5: navigator.language,
        device_type: browserInfo.mobile ? "Mobile" : "Desktop",
        browser_type: browserInfo.name,
        browser_version: browserInfo.version,
        language: navigator.language,
        ip_address: deviceIp,
      });
    }
  };

  const ResponseObject = useSelector(
    (state) => state?.cashierReducer?.deposit?.ResponseObject?.Url
  );

  useEffect(() => {
    if (ResponseObject) {
      getResponseurl();
    }
    // eslint-disable-next-line
  }, [ResponseObject]);

  const getResponseurl = () => {
    if (Token) {
      const hostName = window?.location?.host;
      let a = ResponseObject?.replace(hostName, "oceanbet.io");
      setTransactionUrl(a);
      setOpenModel(true);
    }
  };

  return (
    <div className="payment-main">
      <div className="payment-title" id="payment-title">
        {t("Enter_payment")}
      </div>
      <div className="payment-field">
        <div className="d-flex flex-column gap-2">
          <div className="payment-amount">
            {t("Amount")} ({selectedCurrency})
          </div>
          <input
            className="payment-input"
            data-e2e="amount-text-box"
            type="number"
            placeholder={t("Amount")}
            onChange={(e) => {
              setErrMsg("");
              setAmount(e.target.value);
            }}
          />
        </div>
        {errMsg !== "" && <div className="error-message">{errMsg}</div>}
        <div className="d-flex flex-column gap-2">
          <div className="payment-promoCode">{t("PromoCode")}</div>
          <input
            className="payment-input"
            data-e2e="promo-code-text-box"
            placeholder={t("PromoCode-placeholder")}
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </div>
        <div
          className="confirm-btn pointer"
          onClick={() => handleDeposit()}
          data-e2e={t("Confirm")}
          id="register-btn-clr"
        >
          {t("Confirm")}
        </div>
      </div>
      <Modal
        visible={openModel}
        destroyOnClose
        footer={null}
        className=""
        onCancel={() => setOpenModel(false)}
      >
        <div className="casino-info-model">
          <div className="row m-0">
            <div className="col-12 col-md-12 p-0">
              {}
              <iframe
              data-e2e="url"
              data-e2e-transaction={transactionUrl}
                src={transactionUrl}
                width="100%"
                height="500px"
                title="Games"
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentInfo;
