export const CasherActions = {
  WITHDRAW: "WITHDRAW",
  WITHDRAW_SUCCESS: "WITHDRAW_SUCCESS",
  WITHDRAW_ERROR: "WITHDRAW_ERROR",
  DEPOSIT: "DEPOSIT",
  DEPOSIT_SUCCESS: "DEPOSTI_SUCCESS",
  DEPOSIT_ERROR: "DEPOSIT_ERROR",
  SELECTED_CARD: "SELECTED_CARD",
  STATUS_OPTIONS: 'STATUS_OPTIONS',
  STATUS_OPTIONS_SUCCESS: 'STATUS_OPTIONS_SUCCESS',
  STATUS_OPTIONS_ERROR: 'STATUS_OPTIONS_ERROR',
  PRODUCT_OPTIONS: 'PRODUCT_OPTIONS',
  PRODUCT_OPTIONS_SUCCESS: 'PRODUCT_OPTIONS_SUCCESS',
  PRODUCT_OPTIONS_ERROR: 'PRODUCT_OPTIONS_ERROR',
  BET_HISTORY: 'BET_HISTORY',
  BET_HISTORY_SUCCESS: 'BET_HISTORY_SUCCESS',
  BET_HISTORY_ERROR: 'BET_HISTORY_ERROR',
  TRANSACTION_TYPES: 'TRANSACTION_TYPES',
  TRANSACTION_TYPES_SUCCESS: 'TRANSACTION_TYPES_SUCCESS',
  TRANSACTION_TYPES_ERROR: 'TRANSACTION_TYPES_ERROR',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  TRANSACTION_HISTORY_SUCCESS: 'TRANSACTION_HISTORY_SUCCESS',
  TRANSACTION_HISTORY_ERROR: 'TRANSACTION_HISTORY_ERROR',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  PAYMENT_HISTORY_SUCCESS: 'PAYMENT_HISTORY_SUCCESS',
  PAYMENT_HISTORY_ERROR: 'PAYMENT_HISTORY_ERROR',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  PAYMENT_TYPE_SUCCESS: 'PAYMENT_TYPE_SUCCESS',
  PAYMENT_TYPE_ERROR: 'PAYMENT_TYPE_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  NEW_TICKET: 'NEW_TICKET',
  TICKET_HISTORY: 'TICKET_HISTORY',
  TICKET_HISTORY_SUCCESS: 'TICKET_HISTORY_SUCCESS',
  TICKET_HISTORY_ERROR: 'TICKET_HISTORY_ERROR',
};
