import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../../Images";
import { Localisation } from "../../../locale/Localisation";
import routes from "../../../Route/URLs";
import { setTheme } from "../../../theme/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { saveActivity } from "../../ActivityTracking/activityTrackingService";
import { setThemeMode } from "../../Common/theme";
import { emailLogInExample, languageDropdown } from "../constantNavBar";
import "./SideBar.css";

const { Option } = Select;

const SiderMenu = ({ siderMenu, setSiderMenu, userlogin, userName }) => {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const history = useHistory();
  const MENU_KEYS = {
    HOME_PAGE: "home_page",
    CASINO: "casino",
    LIVE_CASINO: "live_casino",
    GAMES: "games",
    SPORTS: "sports",
    INPLAY: "inplay",
    PROMOTIONS: "promotions",
  };

  const sideBarMenu = [
    {
      name: "CASINO",
      key: MENU_KEYS.CASINO,
      url: routes.CASINO,
      icon:
        themeMode === "light"
          ? Images.casinoLightActive
          : Images.casinoDarkActive,
    },
    {
      name: "LIVE CASINO",
      key: MENU_KEYS.LIVE_CASINO,
      url: routes.LIVE_CASINO,
      icon:
        themeMode === "light"
          ? Images.liveCasinoMenuActiveLight
          : Images.liveCasinoDArkMenuActive,
    },
    {
      name: "GAMES",
      key: MENU_KEYS.GAMES,
      url: routes.GAMES,
      icon:
        themeMode === "light"
          ? Images.gamesMenuActiveLight
          : Images.gamesMenuActiveDark,
    },
    {
      name: "SPORT",
      key: MENU_KEYS.SPORTS,
      url: routes.SPORTS,
      icon:
        themeMode === "light"
          ? Images.sportsLightMenuActive
          : Images.sportsDarkMenuActive,
    },
    {
      name: "INPLAY",
      key: MENU_KEYS.INPLAY,
      url: routes.INPLAY,
      icon:
        themeMode === "light"
          ? Images.inplayActiveLight
          : Images.inplayActiveDark,
    },
  ];

  const dispatch = useDispatch();
  const handleTheme = () => {
    if (getItemFromLocalStorage("Theme") === "dark") {
      setThemeMode("light");
      dispatch(setTheme("light"));
    } else {
      setThemeMode("dark");
      dispatch(setTheme("dark"));
    }
    // setSiderMenu(false)
  };
  const { ChangeLanguage } = Localisation;

  const HandleSelectLanguage = (e) => {
    ChangeLanguage(e);
    // localStorage.setItem("key",e)
    window.location.reload();
  };

  const handleUrlChange = (list) => {
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: list?.url?.split("/")[2] || "",
    });
    const pathName = window?.location?.pathname;
    if (list.url === pathName) {
      setSiderMenu(false);
    } else {
      history.push(list.url);
    }
  };
  return (
    <div className={`${siderMenu ? "ModalOpen" : "d-none"} siderBar-main `}>
      <div className="sidebar-main-container">
        <div className="sideBar-container">
          <div className="sideBar-header">
            {userlogin && (
              <div className="logInAccount-container text-center">
                <div className="clientLogIn">{userName}</div>
                <div className="emailLogIn">{emailLogInExample}</div>
              </div>
            )}
            <div
              className="sideBar-arrow-container d-flex justify-content-center align-items-center"
              onClick={() => setSiderMenu(false)}
            >
              <img
                src={Images.downArrowGray}
                alt="close modal"
                className="rotate_90-left"
                width="15px"
                height="15px"
              />
            </div>
          </div>
          {/* <div className="sideBar-border"></div> */}
          <div className="sideBar-menu-container">
            {sideBarMenu?.map((list, i) => {
              return (
                <Link
                  key={i}
                  className="sidebar-link"
                  onClick={() => handleUrlChange(list)}
                >
                  <div className="sideBar-menu d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center mx-3">
                      <div className="left-border"></div>
                      <div
                        style={{
                          background: `url(${list.icon}) center no-repeat`,
                          backgroundSize: "cover",
                          width: "60px",
                          height: "50px",
                        }}
                      ></div>
                      <div className="sideBar-menu-content-menu">
                        {list.name}
                      </div>
                    </div>
                    <div className="side-arrow-mobile-menu">
                      <img
                        src={Images.downArrowGray}
                        alt="rigthArrow"
                        className="rotate_90-right"
                      />
                    </div>
                  </div>
                </Link>
              );
            })}
            <div className="sideBar-menu-border"></div>
            {/* {sideBarPromotion?.map((list, i) => {
            return (
              <Link key={i} className="sidebar-link" to={list.url}>
                <div
                  key={i}
                  className="sideBar-menu d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex align-items-center">
                    <div className="left-border"></div>
                    <img
                      className="sideBar-menu-img"
                      src={list.icon}
                      alt="close sidebar"
                    />
                    <div className="sideBar-menu-content">{list.name}</div>
                  </div>
                  <div>
                    <img
                      src={Images.downArrowGray}
                      alt="rigthArrow"
                      className="rotate_90-right"
                    />
                  </div>
                </div>
              </Link>
            );
          })}
          <div className="sideBar-menu-border"></div>
          */}
            {/* {support_faq?.map((list, i) => {
            return (
              <div
                key={i}
                className="sideBar-menu d-flex justify-content-between align-items-center"
                onClick={() => history.push(`/ocean-bet${list.path}`)}
              >
                <div className="d-flex align-items-center">
                  <img
                    className="sideBar-menu-img"
                    src={list.icon}
                    alt={list.name}
                    width={17}
                    height={17}
                  />
                  <div className="sideBar-menu-content listName-padding">
                    {list.name}
                  </div>
                </div>
                <div>
                  <img
                    src={Images.downArrowGray}
                    alt="rigthArrow"
                    className="rotate_90-right"
                  />
                </div>
              </div>
            );
          })} */}
            <div>
              {/* <div className="sideBar-border"></div> */}
              <div className="sideBar-menu d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    className="sideBar-menu-img"
                    src={
                      themeMode === "light"
                        ? Images.lightMoonTheme
                        : Images.moon
                    }
                    alt="close dark mode"
                  />
                  <div className="sideBar-menu-content listName-padding">
                    {themeMode === "light" ? "Light Mode" : "Dark Mode"}
                  </div>
                </div>
                <div>
                  <img
                    onClick={() => {
                      handleTheme();
                    }}
                    src={
                      themeMode === "light"
                        ? Images.switch_light_mode
                        : Images.swicth_dark_mode
                    }
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            {/* <div className="sideBar-border"></div> */}
            <div className="sideBar-menu" id="sidebar-menu-select">
              <Select
                style={{ width: "100%" }}
                className="select-lang"
                size="large"
                bordered={false}
                defaultValue={
                  getItemFromLocalStorage("i18nextLng") === "en-GB"
                    ? "en"
                    : getItemFromLocalStorage("i18nextLng")
                }
                onChange={HandleSelectLanguage}
              >
                {languageDropdown?.map((e, i) => {
                  return (
                    <>
                      <Option value={e.key}>
                        <span className="">
                          <img className="option-menu-img" src={e.img} alt="" />
                        </span>
                        {e.value}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiderMenu;
