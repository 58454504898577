import { Select } from "antd";
import { t } from "i18next";
import { useState } from "react";
import "./BonusesForms.css";

const { Option } = Select;

const BonusesForms = ({
  statusSelected,
  setStatusSelected,
  bonusStatusList,
  setBonusStatus,
}) => {
  const [bonus, setBonus] = useState(true);

  function selectBonus() {
    setBonus((prev) => !prev);
  }

  const handleBonus = () => {
    setBonusStatus(statusSelected);
  };

  const main = () => {
    return (
      <div className="bonus-form-container">
        <div className="bonus-form-subc">
          <div className="bonus-titles">
            <div
              onClick={selectBonus}
              className={`${bonus ? "text-white" : "text-gray"} title-bonus`}
            >
              {t("Common")}
              <div className={`${bonus && "underline"}`}></div>
            </div>
            <div
              onClick={selectBonus}
              className={`${
                !bonus ? "text-white" : "text-gray"
              } title-bonus title-sportbook`}
            >
              {t("Sportsbook")}
              <div className={`${!bonus && "underline"}`}></div>
            </div>
          </div>
          <div className="inp-btn-box d-flex justify-content-between">
            <input
              className="bns-input"
              type="text"
              placeholder={t("PromoCode")}
            />
            <button className="activate-btn" id="register-btn-clr">
              {t("ActivateBonus")}
            </button>
          </div>
        </div>
        <div>
          <div className="d-flex flex-column">
            <label className="select-label py-2">{t("Status")}</label>
            <Select
              className="bonus-custom-select"
              placeholder="Select Status"
              labelInValue
              onChange={(e) =>
                setStatusSelected({ ...statusSelected, status: e.value })
              }
            >
              {bonusStatusList &&
                bonusStatusList?.map((e) => {
                  return (
                    <Option className="opt" value={e?.Id} key={e?.Id}>
                      {e?.Name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bonus-form-main">
      <div>{main()}</div>
      <div className="button cursor-pointer">
        <div
          className="show-btn"
          id="register-btn-clr"
          onClick={() => handleBonus()}
        >
          Show
        </div>
      </div>
    </div>
  );
};

export default BonusesForms;
