import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { Images } from "../../../Images/index";

import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage
} from "../../../utils/localStorage";
import { getAllCountries } from "../../Login/actions";

const { Option } = Select;

const Step2 = ({ registerPayload, setRegisterPayload, setFormSteps }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const themeMode = getItemFromLocalStorage("Theme") || "";

  let allCountriesData = useSelector(
    (state) => state?.loginReducer?.allCountries?.data?.ResponseObject
  );

  // let allCitiesData = useSelector(
  //   (state) => state?.loginReducer?.allCities?.data?.ResponseObject
  // );

  const onFinish = async (values) => {
    let obj = {
      Address: values.Address,
      MobileCode: values.MobileCode,
      MobileNumber: values.MobileNumber,
      Country: values.Country,
      City: "",
      District: "",
      DocumentNumber: "",
    };
    setRegisterPayload({ ...registerPayload, ...obj });
    addItemToLocalStorage("step2",obj);
    setFormSteps(3);
  };
  let payload = { LanguageId: "en", PartnerId: 43, TimeZone: 5.5 };

  // const handleCities = (value) => {
  //   dispatch(
  //     getAllCities({
  //       ...payload,
  //       ParentId: value,
  //       TypeId: 3,
  //     })
  //   );
  // };

  let step2Data = getItemFromLocalStorage("step2");

  useEffect(() => {
    dispatch(getAllCountries({ ...payload, TypeId: 5 })); // eslint-disable-next-line
  }, []);

  return (
    <>
        <div className="row">
          <Form
            name="register"
            initialValues={{
              Address: step2Data?.Address,
              MobileCode: step2Data?.MobileCode,
              MobileNumber: step2Data?.MobileNumber,
              Country: step2Data?.Country,
              City: step2Data?.City,
              District: "",
              DocumentNumber: step2Data?.DocumentNumber || "",
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // onValuesChange={() =>
            //   changeTheme(getItemFromLocalStorage("Theme") || "")
            // }
          >
            <div className="col-12">
              <Form.Item
                name="Country"
                rules={[
                  {
                    required: true,
                    message: t("Country-option"),
                  },
                ]}
              >
                <Select
                  className={`amount-input-currency ${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  // onChange={handleCities}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  data-e2e="country"
                >
                  {allCountriesData?.map(({ Id, Name }) => (
                    <Option value={Id} key={Id} data-e2e={Name}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {/* <div className="col-12">
              <Form.Item
                name="City"
                rules={[
                  {
                    required: true,
                    message: t("Country-option"),
                  },
                ]}
              >
                <Select
                  className={`amount-input-currency ${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allCitiesData?.map(({ Id, Name }) => (
                    <Option value={Id} key={Id}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div> */}
            <div className="col-12 ">
              <Form.Item
                name="Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Address!",
                  },
                ]}
              >
                <Input
                  placeholder={t("Address-placeholder")}
                  data-e2e="address"
                  className={`${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  prefix={<img src={Images.maps} alt="" />}
                />
              </Form.Item>
            </div>
            <div className="col-12">
              <div className="row ">
                <div className="col-5">
                  <Form.Item
                    name="MobileCode"
                    rules={[
                      {
                        required: false,
                        message: "Please input your country code.!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Mobile Code"
                      data-e2e="mobile-code"
                      className={`${
                        themeMode === "light"
                          ? "white-content-bg"
                          : "login-body-input"
                      }`}
                      prefix={<img src={Images.document} alt="" />}
                    />
                  </Form.Item>
                </div>
                <div className="col-7">
                  <Form.Item
                    name="MobileNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please input your mobile number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("MobileNumber")}
                      data-e2e="mobile-number"
                      className={`${
                        themeMode === "light"
                          ? "white-content-bg"
                          : "login-body-input"
                      }`}
                      prefix={<img src={Images.document} alt="" />}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            {/* <div className="col-12 ">
              <Form.Item
                name="DocumentNumber"
                rules={[
                  {
                    required: false,
                    message: "Please input your document no.!",
                  },
                ]}
              >
                <Input
                  placeholder={t("DocumentNumber")}
                  className={`${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  prefix={<img src={Images.document} alt="" />}
                />
              </Form.Item>
            </div> */}

            <button className="w-100">
              <span data-e2e={t("Next")}>{t("Next")}</span>
            </button>
          </Form>
        </div>
    </>
  );
};

export default Step2;
