import "./SupportRight.css";
import { Images } from "../../../../Images";
import { frequentlyAskedQuestions } from "../../lib/constants";
import { useState } from "react";

const SupportRight = () => {
  const [displayNone, setDisplayNone] = useState(null);
  const { questionMark, openAnswer, closeAnswer } = Images;

  const questions = frequentlyAskedQuestions.map((quest, i) => {
    return (
      <div key={i}>
        <div className="quest-title d-flex align-items-center">
          <div>
            <img
              className="quest-title-img "
              src={displayNone !== i ? openAnswer : closeAnswer}
              alt={displayNone !== i ? "+" : "x"}
              onClick={() => setDisplayNone((prev) => (prev === i ? null : i))}
            />
          </div>
          <h4>{quest.title}</h4>
        </div>
        <div className={`quest-answer ${displayNone !== i ? "none" : ""}`}>
          {quest.expl}
        </div>
        <div className="title-border-right"></div>
      </div>
    );
  });

  return (
    <div className="sup-right-main">
      <div className="asked-questions ">
        <div className="d-flex gap-3">
          <div>
            <img src={questionMark} alt="?" className="quest-img" />
          </div>
          <h4 className="faq-title">Frequently Asked Questions</h4>
        </div>
      </div>
      <div className="title-border-right-top"></div>
      {questions}
    </div>
  );
};

export default SupportRight;
