import axios from "axios";

class LoginApi {
  loginUser = (data) => axios.post("https://websitewebapi.oceanbet.io/43/api/Main/LoginClient", data);
  getClientByToken = (data) => axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetClientByToken", data);
  userSignup = (data) => axios.post("https://websitewebapi.oceanbet.io/43/api/Main/RegisterClient", data.Data);
  countries = (data) => axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetRegions", data.Data);
  cities = (data) => axios.post("https://websitewebapi.oceanbet.io/43/api/Main/GetRegions", data.Data);
  recoveryPassword = (data) =>axios.post("https://websitewebapi.oceanbet.io/43/api/Main/SendRecoveryToken", data.Data)
}

export default new LoginApi();
