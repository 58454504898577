import { t } from "i18next";
import { useContext, useState } from "react";
import BasicLayout from "../../../../OceanBetBasicLayout";
import CasherNav from "../../context/context";
import { financesNavEl } from "../../lib/constants";
import NavBar from "../../_sharedComponents/NavBar/NavBar";
import Nav from "../../_sharedComponents/SubNav/Nav";
import Accounts from "../Accounts/Accounts";
import RightSection from "../RightSection/RightSection";
import AddAccount from "./AddAccount";
import Deposit from "./Deposit/Deposit";
import FormPayment from "./FormPayment/FormPayment";
import "./mainSection.css";
import Withdraw from "./Withdraw/Withdraw";
const MainSection = ({ selectedCurrency, setSelectedCurrency, props }) => {
  const { finUrl, setFinUrl } = useContext(CasherNav);
  const [addAccount, setAddAccount] = useState(false);

  const main = () => {
    if (finUrl === "Deposit") return <Deposit />;
    if (finUrl === "Withdraw") return <Withdraw />;
    if (finUrl === "Bank_Accounts") return <Accounts />;
  };

  return (
    <BasicLayout>
      <div className="main-container">
        <NavBar />
        <div className="main">
          <div
            className={`main-main d-flex flex-column ${
              finUrl === "Bank_Accounts" ? "main-full-screen" : "gap-4"
            }`}
          >
            <div className="d-flex flex-column gap-4">
              <Nav
                navEl={financesNavEl}
                url={finUrl}
                setUrl={setFinUrl}
                selectedCurrency={selectedCurrency}
              />
              <div className="d-flex flex-column gap-2">
                <div className="title-border"></div>
                {finUrl === "Bank_Accounts" ? (
                  <div data-e2e="add-account" className="add-btn" id="register-btn-clr" onClick={() => setAddAccount(true)}>
                    + {t("Add_Account")}
                  </div>
                ) : (
                  <div className="main-title d-flex justify-content-between align-items-center">
                    <div className="selPay-responsive">
                      {t("Select_payment")}
                    </div>
                    <div className="formPay-responsive">
                      <FormPayment type={finUrl === "Deposit" ? 2 : 1} />
                    </div>
                  </div>
                )}
                <div className="title-border"></div>
              </div>
            </div>
            <AddAccount addAccount={addAccount} setAddAccount={setAddAccount} />
            {main()}
          </div>
          {finUrl !== "Bank_Accounts" && <RightSection />}
        </div>
      </div>
    </BasicLayout>
  );
};

export default MainSection;
