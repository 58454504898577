export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1250 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1250, min: 1040 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 1040, min: 874 },
    items: 4,
  },
  small: {
    breakpoint: { max: 874, min: 570 },
    items: 3,
  },
  xs: {
    breakpoint: { max: 570, min: 0 },
    items: 2,
  },
};

export const topresponsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1300 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1300, min: 1080 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 1080, min: 600 },
    items: 3,
  },
  small: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
  },
};
