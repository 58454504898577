import Card from "../Card/Card";
import { useContext, useState, useEffect } from "react";
import "./withdraw.css";
import CasherNav from "../../../context/context";
import { selectedCard } from "../../../Services/actions";
import { connect } from "react-redux";
import { getClientBalance } from "../../../../../Components/Navbar/actions";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";

const Withdraw = (props) => {
  const [cardSelected, setCardSelected] = useState(0);
  const [methods, setMethods] = useState([]);
  const { partnerPayment, selectedCurrency } = useContext(CasherNav);

  const { selectedCard, getClientBalance } = props

  useEffect(() => {
    const options = [];
    if (partnerPayment.length > 0) {
      // eslint-disable-next-line
      partnerPayment.map((p) => {
        if (p.CurrencyId === selectedCurrency && p.Type === 1) {
          options.push(p);
        }
      });
    }

    setMethods(options);
    // eslint-disable-next-line
  }, [selectedCurrency, partnerPayment]);



  useEffect(() => {
    const PartnerId = getItemFromLocalStorage("PartnerId");
    const Token = getItemFromLocalStorage("Token");
    const CurrencyId = getItemFromLocalStorage("CurrencyId");
    const ClientId = getItemFromLocalStorage("Id");
    const payload = {
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
    };
    if (methods.length > 1) {
      selectedCard(methods[cardSelected])
      getClientBalance(payload);
    }
    // eslint-disable-next-line
  }, [cardSelected, methods])

  return (
    <div className="mainSection-container">
      <div className="withdraw-main">
        {methods.map((card, i) => (
          <Card
            key={i}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
            i={i}
            card={card}
            CommissionPercent={card.CommissionPercent}
          />
        ))}
      </div>
    </div>
  );
};


export default connect(null, {
  selectedCard,
  getClientBalance
})(Withdraw);