import Nav from "../_sharedComponents/SubNav/Nav";
import CasherNav from "../context/context";
import { useContext, useState } from "react";
import { ticketNavEl } from "../lib/constants";
import MainHistory from "../History/Bets/Main/MainHistory";
import NewTicket from "../../../Components/Models/Mail/NewTicket";
import "./NewTicket.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { t } from "i18next";
import BasicLayout from "../../../OceanBetBasicLayout";
import NavBar from "../_sharedComponents/NavBar/NavBar";
const Ticket = () => {
  const { ticketUrl, setTicketUrl } = useContext(CasherNav);
  const [mailmodel, setmailmodel] = useState(false);

  const TicketHistory = useSelector(
    (state) =>
      state?.cashierReducer?.ticketHistory?.data?.ResponseObject?.Tickets
  );

  const navElTicket = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "Status",
      key: "Status",
      render: (text) => <p>{text === 1 ? "Active" : "Closed"}</p>,
    },
    {
      title: t("Subject"),
      dataIndex: "Subject",
      key: "Subject",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Creation Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      render: (text) => <p>{moment(text).format("LL")}</p>,
      responsive: ["sm"],
    },
  ];

  const main = () => {
    if (ticketUrl === "My_tickets")
      return (
        <>
          <div className="d-flex flex-column gap-4">
            <div className="title-border"></div>
            <div className="newTicket-btn-container">
              <div className="newTicket-btn" onClick={() => setmailmodel(true)}>
                + {t("New-Ticket")}
              </div>
            </div>
            <div className="title-border"></div>
          </div>
          <MainHistory
            empty={t("Tickets-empty")}
            navEl={navElTicket}
            Payhistory={TicketHistory}
          />
        </>
      );
  };

  return (
    <>
      <BasicLayout>
        <div className="main-container">
          <NavBar />
          <div className="history-main d-flex flex-column gap-4">
            <Nav navEl={ticketNavEl} url={ticketUrl} setUrl={setTicketUrl} />
            <div className="d-flex flex-column">{main()}</div>
          </div>
          <div className="mail-container">
            <NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
          </div>
        </div>
      </BasicLayout>
    </>
  );
};

export default Ticket;
