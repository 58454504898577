import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../Images";
import { logoStyle } from "./style";

export const LogoComponent = (props) => {
  return (
    <Link to="/" className="cursor-pointer">
      <img
        src={Images.SidebarLogo}
        alt={`Logo`}
        align={"middle"}
        style={logoStyle}
        className={"logo"}
      />
    </Link>
  );
};
