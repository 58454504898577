import { Tooltip } from "antd";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Images } from "../../../../Images";
import CasherNav from "../../context/context";
import { navEl } from "../../lib/constants";
import "./navBar.css";

const NavBar = () => {
  const history = useHistory();
  const { navURL, setNavURL } = useContext(CasherNav);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const {downArrowGray } = Images;
  const [show, setShow] = useState(false);
  const [navImage, setNavImage] = useState(Images.finances);

  const location = window.location.pathname;

  let ClientBalance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data
  );

  let unused = ClientBalance?.Balances
    ? ClientBalance?.Balances[0]?.Balance
    : 0;
  let used = ClientBalance?.Balances ? ClientBalance?.Balances[1]?.Balance : 0;

  let clientBooking = 0;
  const nameToImage = (name) => {
    navEl.forEach((el) => {
      if (el.title === name) {
        setNavImage(el.img);
      }
    });
  };

  const click = (el, route) => {
    history?.push(route);
    setNavURL(el);
  };

  useEffect(() => {
    nameToImage(navURL);
  }, [navURL]);

  useEffect(() => {
    setNavURL(
      location?.split("/")[3]?.charAt(0)?.toUpperCase() +
        location.split("/")[3]?.slice(1)
    ); // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <div className="flex">
        <div className="nav-border"></div>
        <div className="nav-main">
          <div className="nav-main-sub">
            {navEl.map((el, i) => (
              <div
                onClick={() => click(el.title, el.route)}
                key={i}
                className={`flex pointer gap-responsive
                ${navURL === el.title ? "text-white-color" : " text-gray"}`}
              >
                <img
                  src={navURL === el.title ? el.img : el.imgIn}
                  className={`nav-img gap-navBar ${navURL === el.title ? 'card-img-active':'' } ${themeMode === "light" && navURL !== el.title && el.title === "Finances" ? 'img-filter':'' }`}
                  alt=""
                />
                <div className="gap-navBar">
                  <p className="m-0 nav-title" data-e2e={t(el.title)}>{t(el.title)}</p>
                </div>
                {/* <img
                  src={navURL === el.title ? path : downArrowGray}
                  alt=""
                  className={`navBar-downArrow ${
                    navURL === el.title ? "img-color" : ""
                  }`}
                ></img> */}
              </div>
            ))}
          </div>
        </div>
        <div className="dropDown-navMobile">
          <div
            onClick={() => setShow((prev) => !prev)}
            // className={`${navURL === el.title ? "nav-dropBtn" : "dropNone"}`}
            className="nav-dropBtn"
          >
            <div>
              <img src={navImage} className="nav-img gap-navBar mobile-finance-sidebar-img" alt="" />
              {navURL}
            </div>
            <img src={downArrowGray} alt="" className="navBar-downArrow" />
          </div>
          {navEl.map((el, i) => (
            <div
              key={i}
              onClick={() => {
                click(el.title, el.route);
              }}
              className={`${navURL !== el.title ? "dropShow" : "dropNone"}`}
            >
              <div
                className={`nav-dropContent ${
                  show ? "dropShow" : "dropNone"
                }`}
              >
                {/* <img src={el.imgIn} alt="casherImg" /> */}
                <div>{el.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-2 info-accounts-img info-desktop">
        <Tooltip
          placement="bottomRight"
          title={
            <div className="info-tooltip-text">
              <div>Client Unused Balance: {unused}</div>
              <hr className="info-tooltip" />
              <div>Client Used Balance: {used}</div>
              <hr className="info-tooltip" />
              <div>Clinet Booking: {clientBooking}</div>
            </div>
          }
        >
          <img src={Images.info} alt="info" className="info-accounts" />
        </Tooltip>
        <div className="info-accounts">Accounts</div>
      </div>
    </>
  );
};

export default NavBar;
