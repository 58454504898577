import { t } from "i18next";
import { useContext } from "react";
import { Images } from "../../../../../Images";
import CasherNav from "../../../context/context";
import { addPoint, chooseImg, currencyToSymbol } from "../../../lib/constants";
import "./card.css";

const MobileViewCard = ({
  cardSelected,
  i,
  card,
  setCardSelected,
  CommissionPercent,
}) => {
  let { PaymentSystemName, MinAmount, MaxAmount } = card;
  const { selected, master, masterIn } = Images;
  const { selectedCurrency, setSelectedPaymentMethod } = useContext(CasherNav);
  PaymentSystemName = PaymentSystemName?.replace("PaymentIQCryptoPay", "CryptonPay") || PaymentSystemName;
  return (
    <div
      key={i}
      onClick={() => {
        setSelectedPaymentMethod(card);
        setCardSelected(i);
      }}
      className={`${
        cardSelected === i ? "border-selected" : "border-noSelected"
      } brd pointer ${CommissionPercent >= 0 ? "card-commission-h" : "card-h"}`}
    >
      <div className="dark-bg">
        <div 
          className={`card ${
            cardSelected === i ? "card-selected" : "card-noSelected"
          }`}
        >
          <div className="card-first" data-e2e={PaymentSystemName}>
            <div
              className={`logo-container d-flex align-items-center ${
                PaymentSystemName.includes("MoneyPayV") && "gap-3"
              } `}
            >
              <div
                className={`${
                  PaymentSystemName !== "Skrill" &&
                  PaymentSystemName !== "Neteller"
                    ? cardSelected === i
                      ? "card-sphere-border-selected"
                      : "card-sphere-border-noSelected"
                    : ""
                }`}
              >
                <div className="dark-bg radius-full">
                  <div className="card-sphere">
                    <img
                      src={chooseImg(PaymentSystemName, cardSelected, i)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {PaymentSystemName.includes("MoneyPayV") && (
                <div
                  className={`${
                    cardSelected === i
                      ? "card-sphere-border-selected"
                      : "card-sphere-border-noSelected"
                  }`}
                >
                  <div className="dark-bg radius-full">
                    <div className="card-sphere">
                      <img
                        src={cardSelected === i ? master : masterIn}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mobile-nav2">
                {cardSelected === i && (
                  <img className="card-selected-img" src={selected} alt="" />
                )}
              </div>
            </div>
            <div
              className={`mobile-nav2 card-title ${
                cardSelected === i ? "text-white-color" : "text-gray"
              }`}
            >
              {PaymentSystemName.replace("PaymentIQ", "")}
            </div>
          </div>
          <div className="container-2">
            <div className="mobile-nav1">
              <div
                className={`card-title ${
                  cardSelected === i ? "text-white-color" : "text-gray"
                }`}
              >
                {PaymentSystemName.replace("PaymentIQ", "")}
              </div>
              <div>
                {cardSelected === i && (
                  <img className="card-selected-img" src={selected} alt="" />
                )}
              </div>
            </div>
            {CommissionPercent >= 0 && (
              <div className="card-second b-brd">
                <div
                  className={`${
                    cardSelected === i ? "text-white-color" : "text-gray"
                  } sub`}
                >
                  {CommissionPercent + " %"}
                </div>
                <div className="text-gray">{t("CommissionPercent")}</div>
              </div>
            )}
            <div className="card-second b-brd">
              <div
                className={`${
                  cardSelected === i ? "text-white-color" : "text-gray"
                } sub`}
              >
                {MinAmount + " " + currencyToSymbol(selectedCurrency)}
              </div>
              <div className="text-gray">{t("Minimum")}</div>
            </div>
            <div className="card-second">
              <div
                className={`${
                  cardSelected === i ? "text-white-color" : "text-gray"
                } sub`}
              >
                {addPoint(MaxAmount) + " " + currencyToSymbol(selectedCurrency)}
              </div>
              <div className="text-gray">{t("Maximum")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileViewCard;
