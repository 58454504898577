import { CasherActions } from "../types";

const initialState = {
  withdraw: [],
  deposit: [],
  selectedCard: [],
  statusOptions: [],
  productOptions: [],
  transectionTypes: [],
  transectionHistory: [],
  paymentHistory: [],
  paymentType: [],
  password: [],
  ticketHistory: [],
  betHistory:[],
};

const CashierReducer = (state = initialState, action) => {
  switch (action.type) {
    case CasherActions.WITHDRAW_SUCCESS: {
      return {
        ...state,
        withdraw: action.payload,
      };
    }
    case CasherActions.WITHDRAW_ERROR: {
      return {
        ...state,
        withdraw: action.payload,
      };
    }
    case CasherActions.SELECTED_CARD: {
      return {
        selectedCard: action.payload,
      };
    }
    case CasherActions.DEPOSIT_SUCCESS: {
      return {
        ...state,
        deposit: action.payload?.data,
      };
    }
    case CasherActions.DEPOSIT_ERROR: {
      return {
        ...state,
        deposit: action.payload,
      };
    }

    case CasherActions.STATUS_OPTIONS_SUCCESS: {
      return {
        ...state,
        statusOptions: action.payload,
      };
    }

    case CasherActions.STATUS_OPTIONS_ERROR: {
      return {
        ...state,
        statusOptions: action.payload,
      };
    }

    case CasherActions.TRANSACTION_TYPES_SUCCESS: {
      return {
        ...state,
        transectionTypes: action.payload,
      };
    }

    case CasherActions.TRANSACTION_TYPES_ERROR: {
      return {
        ...state,
        transectionTypes: action.payload,
      };
    }

    case CasherActions.PRODUCT_OPTIONS_SUCCESS: {
      return {
        ...state,
        productOptions: action.payload,
      };
    }

    case CasherActions.PRODUCT_OPTIONS_ERROR: {
      return {
        ...state,
        productOptions: action.payload,
      };
    }
    case CasherActions.BET_HISTORY_SUCCESS: {
      return {
        ...state,
        betHistory: action.payload,
      };
    }

    case CasherActions.BET_HISTORY_ERROR: {
      return {
        ...state,
        betHistory: action.payload,
      };
    }
    case CasherActions.TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        transectionHistory: action.payload,
      };
    }

    case CasherActions.TRANSACTION_HISTORY_ERROR: {
      return {
        ...state,
        transectionHistory: action.payload,
      };
    }

    case CasherActions.PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        paymentType: action.payload,
      };
    }

    case CasherActions.PAYMENT_TYPE_ERROR: {
      return {
        ...state,
        paymentType: action.payload,
      };
    }

    case CasherActions.PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        paymentHistory: action.payload,
      };
    }

    case CasherActions.PAYMENT_HISTORY_ERROR: {
      return {
        ...state,
        paymentHistory: action.payload,
      };
    }

    case CasherActions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        password: action.payload,
      };
    }

    case CasherActions.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        password: action.payload,
      };
    }

    case CasherActions.TICKET_HISTORY_SUCCESS: {
      return {
        ...state,
        ticketHistory: action.payload,
      };
    }

    case CasherActions.TICKET_HISTORY_ERROR: {
      return {
        ...state,
        ticketHistory: action.payload,
      };
    }
    default:
      return state;
  }
};

export default CashierReducer;
