import { Pagination, Table } from "antd";
import { t } from "i18next";
import { Images } from "../../../../../Images";
import "./mainHistory.css";

const MainHistory = ({
  Payhistory,
  navEl,
  empty,
  History,
  SkipCount,
  setSkipCount,
}) => {
  const { shape, noAccounts, noTicket } = Images;

  let locale = {
    emptyText: (
      <span>
        <p className="d-flex justify-content-center align-items-center text-light flex-column">
          <img
            className="shape-img my-4"
            src={
              empty === "There are no uploaded documents"
                ? noAccounts
                : empty === "There are no ticket"
                ? noTicket
                : shape
            }
            alt=""
          />
          <p className="m-0 empty-text">{t(empty)}</p>
        </p>
      </span>
    ),
  };
  return (
    <>
      <Table
        locale={locale}
        columns={navEl}
        dataSource={Payhistory}
        pagination={false}
      />
      <div className="position-relative">
        {History?.Count > 10 ? (
          <Pagination
            current={SkipCount + 1}
            total={History?.Count}
            defaultPageSize={10}
            showSizeChanger={false}
            // responsive={true}
            onChange={(e) => setSkipCount(e - 1)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MainHistory;
