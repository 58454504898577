import BasicLayout from "../../../OceanBetBasicLayout";

const CommonFooterTermsAndConditions = ({ title, data }) => {
  return (
    <BasicLayout>
      <div className="term-main-container">
        <div className="d-flex flex-column w-100">
          <div className="term-title"> {title}</div>
          <div className="term-terms">
            {data?.map(({ expl }, i) => {
              return (
                <div id={i} key={i}>
                  {expl.map((e, j) => (
                    <div
                      className="d-flex align-items-baseline gap-4 mb-3"
                      key={j}
                    >
                      <div>{e}</div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default CommonFooterTermsAndConditions;
