export const getRoute = (route) => {
  const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/ocean-bet/";
  const fullRoute = BASE_ROUTE ? `${BASE_ROUTE}${route}` : route;
  return fullRoute;
};

const routes = {
  ACCESS_DENIED: getRoute(`access-denied`),
  HOME_PAGE: getRoute(`home-page`),
  CASINO: getRoute(`casino`),
  LIVE_CASINO: getRoute(`live-casino`),
  LIVE_CASINO_PREVIEW: getRoute(`live-casino/preview`),
  GAMES: getRoute(`games`),
  SPORTS: getRoute(`sports`),
  INPLAY: getRoute(`inplay`),
  PROMOTIONS: getRoute(`promotions`),
  CASHER: getRoute(`casher`),
  CASHER_FINANCES: getRoute(`casher/finances`),
  CASHER_HISTORY: getRoute(`casher/history`),
  CASHER_BONUSES: getRoute(`casher/bonuses`),
  CASHER_PROFILE: getRoute(`casher/profile`),
  CASHER_TICKET: getRoute(`casher/ticket`),
  CASHER_BALANCE: getRoute(`casher/balance`),
  TERMS_CONDITION: getRoute(`terms&conditions`),
  SUPPORT: getRoute(`support`),
  SINGLE_PROMOTION: getRoute(`promotions/:id`),
  HELP: getRoute(`help`),
  ABOUT_US: getRoute(`about-us`),
  RESPONSIBLE_GAMING: getRoute(`responsible-gaming`),
  SELF_EXCLUSION: getRoute(`self-exclusion`),
  DISPUTE_RESOLUTION: getRoute(`dispute-resolution`),
  AMP_PAGE: getRoute(`aml-policy`),
  FAIRNESS_AND_RNG_TESTING: getRoute(`fairness-rng-testing`),
  PRIVACY_POLICY: getRoute(`privacy-policy`),
  ACCOUNTS_PAYOUTS_BONUS: getRoute(`accounts-payouts-bonus`),
};
export default routes;
