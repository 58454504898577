import { Images } from "../../../../Images";
import { useState } from "react";
import "./dateForm.css";

const Forms = ({ formEl, D, editStatus, selectedDate }) => {
  const [open, setOpen] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(null);
  const { path, flag } = Images;

  const displayForm = formEl.map((form, index) => (
    <div className="w-100" onMouseLeave={() => setOpen(false)} key={index}>
      <div onClick={() => setOpen(true)} className="w-100 max-h">
        <div
          className={`d-flex justify-content-between date-dropbtn w-100 ${
            !editStatus ? "cursor-not-allowed" : ""
          }`}
        >
          {formEl[0].option[0] === "+44" && <img src={flag} alt="" />}
          <div
            className={`${
              formEl[0].option[0] === "Total Deposit" ||
              formEl[0].option[0] === "Temporary"
                ? ""
                : "text-center"
            } w-100`}
          >
            {selectedOpt ? selectedOpt : D}
          </div>
          <img className="path-img" src={path} alt="" />
        </div>
        <div
          className={`${
            open && editStatus ? "date-opt-open" : ""
          } date-dropdown-content `}
        >
          {form.option.map((opt, i) => (
            <div
              key={i}
              onClick={() => {
                selectedDate(opt);
                setSelectedOpt(opt);
              }}
              className={`${
                formEl[0].option[0] === "Total Deposit" ||
                formEl[0].option[0] === "Temporary"
                  ? ""
                  : "text-right"
              } date-opt`}
            >
              {opt}
            </div>
          ))}
        </div>
      </div>
    </div>
  ));

  return (
    <div
      className={`d-flex gap-5 justify-content-between ${
        formEl[0].option[0] === "1"
          ? "w-20"
          : formEl[0].option[0] === "January"
          ? "w-50"
          : formEl[0].option[0] === "1980" || formEl[0].option[0] === "+44"
          ? "w-30"
          : "w-100"
      }`}
    >
      <div className="d-flex w-100">{displayForm}</div>
    </div>
  );
};

export default Forms;
