import browser from "browser-detect";
import { t } from "i18next";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../Components/Common/getIp";
import BasicLayout from "../../../OceanBetBasicLayout";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import CasherNav from "../context/context";
import { historyNavEl } from "../lib/constants";
import {
  getBetHistory,
  getPaymentHistory,
  getPaymentType,
  getProductOptions,
  getStatusOptions,
  getTransactionHistory,
  getTransactionTypes
} from "../Services/actions";
import NavBar from "../_sharedComponents/NavBar/NavBar";
import Nav from "../_sharedComponents/SubNav/Nav";
import BetHistory from "./BetHistory";
import MainHistory from "./Bets/Main/MainHistory";
import "./history.css";
import PaymentHistory from "./PaymentHistory";
import TransectionHistory from "./TransectionHistory";
const browserInfo = browser();

const History = (props) => {
  const { histUrl, setHistUrl } = useContext(CasherNav);
  const {
    getStatusOptions,
    getProductOptions,
    getBetHistory,
    getTransactionTypes,
    getTransactionHistory,
    getPaymentType,
    getPaymentHistory,
    history,
  } = props;

  const ProductOptions = useSelector(
    (state) => state?.cashierReducer?.productOptions?.data?.ResponseObject
  );
  const StatusOptions = useSelector(
    (state) => state?.cashierReducer?.statusOptions?.data?.ResponseObject
  );
  const TransectionOptions = useSelector(
    (state) => state?.cashierReducer?.transectionTypes?.data?.OperationTypes
  );
  const PaymentType = useSelector(
    (state) => state?.cashierReducer?.paymentType?.data?.ResponseObject
  );
  const Payhistory = useSelector(
    (state) => state?.cashierReducer?.paymentHistory?.data
  );
  const Bethistory = useSelector(
    (state) => state?.cashierReducer?.betHistory?.data
  );
  const Transactionhistory = useSelector(
    (state) => state?.cashierReducer?.transectionHistory?.data
  );

  const [selectedBetStatus, setSelectedBetStatus] = useState();
  const [selectedBetProducts, setSelectedBetProducts] = useState();
  const [betSelectValue, setbetSelectValue] = useState({
    time: "24 hours",
    status: "all",
    product: "",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  const [TypeOption, setTypeOption] = useState();
  const [transectionSelectValue, settransectionSelectValue] = useState({
    time: "24 hours",
    type: "all",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  const [paymentType, setpaymentType] = useState();
  const [SkipCount, setSkipCount] = useState(0);

  const [paymentSelectValue, setpaymentSelectValue] = useState({
    time: "24 hours",
    status: "all",
    type: "all",
    date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
      "YYYY-MM-DD hh:mm"
    ),
    fromDate: moment(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    ).format("YYYY-MM-DD hh:mm"),
  });

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  let CurrencyId = getItemFromLocalStorage("CurrencyId");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  useEffect(() => {
    setSelectedBetStatus(StatusOptions);
    setSelectedBetProducts(ProductOptions);
    setTypeOption(TransectionOptions);
    setpaymentType(PaymentType);
  }, [ProductOptions, StatusOptions, TransectionOptions, PaymentType]);

  const handleStatusOptions = async () => {
    let payload = {
      ActivationKey: null,
      CategoryId: null,
      ClientId: ClientId,
      Code: null,
      Controller: "Document",
      Email: null,
      Index: null,
      LanguageId: LanguageId,
      Loader: true,
      Method: "GetBetStates",
      MobileNumber: null,
      PageIndex: 0,
      PageSize: 100,
      PartnerId: PartnerId,
      ProductId: null,
      ProviderIds: [],
      RequestData: "{}",
      TimeZone: 5.5,
      Token: Token,
    };
    await getStatusOptions(payload);
  };

  const ProductStatusOptions = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      Method: "GetProductGroups",
      Controller: "Product",
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      ClientId: ClientId,
      Token: Token,
    };
    await getProductOptions(payload);
  };

  const TransectionTypes = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetOperationTypes",
      Controller: "Document",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getTransactionTypes(payload);
  };

  const PaymentTypes = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetPaymentRequestTypes",
      Controller: "Document",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getPaymentType(payload);
  };

  useEffect(() => {
    if (histUrl === "Bets") {
      handleStatusOptions();
      ProductStatusOptions();
    } else if (histUrl === "Transactions") {
      TransectionTypes();
    } else {
      PaymentTypes();
    }
    // eslint-disable-next-line
  }, [histUrl]);

  useEffect(() => {
    handleBetHistory(histUrl);
    // eslint-disable-next-line
  }, [histUrl, SkipCount]);

  useEffect(() => {
    setSkipCount(0);
  }, [histUrl]);


  let commonPayload = {
    Loader: true,
    PartnerId: PartnerId,
    TimeZone: 5.5,
    LanguageId: LanguageId,
    Controller: "Document",
    CategoryId: null,
    PageIndex: 0,
    PageSize: 100,
    ProviderIds: [],
    Index: null,
    ActivationKey: null,
    MobileNumber: null,
    Code: null,
  };

  const handleBetHistory = async (e) => {
    if (Token) {
      let deviceIp = await getDeviceIP();
      if (e === "Bets") {
        let payload = {
          ...commonPayload,
          Method: "GetBetsHistory",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: PartnerId,
            TimeZone: 5.5,
            CreatedFrom: betSelectValue.fromDate,
            CreatedBefore: betSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            GroupId: betSelectValue.product,
            ProductIds: null,
            Status:
              betSelectValue.status === "all" ? null : betSelectValue.status,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getBetHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Bets",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: betSelectValue?.time || "all",
          attribute3: betSelectValue.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      } else if (e === "Transactions") {
        let payload = {
          ...commonPayload,
          Method: "GetTransactionHistory",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: PartnerId,
            TimeZone: 5.5,
            CreatedFrom: transectionSelectValue.fromDate,
            CreatedBefore: transectionSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            OperationTypeId:
              transectionSelectValue.type === "all"
                ? null
                : transectionSelectValue.type,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getTransactionHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Transactions",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: transectionSelectValue?.time || "all",
          attribute3: transectionSelectValue.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      } else {
        let payload = {
          ...commonPayload,
          Method: "GetPaymentRequests",
          RequestData: JSON.stringify({
            ClientId: ClientId,
            CurrencyId: CurrencyId,
            PartnerId: PartnerId,
            TimeZone: 5.5,
            CreatedFrom: paymentSelectValue.fromDate,
            CreatedBefore: paymentSelectValue.date,
            SkipCount: SkipCount,
            TakeCount: 10,
            Statuses:
              paymentSelectValue.status === "all"
                ? null
                : paymentSelectValue.status,
            Type:
              paymentSelectValue.type === "all"
                ? null
                : paymentSelectValue.type,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await getPaymentHistory(payload);
        saveActivity({
          EventPage: "Cashier",
          EventType: "History_Payments",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: paymentSelectValue?.time || "all",
          attribute3: paymentSelectValue?.status || "all",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
    }
  };

  const navElPayment = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "Type",
      key: "Type",
      render: (text) => <p>{text === 2 ? "Deposit" : "Withdraw"}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "StatusName",
      key: "StatusName",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("BetShop"),
      dataIndex: "BetShopId",
      key: "BetShopId",
      responsive: ["md"],
      render: (text) => <p>{text ? text : "NA"}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("Action"),
      dataIndex: "Action",
      key: "Action",
      responsive: ["md"],
      render: (text) => <p>{text ? text : "NA"}</p>,
    },
  ];

  const handleCheckStatus = (value) => {
    let status = selectedBetStatus?.filter((e) => e.Value === value);
    status = status?.length && status[0]?.Name;
    return status;
  };

  const navElBets = [
    {
      title: t("Id"),
      dataIndex: "BetDocumentId",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "BetDate",
      key: "Date",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "BetAmount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Product-name"),
      dataIndex: "ProductName",
      key: "Product Name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Bet-Type"),
      dataIndex: "BetTypeId",
      key: "Bet Type",
      responsive: ["md"],
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "State",
      key: "Status",
      responsive: ["md"],
      render: (text) => <p>{handleCheckStatus(text)}</p>,
    },
    {
      title: t("Win-Amount"),
      dataIndex: "WinAmount",
      key: "Win Amount",
      render: (text) => <p>{text}</p>,
    },
  ];

  const navElTransaction = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Date"),
      dataIndex: "CreationTime",
      key: "date",
      responsive: ["md"],
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("BetId"),
      dataIndex: "DocumentId",
      key: "Bet Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "OperationTypeName",
      key: "Type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      responsive: ["md"],
      render: (text) => <p>{`EUR ${text}`}</p>,
    },
  ];

  const main = () => {
    return (
      <>
        <div className="d-flex flex-column gap-4">
          <div className="title-border"></div>
          <div className="d-flex justify-content-between align-items-center histUrl-column">
            <div className="d-flex justify-content-between align-items-center histUrl-container">
              {histUrl === "Bets" ? (
                <BetHistory
                  setbetSelectValue={setbetSelectValue}
                  betSelectValue={betSelectValue}
                  selectedBetStatus={selectedBetStatus}
                  selectedBetProducts={selectedBetProducts}
                />
              ) : histUrl === "Transactions" ? (
                <TransectionHistory
                  settransectionSelectValue={settransectionSelectValue}
                  transectionSelectValue={transectionSelectValue}
                  TypeOption={TypeOption}
                />
              ) : (
                <PaymentHistory
                  paymentType={paymentType}
                  selectedBetStatus={selectedBetStatus}
                  setpaymentSelectValue={setpaymentSelectValue}
                  paymentSelectValue={paymentSelectValue}
                />
              )}
            </div>
            <div className="button show-btn-container">
              <div
                className="show-btn"
                id="register-btn-clr"
                onClick={() => handleBetHistory(histUrl)}
              >
                {t("Show")}
              </div>
            </div>
          </div>
          <div className="title-border"></div>
        </div>
        {histUrl === "Bets" ? (
          <MainHistory
            navEl={navElBets}
            empty={t("Bets-empty")}
            Payhistory={Bethistory?.Bets}
            History={Bethistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        ) : histUrl === "Transactions" ? (
          <MainHistory
            navEl={navElTransaction}
            empty={t("Transactions-empty")}
            Payhistory={Transactionhistory?.Transactions}
            History={Transactionhistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        ) : (
          <MainHistory
            navEl={navElPayment}
            empty={t("Payments-empty")}
            Payhistory={Payhistory?.PaymentRequests}
            History={Payhistory}
            setSkipCount={setSkipCount}
            SkipCount={SkipCount}
          />
        )}
      </>
    );
  };

  return (
    <BasicLayout>
      <div className="main-container">
        <NavBar history={history} />
        <div className="history-main">
          <Nav navEl={historyNavEl} url={histUrl} setUrl={setHistUrl} />
          <div className="d-flex flex-column">{main()}</div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default connect(null, {
  getStatusOptions,
  getProductOptions,
  getBetHistory,
  getTransactionTypes,
  getTransactionHistory,
  getPaymentType,
  getPaymentHistory,
})(History);
