import axios from "axios";

class LiveCasinoApi {
  getProductURL = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

export default new LiveCasinoApi();
