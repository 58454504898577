import { GamesActions } from "../types";

const initialState = {
  gamesData: [],
  markGameFavourite: [],
  playNowGame:[],
  loginStatus: false
};

const GamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GamesActions.CHECK_LOGIN_STATUS: {
      return {
        ...state,
        loginStatus: action.payload,
      };
    }
    case GamesActions.GET_GAMES_SUCCESS: {
      return {
        ...state,
        gamesData: action.payload,
      };
    }
    case GamesActions.GET_GAMES_ERROR: {
      return {
        ...state,
        gamesData: action.payload,
      };
    }
    case GamesActions.MARK_GAME_FAVOURITE_SUCCESS: {
      return {
        ...state,
        markGameFavourite: action.payload,
        loginStatus: false,
      };
    }
    case GamesActions.MARK_GAME_FAVOURITE_ERROR: {
      return {
        ...state,
        markGameFavourite: action.payload,
        loginStatus: false,
      };
    }


    case GamesActions.PLAY_NOW_GAME: {
      return {
        ...state,
        playNowGame: action.payload,
        loginStatus: false,
      };
    }
    case GamesActions.PLAY_NOW_GAME_SUCCESS: {
      return {
        ...state,
        playNowGame: action.payload,
        loginStatus: false,
      };
    }
    case GamesActions.PLAY_NOW_GAME_ERROR: {
      return {
        ...state,
        playNowGame: action.payload,
        loginStatus: false,
      };
    }
    default:
      return state;
  }
};

export default GamesReducer;
