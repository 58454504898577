import { Menu } from "antd";
import { t } from "i18next";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { loginModal } from "../Components/Login/actions";
import { Images } from "../Images";
import { MENU_KEYS } from "../Route/navigation";
import routes from "../Route/URLs";
import { getItemFromLocalStorage } from "../utils/localStorage";
import "./style.css";

class SidebarMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [this.firstPath],
      selectedKey: this.path,
    };
  }
  rootSubmenuKeys = Object.values(MENU_KEYS);
  path = window.location.pathname;
  firstPath = this.path.split("/")[2];

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  onMenuItemSelected = async (e) => {
    if (e.key !== this.state.selectedKey) {
      this.setState({ selectedKey: e.key });
      this.props?.history?.push(`${e.key}`);
    }
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: e?.key?.split("/")[2] || "",
    });
  };
  renderSideMenu = (routeMap) => {
    const { themeMode } = this.props;
    const { selectedKey } = this.state;
    return routeMap?.map((menu, i) => {
      return (
        <Menu.Item
          key={menu.url}
          disabled={menu.disabled}
          className={` menu-item-style ${
            selectedKey === menu.url ? "active-menu-img" : ""
          }`}
        >
          <span className="menu-img">
            {selectedKey === menu.url ? (
              <img
                className={themeMode === "dark" ? "" : "active-menu-img-light"}
                src={menu.active}
                alt={menu.active}
                style={{ width: "22px", height: "22px" }}
                key={i}
                data-e2e={menu.name}
              />
            ) : (
              <img
              data-e2e={menu.name}
                src={menu.icon}
                alt={menu.icon}
                style={{ width: "22px", height: "22px" }}
                key={i}
              />
            )}
          </span>
          <span
            id="menu-heading"
            style={{
              fontSize: "12px",
              fontWeight: selectedKey === menu.url ? "600" : "none",
              fontFamily: "Poppins",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {menu.name}
          </span>
        </Menu.Item>
      );
    });
  };

  render() {
    const routesGenerated = [
      {
        name: t("Home"),
        key: MENU_KEYS.HOME_PAGE,
        url: routes.HOME_PAGE,
        icon: Images.Home1,
        active: Images.ActiveHome,
      },
      {
        name: t("Item1"),
        key: MENU_KEYS.CASINO,
        url: routes.CASINO,
        icon: Images.Casino2,
        active: Images.ActiveCasino,
      },
      {
        name: t("Item4"),
        key: MENU_KEYS.LIVE_CASINO,
        url: routes.LIVE_CASINO,
        icon: Images.LiveCasino3,
        active: Images.ActiveLiveCasino,
      },
      {
        name: t("Item2"),
        key: MENU_KEYS.GAMES,
        url: routes.GAMES,
        icon: Images.Games4,
        active: Images.ActiveGames,
      },
      {
        name: t("Sport"),
        key: MENU_KEYS.SPORTS,
        url: routes.SPORTS,
        icon: Images.Sport5,
        active: Images.ActiveSports,
      },
      {
        name: t("In-Play"),
        key: MENU_KEYS.INPLAY,
        url: routes.INPLAY,
        icon: Images.Inplay6,
        active: Images.ActiveInplay,
      },
      // {
      //   name: t("Promotions_1"),
      //   key: MENU_KEYS.PROMOTIONS,
      //   url: routes.PROMOTIONS,
      //   icon: Images.Promotions7,
      //   active: Images.ActivePromotions,
      // },
    ];
    return (
      <Menu
        id="sidebar-ul"
        mode="inline"
        onClick={(e) => this.onMenuItemSelected(e)}
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        selectedKeys={this.state.selectedKey}
      >
        {routesGenerated && this.renderSideMenu(routesGenerated)}
      </Menu>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeMode: state?.themeReducer?.theme,
  };
};

export const SidebarMenuItemsWithRouter = connect(mapStateToProps, {
  loginModal,
})(withRouter(SidebarMenuItems));
