import axios from "axios";

class HomeApi {
  getGames = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

export default new HomeApi();
