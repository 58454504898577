import { Images } from "../../Images";
import routes from "../../Route/URLs";

export const accountBudget = ["500€"];

export const accountClient = ["JC"];

export const emailLogInExample = ["Test@test.com"];

export const MENU_KEYS = {
  HOME_PAGE: "home_page",
  CASINO: "casino",
  LIVE_CASINO: "live_casino",
  GAMES: "games",
  SPORTS: "sports",
  INPLAY: "inplay",
  PROMOTIONS: "promotions",
};

export const sideBarMenu = [
  {
    name: "CASINO",
    key: MENU_KEYS.CASINO,
    url: routes.CASINO,
    icon: Images.Casino2,
    active: Images.ActiveCasino,
  },
  {
    name: "LIVE CASINO",
    key: MENU_KEYS.LIVE_CASINO,
    url: routes.LIVE_CASINO,
    icon: Images.LiveCasino3,
    active: Images.ActiveLiveCasino,
  },
  {
    name: "GAMES",
    key: MENU_KEYS.GAMES,
    url: routes.GAMES,
    icon: Images.Games4,
    active: Images.ActiveGames,
  },
  {
    name: "SPORT",
    key: MENU_KEYS.SPORTS,
    url: routes.SPORTS,
    icon: Images.Sport5,
    active: Images.ActiveSports,
  },
  {
    name: "INPLAY",
    key: MENU_KEYS.INPLAY,
    url: routes.INPLAY,
    icon: Images.Inplay6,
    active: Images.ActiveInplay,
  },
];

export const sideBarPromotion = [
  {
    name: "PROMOTIONS",
    key: MENU_KEYS.PROMOTIONS,
    url: routes.PROMOTIONS,
    icon: Images.Promotions7,
    active: Images.ActivePromotions,
  },
];

export const support_faq = [
  {
    name: "Support",
    icon: Images.supportIcon,
    path: "/support",
  },
  {
    name: "FAQ",
    icon: Images.questionMark,
    path: "/fairness-rng-testing",
  },
];

export const loggedInMenu = [
  { name: "Deposit", icon: Images.depositIcon, url: routes.CASHER_FINANCES },
  { name: "Withdraw", icon: Images.atmIcon, url: routes.CASHER_FINANCES },
  { name: "Bank Accounts", icon: Images.profile, url: routes.CASHER_FINANCES },
  { name: "History", icon: Images.history, url: routes.CASHER_HISTORY },
  { name: "Settings", icon: Images.settingIcon, url: routes.CASHER_PROFILE },
  { name: "My Tickets", icon: Images.ticket, url: routes.CASHER_TICKET },
];

export const logOutFooter = ["Log Out"];

export const mobileLoginTxt = {
  first:
    "By accessing the site, I confirm that I am 18 years olf and I have read the",
  second: "Terms Of Service",
};

export const languageDropdown = [
  { key: "da", img: Images.da, value: "Dansk" },
  { key: "de", img: Images.de, value: "Deutsche" },
  { key: "en", img: Images.en, value: "English" },
  { key: "es", img: Images.es, value: "Español" },
  { key: "fr", img: Images.fr, value: "Français" },
  { key: "it", img: Images.it, value: "Italiano" },
  { key: "nn", img: Images.nn, value: "Norsk" },
  { key: "ru", img: Images.ru, value: "Русский" },
  { key: "zh", img: Images.zh, value: "漢語" },
];

export const currency_symbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
  'AUD': '$',
  'CAD': '$',
};

