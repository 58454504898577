import { Alert } from "antd";
import browser from "browser-detect";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { changePassword } from "../../../Services/actions";
import "../../profile.css";
const browserInfo = browser();

const RightSettings = (props) => {
  const setting = props.settingSelect;
  const { changePassword } = props;
  const [password, setpassword] = useState({
    oldPwd: "",
    NewPwd: "",
    ConfirmPwd: "",
  });
  const [CheckPassword, setCheckPassword] = useState(true);

  const ResponseCode = useSelector(
    (state) => state?.cashierReducer?.password?.data?.ResponseCode
  );

  useEffect(() => {
    if (password.NewPwd === password.ConfirmPwd) {
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
    //eslint-disable-next-line
  }, [password.ConfirmPwd]);

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  const HandleChangePassword = async () => {
    if (!CheckPassword) {
      if (Token) {
        let payload = {
          Loader: true,
          PartnerId: PartnerId,
          TimeZone: 5.5,
          LanguageId: LanguageId,
          ProductId: null,
          Method: "ChangeClientPassword",
          Controller: "Client",
          CategoryId: null,
          PageIndex: 0,
          PageSize: 100,
          ProviderIds: [],
          Index: null,
          ActivationKey: null,
          MobileNumber: null,
          Code: null,
          RequestData: JSON.stringify({
            ClientId: ClientId,
            OldPassword: password.oldPwd,
            PartnerId: PartnerId,
            NewPassword: password.NewPwd,
          }),
          ClientId: ClientId,
          Token: Token,
        };
        await changePassword(payload);
        let deviceIp = await getDeviceIP();
        saveActivity({
          EventPage: "Cashier",
          EventType: "Profile_ChangePwd",
          attribute1: getItemFromLocalStorage("UserName") || "",
          attribute2: "",
          attribute3: "",
          attribute4: navigator.language,
          device_type: browserInfo.mobile ? "Mobile" : "Desktop",
          browser_type: browserInfo.name,
          browser_version: browserInfo.version,
          language: navigator.language,
          ip_address: deviceIp,
        });
      }
      setpassword({
        oldPwd: "",
        NewPwd: "",
        ConfirmPwd: "",
      });
    }
  };

  return (
    <div
      className={`${
        setting ? "none-mobile" : "d-flex flex-column password-section"
      }`}
    >
      <div className="changePassword-container">
        {/* <div className="align-self-end d-flex align-items-center r-nav">
          <img src={edit} alt="" />
          <div className="edit">{t("Edit")}</div>
        </div> */}
        <div className="align-self-start title-border"></div>
        <div className="p-4 prof-r-title">{t("Change-password")}</div>
        <div className="align-self-start title-border"></div>
      </div>
      <form action="" autoComplete="off">
        <div className="set-main d-flex flex-column items-center gap-4 w-100">
          <div className="d-flex flex-column set-main-half gap-2 w-100">
            <div className="fontSize-12">{t("Old-password")}</div>
            <input
              type="password"
              className="profile-input fontSize-12"
              placeholder={t("Old-password")}
              value={password.oldPwd}
              onChange={(e) =>
                setpassword({ ...password, oldPwd: e.target.value })
              }
            />
          </div>
          <div className="d-flex flex-column set-main-half gap-2 w-100">
            <div className="fontSize-12">{t("New-password")}</div>
            <input
              type="password"
              className="profile-input fontSize-12"
              placeholder={t("New-password")}
              value={password.NewPwd}
              onChange={(e) =>
                setpassword({ ...password, NewPwd: e.target.value })
              }
            />
          </div>
          <div className="d-flex flex-column set-main-half gap-2 w-100">
            <div className="fontSize-12">{t("Confirm-password")}</div>
            <input
              type="password"
              className="profile-input fontSize-12"
              placeholder={t("Confirm-password")}
              value={password.ConfirmPwd}
              onChange={(e) =>
                setpassword({ ...password, ConfirmPwd: e.target.value })
              }
            />
            {CheckPassword ? (
              <Alert
                message={"password not match"}
                type="error"
                // style={{ margin: "10px 0px" }}
                showIcon
              />
            ) : null}

            {ResponseCode && ResponseCode !== 0 ? (
              <Alert
                message={"Invalid old password"}
                type="error"
                className="p-0"
                // closable
                style={{ margin: "10px 0px" }}
                showIcon
              />
            ) : null}
          </div>
          <div className="prof-r-pass-text fontSize-12">
            * {t("Password_Validation")}
          </div>
          <div
            className="set-change-btn fontSize-12"
            onClick={HandleChangePassword}
            id="register-btn-clr"
          >
            {t("ChangePassword")}
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(null, {
  changePassword,
})(RightSettings);
