import "./index.css";
import MainSection from "./Finances/MainSection/MainSection";
import { useContext } from "react";
import CasherNav from "./context/context";
import History from "./History/History";
import RightSection from "./Finances/RightSection/RightSection";
import Bonuses from "./Bonuses/Bonuses";
import Ticket from "./Ticket/Ticket";
import Profile from "./Profile/Profile";
import Balance from "./Balance/Balance";

const Main = () => {
  const { navURL, finUrl, selectedCurrency } = useContext(CasherNav);

  const main = () => {
    if (navURL === "Finances")
      return (
        <div className="main">
          <MainSection selectedCurrency={selectedCurrency} />
          {finUrl !== "Bank_Accounts" && <RightSection />}
        </div>
      );
    if (navURL === "History") return <History />;
    if (navURL === "Bonuses") return <Bonuses />;
    if (navURL === "Ticket") return <Ticket />;
    if (navURL === "Profile") return <Profile />;
    if (navURL === "Balance") return <Balance />;
  };

  return <div className="main-container">{main()}</div>;
};

export default Main;
