import "antd/dist/antd.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App.js";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CasherProvider } from "./modules/Casher/context/context";
import store from "./Store/store";

ReactDOM.render(
  <CasherProvider>
      <Provider store={store}>
       <App />
      </Provider>
  </CasherProvider>,
  document.getElementById("root")
);
