import Nav from "../../_sharedComponents/SubNav/Nav";
import { profileNavEl } from "../../lib/constants";
import CasherNav from "../../context/context";
import { useContext, useEffect } from "react";
import MainHistory from "../../History/Bets/Main/MainHistory";
import Verification from "../AccVer/Verification/Verification";
import { connect, useSelector } from "react-redux";
import { getItemFromLocalStorage } from "../../../../utils/localStorage";
import { getdocumentStatus } from "../Services/actions";
import moment from "moment";
import { t } from "i18next";

const AccVer = (props) => {
  const { profileUrl, setProfileUrl } = useContext(CasherNav);
  const { getdocumentStatus, navMobile, setNavMobile } = props;

  const DocumentHistory = useSelector(
    (state) => state?.profileReducer?.documentHistory?.data?.ResponseObject
  );
  const Documentype = useSelector(
    (state) => state?.profileReducer?.documentType?.data?.ResponseObject
  );
  const Statustype = useSelector(
    (state) => state?.profileReducer?.documentStatus?.data?.ResponseObject
  );

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  const DocumentType = (value) => {
    if (Documentype) {
      let type = Documentype.filter((e, i) => e.Id === value);
      let name = type.length ? type[0]?.Name : "NA";
      return name;
    }
  };

  const StatusType = (value) => {
    if (Statustype) {
      let type = Statustype.filter((e, i) => e.Id === value);
      let name = type.length ? type[0]?.Name : "NA";
      return name;
    }
  };

  const navElProfile = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Document type"),
      dataIndex: "DocumentTypeId",
      key: "DocumentTypeId",
      render: (text) => <p>{DocumentType(text)}</p>,
    },
    {
      title: t("Creation Date"),
      dataIndex: "CreationTime",
      key: "CreationTime",
      render: (text) => <p>{moment(text).format("LLL")}</p>,
    },
    {
      title: t("Status"),
      dataIndex: "Status",
      key: "Status",
      render: (text) => <p>{StatusType(text)}</p>,
    },
  ];

  const HandleDocumentsStatus = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetKYCDocumentStatesEnum",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getdocumentStatus(payload);
  };

  useEffect(() => {
    HandleDocumentsStatus();
    //eslint-disable-next-line
  }, []);

  const main = () => {
    if (profileUrl === "AccountVerification")
      return (
        <>
          <div className="d-flex flex-column gap-4">
            <div className="title-border"></div>
            <Verification />
            <div className="title-border"></div>
          </div>
          <MainHistory
            navEl={navElProfile}
            Payhistory={DocumentHistory}
            empty={t("Documents-empty")}
          />
        </>
      );
  };

  return (
    <div className="history-main d-flex flex-column gap-2">
      <Nav
        navEl={profileNavEl}
        url={profileUrl}
        setUrl={setProfileUrl}
        navMobile={navMobile}
        setNavMobile={setNavMobile}
      />
      <div className="d-flex flex-column">{main()}</div>
    </div>
  );
};

export default connect(null, {
  getdocumentStatus,
})(AccVer);
