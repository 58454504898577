import BlackNav from "../../History/BlackNav/BlackNav";
import { Images } from "../../../../Images";
import { accountsEl } from "../../lib/constants";
import { t } from "i18next";

const Accounts = () => {
  const { noAccounts } = Images;

  return (
    <>
      <BlackNav navEl={accountsEl} />
      <div className="h-100 p-5 d-flex flex-column align-items-center justify-content-center gap-4">
        <img src={noAccounts} alt="no account" />
        <div className="banksEmpty">{t("Banks-empty")}</div>
      </div>
    </>
  );
};

export default Accounts;
