// import { getImageFullUrl } from "../utils/helrevper";
const HugoCarts = "/images/HugoCarts.gif";
const InvisibleManGif = "/images/InvisibleMan.gif";
const JumboStampede = "/images/JumboStampede.gif";
const RazorShark = "/images/RazorShark.gif";
const SweetBonanzagif = "/images/SweetBonanza.gif";

const Homebackgrounddark = "/images/background/Homebackgrounddark.svg";
const Homebackgroundlight = "/images/background/Homebackgroundlight.svg";
const crazyTime1 = "/images/home/crazytime-1.png";
const crazyTime2 = "/images/home/crazytime-2.png";
const crazyTime3 = "/images/home/crazytime-3.png";
const bigbassBonanza1 = "/images/home/bigbassbonanza-1.png";
const bigbassBonanza2 = "/images/home/bigbassbonanza-2.png";
const bigbassBonanza3 = "/images/home/bigbassbonanza-3.png";
const Prev = "/images/home/prev.svg";
const Next = "/images/home/next.svg";
const LoveIcon = "/images/home/love.svg";
const Fortunecatsgoldenstacks = "/images/home/fortunecatsgoldenstacks.svg";
const Gigantoonz = "/images/home/gigantoonz.svg";
const Goldenfishtank2 = "/images/home/goldenfishtank2.svg";
const Mental = "/images/home/mental.svg";
const Thedoghouse = "/images/home/thedoghouse.svg";
const Home1 = "/images/sidebar/home1.svg";
const Casino2 = "/images/sidebar/casino2.svg";
const LiveCasino3 = "/images/sidebar/livecasino3.svg";
const Games4 = "/images/sidebar/games4.svg";
const Sport5 = "/images/sidebar/sport5.svg";
const Inplay6 = "/images/sidebar/inplay6.svg";
const Promotions7 = "/images/sidebar/promotions7.svg";
const SidebarLogo = "/images/sidebar/logo.svg";
const loginLogo = "/images/login/oceanbet-logo.svg";
const mail = "/images/register/mail.svg";
const fblogo = "/images/login/fb-logo.svg";
const googlelogo = "/images/login/google-logo.svg";
const lock = "/images/login/lock.svg";
const vklogo = "/images/login/vk-logo.svg";
const login = "/images/login/login-side.svg";
const flag = "/images/register/flag.svg";
const calendar = "/images/register/calendar.svg";
const maps = "/images/register/maps.svg";
const discount = "/images/register/discount.svg";
const document = "/images/register/document.svg";
const user = "/images/login/user.svg";
const logout = "/images/logout/logout.svg";
const stepper_A1 = "/images/register/stepper-A1.svg";
const stepper_2 = "/images/register/stepper-2.svg";
const stepper_3 = "/images/register/stepper-3.svg";
const stepper_A2 = "/images/register/stepper-A2.svg";
const stepper_A3 = "/images/register/stepper-A3.svg";
const HomeBanner = "/images/home/homebanner.svg";
const HomeCasino = "/images/home/homecasino2.png";
const HomeLiveCasino = "/images/home/homelivecasino2.png";
const HomeGames = "/images/home/homegames2.png";
const HomeSports = "/images/home/homesports2.png";
const HomeInplay = "/images/home/homeinplay2.png";
const age_limit = "/images/footer/age-limit.svg";
const casino_2_bg = "/images/footer/casino-2-bg.svg";
const casino_2 = "/images/footer/casino-2.svg";
const discord = "/images/footer/discord.svg";
const gmail = "/images/footer/gmail.svg";
const mastercard = "/images/footer/mastercard.svg";
const neteller = "/images/footer/neteller.svg";
const paypal = "/images/footer/paypal.svg";
const paysafecard = "/images/footer/paysafecard.svg";
const skrill = "/images/footer/skrill.svg";
const telegram = "/images/footer/telegram.svg";
const twitter = "/images/footer/twitter.svg";
const visa = "/images/footer/visa.svg";
const Bitmap2 = "/images/home/Bitmap2.svg";
const Bitmap3 = "/images/home/Bitmap3.svg";
const Bitmap4 = "/images/home/Bitmap4.svg";
const Bitmap5 = "/images/home/Bitmap5.svg";
const Bitmap1 = "/images/home/Bitmap1.svg";
const Bitmap6 = "/images/home/Bitmap6.svg";
const ActiveHome = "/images/sidebar/ActiveHome1.svg";
const ActiveCasino = "/images/sidebar/ActiveCasino2.svg";
const ActiveLiveCasino = "/images/sidebar/ActiveLiveCasino3.svg";

const ActiveGames = "/images/sidebar/ActiveGames4.svg";
const ActiveSports = "/images/sidebar/ActiveSports5.svg";
const ActiveInplay = "/images/sidebar/ActiveInplay6.svg";
const ActivePromotions = "/images/sidebar/ActivePromotions7.svg";

const DailyJackpot = "/images/home/daily-jackpot.svg";
const DailyJackpot1 = "/images/home/daily-jackpot1.svg";
const DailyJackpot2 = "/images/home/daily-jackpot2.svg";
const DailyJackpot3 = "/images/home/daily-jackpot3.svg";
const DailyJackpot4 = "/images/home/daily-jackpot4.svg";
const DailyJackpot5 = "/images/home/daily-jackpot5.svg";
const subscribe1 = "/images/home/subscribe-1.svg";
const subscribe2 = "/images/home/subscribe-2.svg";
const GamesBanner = "/images/games/gamesBanner.svg";

// Casher
const finances = "/images/casher/nav/finances.svg";
const history = "/images/casher/nav/history.svg";
const bonuses = "/images/casher/nav/bonuses.svg";
const profile = "/images/casher/nav/profile.svg";
const ticket = "/images/casher/nav/ticket.svg";
const balance = "/images/casher/nav/balance.svg";
const financesIn = "/images/casher/nav/inactive/financesIn.svg";
const historyIn = "/images/casher/nav/inactive/historyIn.svg";
const bonusesIn = "/images/casher/nav/inactive/bonusesIn.svg";
const profileIn = "/images/casher/nav/inactive/profileIn.svg";
const ticketIn = "/images/casher/nav/inactive/ticketIn.svg";
const balanceIn = "/images/casher/nav/inactive/balanceIn.svg";
const downArrow = "/images/casher/downArrow.svg";
const downArrowGray = "/images/casher/downArrowGray.svg";
const info = "/images/casher/info.svg";
const noAccounts = "/images/casher/noAccounts.svg";
const path = "/images/casher/path.svg";
const selected = "/images/casher/selected.svg";
const shape = "/images/casher/shape.svg";
const luxon = "/images/casher/paymethods/luxon.svg";
const luxonlight = "/images/casher/paymethods/luxonlight.svg";
const skrillCalight = "/images/casher/paymethods/skrillCalight.svg";
const netellerCalight = "/images/casher/paymethods/netellerCalight.svg";
const cryptolight = "/images/casher/paymethods/cryptolight.svg";

const cryptonPay = "/images/casher/paymethods/cryptonPay.svg";
const crypto = "/images/casher/paymethods/crypto.svg";
const netellerCa = "/images/casher/paymethods/netellerCa.svg";
const skrillCa = "/images/casher/paymethods/skrillCa.svg";
const american = "/images/casher/paymethods/american.svg";
const master = "/images/casher/paymethods/master.svg";
const nowPay = "/images/casher/paymethods/nowPay.svg";
const visaCa = "/images/casher/paymethods/visaCa.svg";
const luxonIn = "/images/casher/paymethods/inactive/luxonIn.svg";
const cryptonPayIn = "/images/casher/paymethods/inactive/cryptonPayIn.svg";
const cryptoIn = "/images/casher/paymethods/inactive/cryptoIn.svg";
const netellerCaIn = "/images/casher/paymethods/inactive/netellerCaIn.svg";
const americanIn = "/images/casher/paymethods/inactive/americanIn.svg";
const masterIn = "/images/casher/paymethods/inactive/masterIn.svg";
const nowPayIn = "/images/casher/paymethods/inactive/nowPayIn.svg";
const visaCaIn = "/images/casher/paymethods/inactive/visaCaIn.svg";
const skrillCaIn = "/images/casher/paymethods/inactive/skrillCaIn.svg";
const xButton = "/images/casher/xButton.svg";
const noTicket = "/images/casher/noTicket.svg";
const edit = "/images/casher/edit.svg";
const chooseFile = "/images/casher/chooseFile.svg";

// casino
const cherry = "/images/casino/cherry.svg";
const pokerCardsLight = "/images/casino/poker-cards-light.svg";
const starLight = "/images/casino/star-light.svg";
const starsLight = "/images/casino/stars-light.svg";
const diceLight = "/images/casino/dice-2-light.svg";
const BaccaratSmall = "/images/casino/baccarat-small.svg";
const heartLight = "/images/casino/heart-light.svg";
const cherryLight = "/images/casino/cherry-light.svg";
const pokerCards = "/images/casino/poker-cards.svg";
const star = "/images/casino/star.svg";
const stars = "/images/casino/stars.svg";
const dice = "/images/casino/dice-2.svg";
const heart = "/images/casino/heart.svg";

const gameProvider1 = "/images/casino/game-provider-1.svg";
const gameProviderA1 = "/images/casino/game-provider-active1.svg";
const gameProvider2 = "/images/casino/game-provider-2.svg";
const CasinoPrev = "/images/casino/casino-prev.svg";
const CasinoNext = "/images/casino/casino-next.svg";
const CasinoHr = "/images/casino/casino-hr.svg";
const resize = "/images/casino/resize.svg";

//live casino
const pragmatic = "/images/livecasino/pragmatic.svg";
const evolution = "/images/livecasino/evolution.svg";
const ezugi = "/images/livecasino/ezugi.svg";
const vivoGaming = "/images/livecasino/vivoGaming.svg";
const playIcon = "/images/livecasino/playIcon.svg";

//contact us
const questionMark = "/images/footer/support/questionMark.svg";
const openAnswer = "/images/footer/support/openAnswer.svg";
const closeAnswer = "/images/footer/support/closeAnswer.svg";
const blackPhoneIcon = "/images/footer/support/blackPhoneIcon.svg";
const blackEmailIcon = "/images/footer/support/blackEmailIcon.svg";
const blackAddressIcon = "/images/footer/support/blackAddressIcon.svg";
const bueTelegramIcon = "/images/footer/support/bueTelegramIcon.svg";
const bueDiscordIcon = "/images/footer/support/bueDiscordIcon.svg";
const bueTwitterIcon = "/images/footer/support/bueTwitterIcon.svg";

//about_us
const bitmapImg = "/images/footer/aboutUs/bitmapImg.svg";
const scheduleIcon = "/images/footer/aboutUs/scheduleIcon.svg";
const spadeTokenIcon = "/images/footer/aboutUs/spadeTokenIcon.svg";
const search3Icon = "/images/footer/aboutUs/search3Icon.svg";
const supportIcon = "/images/footer/aboutUs/supportIcon.svg";

//navBar
const burgerMenu = "/images/navbar/burgerMenu.svg";
const user_2 = "/images/navbar/user_2.svg";
const moon = "/images/navbar/moon.svg";
const lightMoonTheme = "/images/home/light-moon-theme.svg";
const swicth_dark_mode = "/images/navbar/dark_ligth_Mode.svg";
const switch_light_mode = "/images/home/light-mode-switch-icon.svg";
const mobileLogIn_background = "/images/navbar/mobileLogIn_background.svg";
const logOutIcon = "/images/navbar/logOutIcon.svg";
const myTicketIcon = "/images/navbar/myTicketIcon.svg";
const settingIcon = "/images/navbar/settingIcon.svg";
const historyIcon = "/images/navbar/historyIcon.svg";
const atmIcon = "/images/navbar/atmIcon.svg";
const depositIcon = "/images/navbar/depositIcon.svg";

//language
const da = "/images/language/da.png";
const de = "/images/language/de.png";
const es = "/images/language/es.png";
const fr = "/images/language/fr.png";
const it = "/images/language/it.png";
const nn = "/images/language/nn.png";
const ru = "/images/language/ru.png";
const zh = "/images/language/zh.png";
const en = "/images/language/en.png";

//Top-games
const InvisibleMan = "/images/top-games/invisibleman.png";
const MoonPrincess = "/images/top-games/moonprincess.png";
const Bigfinbay = "/images/top-games/bigfinbay.png";
const BookofDead = "/images/top-games/bookofdead.png";

//bonusbay
const dinopolis = "/images/bonus-bay/dinopolis.png";
const snakearena = "/images/bonus-bay/snakearena.png";
const punkrocker = "/images/bonus-bay/punkrocker.png";
const wanteddeadorawild = "/images/bonus-bay/wanteddeadorawild.png";
const bookofdead1 = "/images/bonus-bay/bookofdead-1.png";

//tablegames
const tableblackjack = "/images/table-games/table-blackjack.png";
const tableroulette = "/images/table-games/table-roulette.png";
const tablebaccarat = "/images/table-games/table-baccarat.png";
const tablecraps = "/images/table-games/table-craps.png";
const tablecarabpoker = "/images/table-games/table-carabpoker.png";
const livecrazytime = "/images/live-games/live-crazytime.png";
const livemegaball = "/images/live-games/live-megaball.png";
const prevLight = "/images/home/prev_light.svg";
const nextLight = "/images/home/next_light.svg";
const dropdownLight = "/images/home/dropdown_light.svg";
const dropdownDark = "/images/home/dropdown_dark.svg";
const timeDark = "/images/home/time_dark.svg";
const timeLight = "/images/home/time_light.svg";

const inplayActiveLight = "/images/sidebar/menu-inplay-active-light.svg";
const inplayActiveDark = "/images/sidebar/menu-inplay-active-dark.svg";
const gamesMenuActiveDark = "/images/sidebar/menu-game-active-dark.svg";
const gamesMenuActiveLight = "/images/sidebar/menu-game-active-light.svg";
const bonusActiveLight = "/images/home/bonus-light-active.svg";
const balanceActieLight = "/images/home/balance-light-active.svg";
const homeMenuDarkActive = "/images/sidebar/home-image-dark-active.svg";
const homeMenuLightActive = "/images/sidebar/home-image-light-active.svg";
const promotionMenuActiveDark =
  "/images/sidebar/menu-promotions-active-dark.svg";
const promotionMenuActiveLight =
  "/images/sidebar/menu-promotions-active-light.svg";
const inplayMenuActiveDark = "/images/home/inplay-dark-menu-active.svg";
const liveCasinoMenuActiveLight =
  "/images/sidebar/menu-live-casino-active-light.svg";
const allGamesLightHover = "/images/home/all-games-light-hover.svg";
const sportsDarkMenuActive = "/images/sidebar/menu-sports-active-dark.svg";
const sportsLightMenuActive = "/images/sidebar/menu-sports-active-light.svg";
const liveCasinoDArkMenuActive =
  "/images/sidebar/menu-live-casino-active-dark.svg";
const liveCasinoLightPlayBtn = "/images/home/live-casino-light-play-btn.svg";
const lightModeSwitchIcon = "/images/home/light-mode-switch-icon.svg";
const noDataLight = "/images/home/empty-light.svg";
const infoLightIcon = "/images/home/info_light.svg";
const safeBetterBgLight = "/images/home/safe-better-bg-light.svg";
const casinoLightActive = "/images/sidebar/menu-casino-active-light.svg";
const casinoDarkActive = "/images/sidebar/menu-casino-active-dark.svg";

const AztecGold = "/images/megaways/Aztec-Gold-Extra-Gold-Megaways.png";
const BigBadWolf = "/images/megaways/Big-Bad-Wolf-Megaways.png";
const DragonMatch = "/images/megaways/Dragon-Match-Megaways.png";
const Fruitshop = "/images/megaways/Fruitshop-Megaways.png";
const Morgana = "/images/megaways/Morgana-Megaways.png";
const Moriarty = "/images/megaways/Moriarty-Megaways.png";
const Royale = "/images/megaways/Royale-with-Cheese-Megaways.png";
const Scrooge = "/images/megaways/Scrooge-Megaways.png";
const Starz = "/images/megaways/Starz-Megaways.gif";
const TyrantKing = "/images/megaways/Tyrant-King-Megaways.png";
const Sweets = "/images/sweet/Super-Sweets.gif";
const Alchemy = "/images/sweet/Sweet-Alchemy.gif";
const SweetBonanza = "/images/sweet/Sweet-Bonanza.png";
const SweetieLand = "/images/sweet/Sweetie-Land.png";
const SweetSugar = "/images/sweet/Sweet-Sugar.png";
const SweetWin = "/images/sweet/Sweet-Win.png";
const Dragon = "/images/dragon/Dragon.png";
const DoubleDragons = "/images/dragon/Double-Dragons.png";
const DragonHorn = "/images/dragon/Dragon-Horn.gif";
const DragonShrine = "/images/dragon/Dragon-Shrine.png";
const FloatingDragon = "/images/dragon/Floating-Dragon.png";
const LuckyDragon = "/images/dragon/Lucky-Dragon.png";
const HulaMagic = "/images/magic/Hula-Magic.gif";
const JadeMagician = "/images/magic/Jade-Magician.png";
const MagicForest = "/images/magic/Magic-Forest.png";
const MagiciansSecrets = "/images/magic/Magicians-Secrets.png";
const Magicious = "/images/magic/Magicious.png";
const MagicRings = "/images/magic/Magic-Of-The-Ring.png";

const BookAztec = "/images/home/Book-of-Aztec-Select.png";
const BookDarkness = "/images/home/Book-of-Darkness.gif";
const BookDead = "/images/home/Book-of-Dead.png";
const BookDuat = "/images/home/Book-of-Duat.gif";
const BookFortune = "/images/home/Book-of-Fortune.gif";
const BookFruits = "/images/home/Book-of-Fruits-10.gif";
const BookImmortals = "/images/home/Book-of-Immortals.gif";
const BookKingdoms = "/images/home/Book-of-Kingdoms.png";
const BookSkulls = "/images/home/Book-of-Skulls.gif";
const BookTombs = "/images/home/Book-of-Tombs.png";
const YetiGigablox = "/images/home/90k-Yeti-Gigablox.png";
const ChaosCrew = "/images/home/Chaos-Crew.png";
const ChiliPop = "/images/home/Chili-Pop.png";
const Deadwood = "/images/home/Deadwood.png";
const EsqueletoExplosivo = "/images/home/Esqueleto-Explosivo.png";

export const Images = {
  homeMenuLightActive,
  homeMenuDarkActive,
  switch_light_mode,
  swicth_dark_mode,
  inplayActiveDark,
  sportsLightMenuActive,
  gamesMenuActiveLight,
  casinoDarkActive,
  casinoLightActive,
  safeBetterBgLight,
  infoLightIcon,
  noDataLight,
  lightModeSwitchIcon,
  lightMoonTheme,
  liveCasinoLightPlayBtn,
  liveCasinoDArkMenuActive,
  sportsDarkMenuActive,
  liveCasinoMenuActiveLight,
  allGamesLightHover,
  inplayActiveLight,
  gamesMenuActiveDark,
  bonusActiveLight,
  balanceActieLight,
  promotionMenuActiveDark,
  promotionMenuActiveLight,
  inplayMenuActiveDark,
  GamesBanner,
  Home1,
  Casino2,
  LiveCasino3,
  Games4,
  Sport5,
  Inplay6,
  Promotions7,
  SidebarLogo,
  login,
  loginLogo,
  mail,
  fblogo,
  googlelogo,
  lock,
  vklogo,
  flag,
  calendar,
  discount,
  document,
  maps,
  user,
  stepper_2,
  stepper_3,
  stepper_A1,
  stepper_A2,
  stepper_A3,
  HomeBanner,
  HomeCasino,
  HomeLiveCasino,
  HomeGames,
  HomeSports,
  HomeInplay,
  age_limit,
  casino_2,
  casino_2_bg,
  discord,
  gmail,
  mastercard,
  neteller,
  paypal,
  paysafecard,
  skrill,
  telegram,
  twitter,
  visa,
  Bitmap1,
  Bitmap2,
  Bitmap3,
  Bitmap4,
  Bitmap5,
  Bitmap6,
  ActiveHome,
  ActiveCasino,
  ActiveLiveCasino,
  ActiveGames,
  ActiveSports,
  ActiveInplay,
  ActivePromotions,
  DailyJackpot1,
  DailyJackpot2,
  DailyJackpot3,
  DailyJackpot4,
  DailyJackpot5,
  DailyJackpot,
  subscribe1,
  subscribe2,
  Prev,
  Next,
  LoveIcon,
  Fortunecatsgoldenstacks,
  Gigantoonz,
  Goldenfishtank2,
  Mental,
  Thedoghouse,
  finances,
  history,
  bonuses,
  profile,
  ticket,
  balance,
  financesIn,
  historyIn,
  bonusesIn,
  profileIn,
  ticketIn,
  balanceIn,
  downArrow,
  downArrowGray,
  info,
  luxon,
  cryptonPay,
  crypto,
  netellerCa,
  skrillCa,
  luxonIn,
  cryptonPayIn,
  cryptoIn,
  netellerCaIn,
  skrillCaIn,
  noAccounts,
  path,
  selected,
  shape,
  xButton,
  noTicket,
  cherry,
  star,
  stars,
  pokerCards,
  dice,
  BaccaratSmall,
  heart,
  edit,
  pragmatic,
  evolution,
  ezugi,
  vivoGaming,
  playIcon,
  chooseFile,
  gameProvider1,
  gameProvider2,
  gameProviderA1,
  CasinoPrev,
  CasinoNext,
  CasinoHr,
  resize,
  american,
  americanIn,
  visaCa,
  visaCaIn,
  master,
  masterIn,
  nowPay,
  nowPayIn,
  logout,
  questionMark,
  openAnswer,
  closeAnswer,
  blackPhoneIcon,
  blackEmailIcon,
  blackAddressIcon,
  bueTelegramIcon,
  bueDiscordIcon,
  bueTwitterIcon,
  bitmapImg,
  scheduleIcon,
  spadeTokenIcon,
  search3Icon,
  supportIcon,
  burgerMenu,
  user_2,
  moon,
  mobileLogIn_background,
  logOutIcon,
  myTicketIcon,
  settingIcon,
  historyIcon,
  atmIcon,
  depositIcon,
  da,
  de,
  it,
  nn,
  es,
  fr,
  zh,
  ru,
  en,
  InvisibleMan,
  BookofDead,
  Bigfinbay,
  MoonPrincess,
  dinopolis,
  snakearena,
  punkrocker,
  wanteddeadorawild,
  bookofdead1,
  tablebaccarat,
  tableblackjack,
  tablecarabpoker,
  tablecraps,
  tableroulette,
  livecrazytime,
  livemegaball,
  nextLight,
  prevLight,
  timeDark,
  timeLight,
  dropdownLight,
  dropdownDark,
  pokerCardsLight,
  diceLight,
  starLight,
  starsLight,
  heartLight,
  cherryLight,
  AztecGold,
  BigBadWolf,
  DragonMatch,
  Fruitshop,
  Morgana,
  Moriarty,
  Royale,
  Scrooge,
  Starz,
  TyrantKing,
  Sweets,
  SweetWin,
  SweetieLand,
  SweetBonanza,
  SweetSugar,
  Alchemy,
  Dragon,
  DragonHorn,
  DragonShrine,
  LuckyDragon,
  DoubleDragons,
  FloatingDragon,
  HulaMagic,
  JadeMagician,
  MagicForest,
  MagicRings,
  MagiciansSecrets,
  Magicious,
  BookAztec,
  BookDarkness,
  BookDead,
  BookDuat,
  BookFortune,
  BookFruits,
  BookImmortals,
  BookKingdoms,
  BookSkulls,
  BookTombs,
  YetiGigablox,
  ChaosCrew,
  ChiliPop,
  Deadwood,
  EsqueletoExplosivo,
  HugoCarts,
  InvisibleManGif,
  JumboStampede,
  RazorShark,
  SweetBonanzagif,
  crazyTime1,
  crazyTime2,
  crazyTime3,
  bigbassBonanza1,
  bigbassBonanza2,
  bigbassBonanza3,
  luxonlight,
  skrillCalight,
  netellerCalight,
  cryptolight,
  Homebackgrounddark,
  Homebackgroundlight,
};
