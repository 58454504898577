import { BonusActions } from "../types";

export const bonusList = (payload) => {
  return { type: BonusActions.GET_BONUS_STATUS_LIST, payload: payload };
};

export const bonusStatusListSuccess = (payload) => {
  return { type: BonusActions.GET_BONUS_STATUS_LIST_SUCCESS, payload: payload };
};

export const bonusStatusListError = (payload) => {
  return {
    type: BonusActions.GET_BONUS__STATUS_LIST_ERROR,
    payload: payload,
  };
};

export const bonuses = (payload) => {
  return { type: BonusActions.GET_BONUSES, payload: payload };
};

export const bousesSuccess = (payload) => {
  return { type: BonusActions.GET_BONUSES_SUCCESS, payload: payload };
};

export const bonusesError = (payload) => {
  return {
    type: BonusActions.GET_BONUSES_ERROR,
    payload: payload,
  };
};
