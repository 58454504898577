import axios from "axios";

class sportsApi {
  getSportsProductURL = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetProductUrl",
      data
    );
}

export default new sportsApi();
