import { put, takeLatest, call } from "redux-saga/effects";
import { BonusActions } from "../types";
import BonusApi from "../api";
import { getError, openNotificationWithIcon } from "../../../../../utils/helper";

function* bonusStatusList(action) {
  try {
    const response = yield call(BonusApi.bonusStatusList, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: BonusActions.GET_BONUS_STATUS_LIST_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: BonusActions.GET_BONUS__STATUS_LIST_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* bonuses(action) {
  try {
    const response = yield call(BonusApi.bonusApi, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: BonusActions.GET_BONUSES_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: BonusActions.GET_BONUSES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* bonusSaga() {
  yield takeLatest(BonusActions.GET_BONUS_STATUS_LIST, bonusStatusList);
  yield takeLatest(BonusActions.GET_BONUSES, bonuses);

}

