import { Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../../Images/index";
import { loginModal } from "../../Login/actions";
import "./Register.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const Register = (props) => {
  const [formsteps, setFormSteps] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [registerPayload, setRegisterPayload] = useState({});
  const modalStatus = useSelector(
    (state) => state?.loginReducer?.registerStatusModal
  );

  return (
    <Modal
      visible={modalStatus ? modalStatus : props.registerModalStatus}
      footer={null}
      width="700px"
      centered
      className="login"
      onCancel={() => {
        props.setregisterModal(false);
        setFormSteps(1);
      }}
    >
      <div className="row m-0">
        <div className="login-bg col-6 col-md-6 p-0">
          <img src={Images.login} height="100%" alt="" />
        </div>
        <div className="col-6 col-md-6 p-0 login-body">
          <div className="row mx-0 ">
            <div className="col-12 login-header">
              <img src={Images.SidebarLogo} width="100px" alt="" />
            </div>
            <div className="col-12 ">
              <div className="container pt-3 pb-2">
              <div className="login-lable">Registration</div>
                <div className="row pb-4">
                  <div
                    className="col-4 cursor-pointer"
                    onClick={() => setFormSteps(1)}
                  >
                    <img
                      src={`https://dev.oceanbetuk.io${Images.stepper_A1}`}
                      alt=""
                    />
                  </div>
                  <div className="col-4">
                    {formsteps >= 2 ? (
                      <img
                        className=" cursor-pointer"
                        onClick={() => setFormSteps(2)}
                        src={`https://dev.oceanbetuk.io${Images.stepper_A2}`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="cursor-not-allowed"
                        src={`https://dev.oceanbetuk.io${Images.stepper_2}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="col-4">
                    {formsteps >= 3 ? (
                      <img
                        className=" cursor-pointer"
                        onClick={() => setFormSteps(3)}
                        src={`https://dev.oceanbetuk.io${Images.stepper_A3}`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="cursor-not-allowed"
                        src={`https://dev.oceanbetuk.io${Images.stepper_3}`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                {formsteps === 1 && (
                  <Step1
                    registerPayload={registerPayload}
                    setRegisterPayload={setRegisterPayload}
                    setFormSteps={setFormSteps}
                  />
                )}
                {formsteps === 2 && (
                  <Step2
                    registerPayload={registerPayload}
                    setRegisterPayload={setRegisterPayload}
                    setFormSteps={setFormSteps}
                  />
                )}
                {formsteps === 3 && (
                  <Step3
                    registerPayload={registerPayload}
                    setRegisterPayload={setRegisterPayload}
                    setFormSteps={setFormSteps}
                    setregisterModal={props.setregisterModal}
                  />
                )}
                <div className="col-12 mt-10">
                  <h1>
                    {t("Already_have_an_account")}{" "}
                    <span
                      className="cursor-pointer login-footer-span-register"
                      onClick={() => dispatch(loginModal(true))}
                    >
                      {t("Sign In")}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Register;
