import { Select } from "antd";
import browser from "browser-detect";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { currency_symbols } from "../../../../../Components/Navbar/constantNavBar";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { withdraw } from "../../../Services/actions/index";
import Balances from "./Balances/Balances";
import "./withdraw.css";
const { Option } = Select;
const browserInfo = browser();

const Withdraw = (props) => {
  const { withdraw } = props;
  const [formdata, setFormdata] = useState({
    amount: "",
    wallet_no: "",
    account_type: "select",
  });
  const [errMsg, setErrMsg] = useState("");

  let ClientBalance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data
  );

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let SelectedCard = useSelector(
    (state) => state?.cashierReducer?.selectedCard
  );

  const availableBalance = {
    clientUnused: {
      title: "client unused balance",
      total: ClientBalance?.Balances ? ClientBalance?.Balances[0]?.Balance : 0,
      available: ClientBalance?.Balances
        ? ClientBalance?.Balances[0]?.Balance
        : 0,
    },
    clientUsed: {
      title: "client used balance",
      total: ClientBalance?.Balances ? ClientBalance?.Balances[1]?.Balance : 0,
      available: ClientBalance?.Balances
        ? ClientBalance?.Balances[1]?.Balance
        : 0,
    },
    clientBooking: { title: "client booking", total: 0, available: 0 },
    clientBonus: {
      title: "client bonus balance",
      total: 0,
      available: 0,
    },
  };
  useEffect(() => {
    setFormdata({
      wallet_no: "",
      amount: "",
      account_type: "select",
    });
    // eslint-disable-next-line
  }, [ClientBalance]);

  const WithdrawMoney = async () => {
    // eslint-disable-next-line
    if (formdata.amount == "" || formdata.amount == 0) {
      setErrMsg("Please enter valid amount");
    } else {
      let payload = {
        Loader: true,
        Controller: "Document",
        Method: "CreatePaymentRequest",
        Token: Token,
        ClientId: ClientId,
        PartnerId: SelectedCard?.PartnerId,
        RequestData: JSON.stringify({
          PartnerId: SelectedCard?.PartnerId,
          Amount: formdata.amount,
          Info: JSON.stringify({
            Amount: formdata.amount,
            BankId: "",
            BankName: "",
            BankAccountNumber: "",
            AccountType: formdata.account_type,
            NameSurname: "",
            WithdrawCode: "",
            CardType: "",
            Name: "",
            CardNumber: "",
            ExpDate: "",
            ExpDateMM: "",
            ExpDateYY: "",
            Info: "",
            MobileNumber: "",
            SMSCode: "",
            CardHolderName: "",
            ExpirationDate: "",
            Email: "",
            BankACH: "",
            BankAccountHolder: "",
            BankBranchName: "",
            WalletNumber: formdata.wallet_no, ////hggy6767fytfyt
            NationalId: "",
            TypeDocumentId: "",
          }),
          PaymentSystemId: SelectedCard?.PaymentSystemId,
          PaymentForm: "",
          ImageName: "",
          ClientId: ClientId,
          CurrencyId: SelectedCard?.CurrencyId,
          CashCode: "",
          BetShopId: "",
        }),
        TimeZone: 5.5,
      };
      await withdraw(payload);
      let deviceIp = await getDeviceIP();
      saveActivity  ({
        EventPage: "Cashier",
        EventType: "Withdraw_Confirm",
        attribute1: getItemFromLocalStorage("UserName") || "",
        attribute2: SelectedCard?.PaymentSystemId,
        attribute3: SelectedCard?.CurrencyId,
        attribute4: formdata.amount,
        attribute5: navigator.language,
        device_type: browserInfo.mobile ? "Mobile" : "Desktop",
        browser_type: browserInfo.name,
        browser_version: browserInfo.version,
        language: navigator.language,
        ip_address: deviceIp,
      });
    }
  };

  const HandleSubmit = () => {
    WithdrawMoney();
  };
  const handleAmount = (e) => {
    let total =
      availableBalance.clientUnused.available -
      availableBalance.clientBooking.total;
    setFormdata({ ...formdata, amount: total });
  };

  return (
    <div className="withdraw">
      <div className="withdraw-select_payment">{t("Select_payment")}</div>
      <div className="dark-container d-flex flex-column">
        {Object.entries(availableBalance).map(([key, value], i) => (
          <Balances key={i} i={i} value={value} />
        ))}
        <div className="gra-brd align-self-end"></div>
        <div className="d-flex align-items-baseline justify-content-between with-title">
          <div className="total">Total Available</div>
          <div>
            {availableBalance.clientUnused.available -
              availableBalance.clientBooking.total}
            {currency_symbols[SelectedCard?.CurrencyId] || "€"}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-2 mt-4">
        <div className="withdraw-fontSize_12">{t("Amount")} (eur)</div>
        <div className="d-flex align-items-center justify-content-between">
          <input
            className="amount-input with-input-w-sm withdraw-fontSize_12"
            placeholder={t("Amount-placeholder")}
            type="number"
            data-e2e="amount"
            value={formdata.amount}
            onChange={(e) => {
              setErrMsg("");
              setFormdata({ ...formdata, amount: e.target.value });
            }}
          />
          <div
            className="amount-btn withdraw-fontSize_12"
            id="register-btn-clr"
            onClick={handleAmount}
            data-e2e="all-amount"
          >
            All Amount
          </div>
        </div>
        {errMsg !== "" && <div className="error-message" data-e2e={errMsg}>{errMsg}</div>}

        <div className="withdraw-fontSize_12">{t("WalletNumber")}</div>
        <input
        data-e2e="wallet-number"
          className="amount-input with-input-w withdraw-fontSize_12"
          placeholder={t("WalletNumber")}
          value={formdata.wallet_no}
          onChange={(e) =>
            setFormdata({ ...formdata, wallet_no: e.target.value })
          }
        />
        <div className="withdraw-fontSize_12">{t("AccountType")}</div>
        <div className="pb-4">
          <Select
          data-e2e={t("AccountType")}
            className="amount-input with-input-w withdraw-fontSize_12"
            defaultValue="select"
            value={formdata.account_type}
            onChange={(value) =>
              setFormdata({ ...formdata, account_type: value })
            }
          >
            <Option value="select" data-e2e="select-account-type">Select Account Type</Option>
            <Option value="USDT" data-e2e="usdt">USDT</Option>
            <Option value="USDC" data-e2e="usdc">USDC</Option>
          </Select>
        </div>
        <div className="amount-btn w-100" id="register-btn-clr" onClick={() => HandleSubmit()} data-e2e={t("Confirm")}>
          {t("Confirm")}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  withdraw,
})(Withdraw);
