export const links = [
  "General Definitions",
  "Opening Your Account",
  "Menagement of the oceanbet account",
  "Chargeback",
  "Closure of an oceanbet account",
  "Payment rules",
  "Limitation of liability",
  "Collusion, cheating, fraud and criminal acrivity",
  "Closure of your account: termination of the terms of use closure and termination by you",
  "Access to, and use of, the services",
  "Alteration of the website",
  "Third party software",
  "IT failure",
  "Exclusion of our liability",
  "Breach of the terms of use",
  "Intellectual property rights",
  "Viruses, hacking and other offences",
  "Your personal information",
  "Use of 'cookies' on the website",
  "Complaints and notices",
  "Transfer of rights and obligations",
  "Events outside of our control",
  "Waiver",
  "Severability",
  "Entire agreement",
  "Third party rights",
  "Law and jurisdiction",
  "Links",
  "Policy of site use",
  "Legal aspects",
  "Restrictions on rates",
  "Privacy of user's data",
  "Currency",
  "The bonus program",
  "Deopsits",
  "Entry of money on account",
  "Payments",
  "Your obligations as a player",
  "Miscarried and aborted games",
  "Sportsbook rules",
];

export const responsibleGamesData = [
  {
    title: "Responsible Gaming",
    expl: [
      "R.BostockEnts across all brands it controls will maintain a constant commitment to promoting responsible gaming. Games provided are for the sole purpose of entertainment and take our responsibility to prevent any players showing signs of problem gambling from playing on our platforms. Our support staff are here to help with advice on what you can do if you believe gambling is becoming a problem. and are trained to spot behaviours which may be indicative of problem gaming",
      "If any of the following apply to you, please reach out to our customer support immediately",

      "Do you have a hard time focussing outside of your gambling?",

      " Do you forfeit completing daily tasks or attending social events due to gambling?",

      "Do you spend the majority of your day gambling?",

      "Do you feel you have to gamble more if you are losing?",

      "If any of these apply to you please consider taking a break and deciding if online gaming is the right leisure activity for you?",

      "We will take great measures to ensure players under the age of 18 are not able to access our products.",
    ],
  },
];

export const selfExculsion = [
  {
    title: "Self-Exclusion",
    expl: [
      "If you are concerned about your gambling or feel as if you need a break, please use our self exclusion option, you can decide the time you are excluded for and once set this will not be un-done in any circumstance. If you need any advice or help about how best to use this feature then please speak to our support staff.",
    ],
  },
];

export const disputeResolution = [
  {
    title: "Dispute Resolution",
    expl: [
      "In the event of any dispute, our support staff will always aim to amicably settle the dispute first and foremost, if you feel as though your dispute was not settled in a fair or satisfactory way, then you must contact the regulator within 6 months, please give clear and unequivocal details of the complaint to ensure it’s dealt with as speedily as possible.",
    ],
  },
];

export const amlPolicy = [
  {
    title: "AML Policy",
    expl: [
      "R.BostockEnts must protect the integrity of it’s gaming activities and aims to keep its website free from cheating, crime & money laundering activity, which will only serve to damage R.BostockEnts reputation and potentially damage the playing experience of our customers.",
      "The finance team reserve the right to question & request supporting documentation to ascertain the source of any funds deposited into the site. In the event of further documentation being required a temporary account block will be applied and in the event of failure to satisfy the investigation monies will be returned via the method they were deposited. We will always do our utmost to ensure that these investigations are performed as speedily as possible to minimise and interruption to our customer experience, and ask for your understanding that these policies are here to protect both R.BostockEnts and our customers from any negative experience.",
    ],
  },
];

export const fairnessAndTesting = [
  {
    title: "Fairness & RNG Testing",
    expl: [
      "R.Bostock Ents.BV will work with approved testing houses to conduct external compliance testing to verify elements such as functionality, display, transaction, rules and mathematical efficiency (i.e the “RTP” (Return to player) calculation) to ensure the overall compliance of our products to applicable regulatory requirements. All house games will be subject to these tests. Additionally, we commit to internal testing including Integration testing and user acceptance testing, thus guaranteeing all games integrated into our platform returns the expected user experience.",

      "The theoretical Return to Player “RTP” calculation is a theoretical calculation of the expected % of wagers that a specific game will return to player after an amount of plays (e.g 100,000,000 game plays.) While every single game play will be truly unpredictable and it will be possible to see all viable outcomes on a specific bet, the average return of a specific game in the long run will approach the theoretical RTP value. The RTP value is calculated via either a theoretical or simulated approach depending on the game type.",
    ],
  },
];

export const privacyPolicy = [
  {
    title: "KYC Policy, Privacy and Personal Data Management",
    expl: [
      "We are committed above all else to protecting and respecting your privacy and maintaining the trust of our customers.",

      "Upon opening an account we will be obligated to verify your full, address and age to ensure you are eligible to play on our platform, we will ask for I.D and address proof. We may also ask to provide proof that the payment method you use is attributed to you soley.",

      "RBostockEnts will collect this information with the sole purpose of fulfilling it’s legal and ethical obligation to protect it’s customers, we will never share your information with any third party, nor will it be used in any other capacity within our business except to comply with the KYC policies outlined.",

      "You will be able to contact our live support, either via the live chat or through our e-mail at any time. ",
    ],
  },
];
export const terms = [
  {
    title: "General Definitions.",
    expl: [
      "General Terms and Conditions (GTC) is a regulating Contract between Account Holder (You) and the Company (Oceanbet). All the Account Holders registered on www.oceanbet.io regulate their relationship with the company per the accepted GTC.",
      "In order to become an Account Holder you should first register on www.oceanbet.io",
      "An “Oceanbet Account” is an account held by an Account Holder, for bona fide transactions, with a strict aim to establish a normal commercial relationship with Oceanbet and with the strict purpose of conducting betting and other gaming and gambling transactions.",
      "The “Website” is the internet gateway accessible through the internet address www.oceanbet.io  is published, and through which the Services are provided to the Account Holders.",
      "Rules for Bonus/Promotions are described in the promotions part of the website, where you can see the conditions for each bonus and/or promotion.",
      "All the provided services should be wagered in accordance with the rules and the set limits.",
    ],
  },
  {
    title: "Opening Your Account",
    expl: [
      "In order to play a game using the Services, you will need to open an account with the Operator (“Oceanbet Account” or “Account”).",
      "Your Account will either be operated by the Operator, or by another company in its Group for and on behalf of itself and/or the relevant Operator Group company with whom you have contracted.",
      "When you open your Account you will be asked to provide us with personal information, including your name, date of birth and appropriate contact details, including an address, telephone number and e-mail address (“Your Contact Details”). You may update Your Contact Details from time to time by contacting Customer Services; or through the My Account management page on the Website: or by such other method as shall, from time to time, be offered by the Operator.",
      "In opening Your Account You warrant that:",
      "You understand and accept the risk that, by using the Services, you may, as well as win money, lose money;",
      "You are: (a) over 18 years of age: and (b) above the age at which gambling or gaming activities are legal under the law or jurisdiction that applies to You (the “Relevant Age”);",
      "Gambling is not illegal in the territory where You reside;",
      "You are legally able to enter into contracts;",
      "You have not been excluded from gambling; and",
      "You have not already had an Account closed by us under the following reasons Collusion, Cheating, Fraud and Criminal Activity, Breach of the Terms of Use or at Your request under paragraph Responsible Gaming/Gambling.",
      "Your Account must be registered in Your own, correct, name and personal details. It shall only be issued once for You and not duplicated through any other person, family, household, address (postal or IP), email address, Access Device or any environment where Access Devices are shared (e.g. schools, workplaces, public libraries etc), computer (or other access device), in respect of the Services. Any other accounts which You open with us, or which are beneficially owned by You in relation to the Services shall be “Duplicate Accounts”. We may close any Duplicate Account (but shall not be obliged to do so).",
      "If we close a Duplicate Account:",
      "All bonuses, free bets and winnings accrued from such bonuses and free bets obtained using that Duplicate Account will be void and forfeited by You;",
      "We may, at our entire discretion, void all winnings and refund all deposits (less amounts in respect of void winnings) made in respect of that Duplicate Account and, to the extent not recovered by us from the relevant Duplicate Account, any amounts to be refunded to us by You in respect of a Duplicate Account may be recovered by us directly from any other of Your Accounts (including any other Duplicate Account); or",
      "We may, at our entire discretion, allow usage of the Duplicate Account to be deemed valid in which case all losses and stakes placed by or for You through the Duplicate Account shall be retained by us.",
    ],
  },
  {
    title: "MANAGEMENT OF THE OCEANBET ACCOUNT",
    expl: [
      "1.      Oceanbet reserves the right at its own discretion and always, to:",
      "1. - a) Decline to open a Oceanbet Account and/or to close an existing OCeanbet Account without any explanation whatsoever;",
      "2. - b) Decline to accept deposits without any explanation whatsoever;",
      "3. - c) Request documents to verify: (i) the identity of the Account Holder, (ii) his/her authorization to use a specific Card and/or (iii) other facts and information provided by the Account Holder. Such request may be made at any given moment and oceanbet reserves the right to suspend an account pending investigation;",
      "4. - d) Transfer and/or license, without prior notice, data regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises.BV, subject to Oceanbet guaranteeing that the said data always is transferred and managed in accordance with applicable laws, data protection acts and/or similar;",
      "5. - e) Transfer and/or license, without prior notice, the rights and liabilities regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises B.V, subject to Oceanbet guaranteeing the liabilities being honoured;",
      "6. - f) Hold and manage funds belonging to Account Holders in accordance with generally accepted guidelines for cash management regarding such funds; this may include a Financial Institution and/or a Payment Solution Provider being entrusted to hold funds in the name of and/or for the benefit of Account Holders;",
      "7. - g) Forfeit and/or confiscate funds available on a Oceanbet Account and/or refuse to honor a claim, in the event that, directly or indirectly: (i) the Oceanbet Rules have been violated; and/or (ii) other unauthorised activities have occurred in connection with a betting event and/or the operation of a Oceanbet Account (such as, but not limited to, breach of the law or other regulations, breach of a third party’s rights, fraud, and cheating);",
      "8. - h) Suspend and/or cancel the participation of an Account Holder in the games, promotional activities, competitions or other services, whenever Oceanbet believes there are legitimate concerns that a Oceanbet Account is, has been, or may be used for illegal, fraudulent or dishonest practices;",
      "9. - i) Suspend and/or cancel the participation of the Account Holder in the Services, and/or forfeit and/or confiscate funds available on their Oceanbet Account if the Account Holder is found cheating, or if it is determined by Oceanbet that the Account Holder has employed or made use of a system (including machines, robots, computers, software or any other automated system) designed to defeat or capable of defeating the Client Application and/or Software.",
      "2.     Oceanbet is committed to detect and prevent software programs which are designed to enable artificial intelligence (“AI Software”) to play on its website(s) including but not limited to opponent-profiling, player collusion; robots, other ‘cheating’ software or anything else that in our reasonable opinion distorts normal game play and enables the player to have an unfair advantage over other players. You acknowledge that Oceanbet will take measures to detect and prevent the use of such programs and AI Software using methods (including but not limited to reading the list of currently running programs on a player’s computer) and the customer agrees not to use any AI Software and/or any such programs.",
      "3.     Oceanbet warrants at all times to:",
      "1. - a) Manage funds belonging to Account Holders in a secure and appropriate manner; and/or",
      "2. - b) Absorb the cost of and pay the Gaming and Betting Duty, as applicable, at the Place of the Contract;",
      "3. - c) Manage data regarding an Account Holder in accordance with applicable laws, data protection acts and/or similar;",
      "4. - d) Not offer contingencies to customers to proceed to any fund transfer between customers’ accounts.",
      "4.     Oceanbet shall keep Account Holders’ funds separate from Oceanbet’s own funds in a client account held with a Financial Institution approved by the Regulator.",
      "5.      Oceanbet Account does not accrue interest. The Account Holder shall not treat Oceanbet as a financial institution.",
      "6.     An Account Holder can only hold one Oceanbet Account at a time. In the event that this rule is breached, Oceanbet reserves the right to block and/or delete the superfluous Oceanbet Account(s) held by the Account Holder in breach of this clause, and reallocate all the funds to a single Oceanbet Account. No bonus given to the superfluous Oceanbet Account(s) will be reallocated.",
      "7.     A Oceanbet Account is non-transferable. It is prohibited for players to sell, transfer or acquire accounts from or to other players. Funds cannot be transferred between Oceanbet accounts.",
      "8.     An Account Holder shall not allow any other individual, including any minor, to use or reuse its Oceanbet Account, access and/or use any material or information from the Website, accept any Prize, or access and/or participate in the Services.",
    ],
  },
];

export const accountPayoutBonus = [
  {
    expl: [
      "1.     In order to play a game using the Services, you will need to open an account with the Operator (“Oceanbet Account” or “Account”).",

      "2.     Your Account will either be operated by the Operator, or by another company in its Group for and on behalf of itself and/or the relevant Operator Group company with whom you have contracted.",

      "3.     When you open your Account you will be asked to provide us with personal information, including your name, date of birth and appropriate contact details, including an address, telephone number and e-mail address (“Your Contact Details”). You may update Your Contact Details from time to time by contacting Customer Services; or through the My Account management page on the Website: or by such other method as shall, from time to time, be offered by the Operator.",

      "4.     In opening Your Account You warrant that",

      "5.     You understand and accept the risk that, by using the Services, you may, as well as win money, lose money",

      "6.     You are: (a) over 18 years of age: and (b) above the age at which gambling or gaming activities are legal under the law or jurisdiction that applies to You (the “Relevant Age”)",

      "7.     Gambling is not illegal in the territory where You reside",

      "8.     You are legally able to enter into contracts",

      "9.     You have not been excluded from gambling; and",

      "10.   You have not already had an Account closed by us under the following reasons Collusion, Cheating, Fraud and Criminal Activity, Breach of the Terms of Use or at Your request under paragraph Responsible Gaming/Gambling.",

      "11.   Your Account must be registered in Your own, correct, name and personal details. It shall only be issued once for You and not duplicated through any other person, family, household, address (postal or IP), email address, Access Device or any environment where Access Devices are shared (e.g. schools, workplaces, public libraries etc), computer (or other access device), in respect of the Services. Any other accounts which You open with us, or which are beneficially owned by You in relation to the Services shall be “Duplicate Accounts”. We may close any Duplicate Account (but shall not be obliged to do so).",
      "12.   If we close a Duplicate Account",

      "13.   All bonuses, free bets and winnings accrued from such bonuses and free bets obtained using that Duplicate Account will be void and forfeited by You",

      "14.   We may, at our entire discretion, void all winnings and refund all deposits (less amounts in respect of void winnings) made in respect of that Duplicate Account and, to the extent not recovered by us from the relevant Duplicate Account, any amounts to be refunded to us by You in respect of a Duplicate Account may be recovered by us directly from any other of Your Accounts (including any other Duplicate Account); or ",

      "15.    We may, at our entire discretion, allow usage of the Duplicate Account to be deemed valid in which case all losses and stakes placed by or for You through the Duplicate Account shall be retained by us.",
    ],
  },
  {
    title: "MANAGEMENT OF THE OCEANBET ACCOUNT",
    expl: [
      " 1.     Oceanbet reserves the right at its own discretion and always, to:",
      "2.     b) Decline to accept deposits without any explanation whatsoever;",
      "3.     c) Request documents to verify: (i) the identity of the Account Holder, (ii) his/her authorization to use a specific Card and/or (iii) other facts and information provided by the Account Holder. Such request may be made at any given moment and oceanbet reserves the right to suspend an account pending investigation;",
      "4.     d) Transfer and/or license, without prior notice, data regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises.BV, subject to Oceanbet guaranteeing that the said data always is transferred and managed in accordance with applicable laws, data protection acts and/or similar;",
      "5.     e) Transfer and/or license, without prior notice, the rights and liabilities regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises B.V, subject to Oceanbet guaranteeing the liabilities being honoured;",
      "6.     f) Hold and manage funds belonging to Account Holders in accordance with generally accepted guidelines for cash management regarding such funds; this may include a Financial Institution and/or a Payment Solution Provider being entrusted to hold funds in the name of and/or for the benefit of Account Holders;",
      "7.     g) Forfeit and/or confiscate funds available on a Oceanbet Account and/or refuse to honor a claim, in the event that, directly or indirectly: (i) the Oceanbet Rules have been violated; and/or (ii) other unauthorised activities have occurred in connection with a betting event and/or the operation of a Oceanbet Account (such as, but not limited to, breach of the law or other regulations, breach of a third party’s rights, fraud, and cheating);",
      "8.     h) Suspend and/or cancel the participation of an Account Holder in the games, promotional activities, competitions or other services, whenever Oceanbet believes there are legitimate concerns that a Oceanbet Account is, has been, or may be used for illegal, fraudulent or dishonest practices;",
      "9.     i) Suspend and/or cancel the participation of the Account Holder in the Services, and/or forfeit and/or confiscate funds available on their Oceanbet Account if the Account Holder is found cheating, or if it is determined by Oceanbet that the Account Holder has employed or made use of a system (including machines, robots, computers, software or any other automated system) designed to defeat or capable of defeating the Client Application and/or Software.",
      "2.     Oceanbet is committed to detect and prevent software programs which are designed to enable artificial intelligence (“AI Software”) to play on its website(s) including but not limited to opponent-profiling, player collusion; robots, other ‘cheating’ software or anything else that in our reasonable opinion distorts normal game play and enables the player to have an unfair advantage over other players. You acknowledge that Oceanbet will take measures to detect and prevent the use of such programs and AI Software using methods (including but not limited to reading the list of currently running programs on a player’s computer) and the customer agrees not to use any AI Software and/or any such programs.",
      "3.     Oceanbet warrants at all times to:",
      "1.     a) Manage funds belonging to Account Holders in a secure and appropriate manner; and/or",
      "2.     b) Absorb the cost of and pay the Gaming and Betting Duty, as applicable, at the Place of the Contract;",
      "3.     c) Manage data regarding an Account Holder in accordance with applicable laws, data protection acts and/or similar;",
      "4.     d) Not offer contingencies to customers to proceed to any fund transfer between customers’ accounts.",
      "4.     Oceanbet shall keep Account Holders’ funds separate from Oceanbet’s own funds in a client account held with a Financial Institution approved by the Regulator.",
      "5.      Oceanbet Account does not accrue interest. The Account Holder shall not treat Oceanbet as a financial institution.",
      "6.     An Account Holder can only hold one Oceanbet Account at a time. In the event that this rule is breached, Oceanbet reserves the right to block and/or delete the superfluous Oceanbet Account(s) held by the Account Holder in breach of this clause, and reallocate all the funds to a single Oceanbet Account. No bonus given to the superfluous Oceanbet Account(s) will be reallocated.",
      "7.     A Oceanbet Account is non-transferable. It is prohibited for players to sell, transfer or acquire accounts from or to other players. Funds cannot be transferred between Oceanbet accounts.",
      "8.     An Account Holder shall not allow any other individual, including any minor, to use or reuse its Oceanbet Account, access and/or use any material or information from the Website, accept any Prize, or access and/or participate in the Services.",
      "How do I verify my account?",
      "Before you can make a withdrawal, you have to verify your account. To do so you need to send an e-mail to us, providing us with the following documents:",
      "Proof of identity: A photo copy of your ID (national ID, driver’s license or passport)",
      "Proof of address: A copy of an official letter (EX. government or bank issued letter)",
      "If you use a credit card, we also need:",
      "A photo copy of the front and back of your credit card (only the first 6 and last 4 numbers of the card number needs to be visible).",
      "How do I know if my documents are approved?",
      "After you’ve supplied us with the documents it’ll take up to 12 hours before you’ll receive a confirmation of the documents either being approved or what further information are required.",
      "Why do I need to give our personal information to register an account at Oceanbet?",
      "For us at Oceanbet to provide you with the best service and highest possible security we need to be able to identify our members, especially before any winning amounts can be paid out. For that reason we need members full name and home address. To verify that players are of legal age to gamble online we also need birth date. Access to your email adders ensures that we can keep you informed of any important news, promotions or information needed if you’ve forgot your password. You can rest assure that your information is kept safe and no third party will have access to it.",
    ],
  },
  {
    title: "CHARGEBACK",
    expl: [
      "1.     Subject to the sub-clauses below and without prejudice to Oceanbet’s right to seek redress under any applicable legislation, regulation, enactment or policy, or under any other provision of the Oceanbet Rules, Oceanbet shall have the right to block a Oceanbet Account when a chargeback has been requested in relation to that Oceanbet Account.",
      "2.     When a chargeback has been requested, Oceanbet will send a “Chargeback Notice” to the Account Holder at the email address mentioned in the Account Holder’s details, in order to seek confirmation of the Account Holder’s identity and of the payment method used to credit to the Account Holder’s Oceanbet Account any funds entirely unrelated to a chargeback (“Untainted Funds”).",
      "3.     In the absence of confirmation by the Account Holder of the Account Holder’s identity and of the payment method used to credit Untainted Funds to the Account Holder’s Oceanbet Account, following a Chargeback Notice, Oceanbet will send two written reminders to the Account Holder at the email available to it, each of which will be subject to a processing fee of fifty (50) EURO drawn on any Untainted Funds.",
      "4.     Where an Oceanbet Account has been blocked due to a chargeback and the Account Holder has not:",
      "1.     a) logged in to the Oceanbet Account for a period of thirty (30) consecutive months; or",
      "2.     b) confirmed to Oceanbet his identity and the details of the payment method used to credit Untainted Funds to the Account Holder’s Oceanbet Account and then requested a withdrawal; any Untainted Funds on the Oceanbet Account will be treated as though they were funds on an Inactive Account and Oceanbet will remit the balance on the Oceanbet Account of the Account Holder.",
    ],
  },
  {
    title: "CLOSURE OF AN OCEANBET ACCOUNT",
    expl: [
      "1.     An Account Holder may close their Oceanbet account at any time by contacting Oceanbet’s Customer Support using the contact details provided in the “Get Help” section on the Website (www.oceanbetbet.com/help), either by phone or email. Any funds in the Oceanbetbet Account will be remitted to the Account Holder.",
      "2.     Should an existing Oceanbet Account be closed, any obligations already entered into will be honoured.",
      "3.     Account Holders who wish to recover funds held in a closed, blocked or excluded account are advised to contact Customer Support.",
      "4.     In case of closure of their Oceanbet Account due to gambling addiction or fraud, an individual must not open a new Oceanbet Account. Oceanbet will not be liable should the individual succeed in opening a new account, nor for any direct or indirect consequential damages. Oceanbet reserves the right to close an account opened in breach of this rule at any point. ",
    ],
  },
  {
    title: "PAYMENT RULES",
    expl: [
      "1.    Deposits to and withdrawals from a Oceanbet Account shall at all times be made through a Financial Institution or a Payment Solution Provider. Procedures, terms and conditions, availability, and duration for deposits/withdrawals may vary depending on time as well as the country and Financial Institution in question. Current advice is available when logged in on the Website under the headings “Deposit” or “Withdrawal”.",
      "2.    Oceanbet may appoint a Payment Solution Provider to act, receive deposits, hold and manage funds, and/or expedite withdrawals, on behalf of Oceanbet.",
      "3.    Oceanbet does not accept cash funds sent or delivered directly to Oceanbet or a Payment Solution Provider.",
      "4.    Oceanbet will credit to the Oceanbet Account all funds received by Oceansbet from or on behalf of the Account Holder, or owed by Oceanbet to the Account Holder.",
      "5.    Method of payment/withdrawal from/to Oceanbet Account",
      "6.    An Account Holder is only allowed to:",
      "a) Make deposits to his Oceanbet Account with his personal Credit Card or via his personal account created with one of the Financial Institutions or their licensees;",
      "   b) Once per day, request withdrawals of funds held on his Oceanbet Account to his/her personal Credit Card or his/her personal account created with one of the Financial Institutions or their licensees.",
      "7.     An Account Holder is responsible for providing Oceanbet with the correct details of his/her personal account for the purpose of withdrawals from his/her Oceanbet Account.",
      "8.     An Account Holder must not allow third parties to use his/her Oceanbet Account to make deposits to or withdrawals from his/her Oceanbet Account.",
      "9.      It is the Account Holder’s sole responsibility to ensure that he/she complies with the above provisions. ",
      "10.   Oceanbet shall not accept a wager from an Account Holder unless a Oceanbet Account has been established in the name of the Account Holder and there are adequate funds in the Oceanbet Account to cover the amount of the wager, or funds necessary to cover the amount of the wager are provided in an approved manner.",
      "11.   Oceanbet shall not deal with the credit balance of a Oceanbet Account except:",
      "1.     a) to debit from the Oceanbet Account a wager made by the Account Holder or an amount the Account Holder indicates they want to wager in the course of a game they are playing or about to play;",
      "2.     b) to remit funds standing to the credit of the Oceanbet Account to the Account Holder, at the Account Holder’s request, in terms of regulation 37 of the Remote Gaming Regulations;",
      "3.     c) to pay reasonable bank charges for deposits received and funds withdrawn; or",
      "4.     d) as otherwise authorised by the Remote Gaming/Gambling Regulations.",
      "12.   The balance of a Oceanbet Account may turn negative in case of chargeback.",
      "13.   Withdrawals from a Oceanbet Account are made through payments addressed to the Account Holder or transferred to a bank account held in the name of the Account Holder, as advised by the Account Holder. Whenever possible, Oceanbet will restrict withdrawals to be made only to the same account utilised by the Account Holder to make deposits.",
      "14.   When an Account Holder wishes to withdraw funds they have deposited into their Oceanbet Account but have not wagered in any game or sport betting offer, Oceanbet reserves the right to refuse to transfer the funds to another bank account than the one the funds originated if Oceanbet suspects fraud and/or money laundering.",
      "15.   Depending on the payment method chosen by the Account Holder, minimum and/or maximum deposit limits may apply. The withdrawals will be remitted only to the same account from which the funds originated.",
      "16.   Oceanbet reserves the right to charge the Account Holder for administrative costs resulting from withdrawals made by the Account Holder, as indicated on the Website.",
      "17.    Placing a bet through the Internet may be illegal in the jurisdiction in which an Account Holder is resident and/or domiciled; if so, the Account Holder is not authorised to use a Card for the purpose of placing a bet",
      "18.   The participation of an Account Holder in the Services in a jurisdiction where such participation is prohibited by law shall not affect any stakes or payment made to and accrued for the benefit of Oceanbet.",
      "19.   Oceanbet, or Governing Authority can monitor or request to review all transactions to prevent money laundering. All suspicious transactions detected by Oceanbet will be reported to the Governing Authorities",
      "20.   All transactions are checked to prevent money laundering.",
      "21.   It is the sole responsibility of the Account Holder to pay and proceed with all necessary diligence in relation to taxes on any Prize, if and where applicable.",
      "22.   It is unlawful to deposit money from ill-gotten means",
      "23.   With initiative of Finance / Accounting Department, users may be redirect for different payment methods.",
      "24. Players must wager all their deposits once, on odds of 1.30 or above. The deposited amount cannot be withdrawn without completing the deposit wager within the specified limits. This rule applies to the amount transferred through the method Send to a friend .(Wagering is not required when sending to a friend, but wagering is required after transferring to a friend.) This rule exists to prevent fraud and money laundering.",
    ],
  },
];

export const frequentlyAskedQuestions = [
  {
    title: "What are your methods of payment?",
    expl: " The methods of payment we use are, Visa, Luxon pay, Cryptonpay and Now pay.",
  },
  {
    title: "How much can I deposit?",
    expl: "There is no deposit Limit for Oceanbet casino.",
  },
  {
    title: "How long does a Deposit take?",
    expl: "Your money is instantly deposited into your account, so your ready to play right away.",
  },
  {
    title: "How to withdraw?",
    expl: "A user chooses their payment option, enters the amount to withdraw, inputs their wallet number and clicks withdraw. Simple!",
  },
  {
    title: " How do I deposit?",
    expl: "Choose payment method, enter amount, enter wallet address then click deposit",
  },
];

export const contactSupport = [
  // { title: "Phone:", text: "+44 00 000 000" },
  { title: "Email:", text: "support@oceanbet.io" },
  // { title: "Address:", text: "casinobet street 98" },
];

export const ourFeatures = [
  {
    img: "search3Icon",
    title: "Guarantee",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "spadeTokenIcon",
    title: "Best Games",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "supportIcon",
    title: "Support",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "scheduleIcon",
    title: "Favorite Matches",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
];

export const aboutUsTitle = ["About us"];
export const aboutUsSpan = ["Oceanbet.io"];
export const aboutUsDescripton = [
  "offers a range of products to it's visitors to play and enjoy their spent time here . Your favorite games and matches. Here you can find them all. Due to our professional and experienced specialists we assure you that you will find convenience and comfort while playing and enjoying Your time. Also our 24/7 support team is always ready to help you.",
];
export const aboutUsBtn = ["Play now"];
export const aboutUsContent = [
  "We are OceanBet, and we are absolutely delighted to get to know you. In fact, that is what we are all about – a customised casino experience that is really all about you!",

  "For this quest we’ve put together a team of experts specialised in the fields of iGaming, e-commerce, data mining and specialised user interfaces to bring a unique personalised casino experience to each invited player.",

`You are different and we feel that this is the casino experience you deserve! OceanBet offers the hottest online casino games from popular brands you’ve tried and trusted – games that you love and want to play; games that are relevant to you… because this isn’t just any other casino - this is Your Casino!
OceanBet offers a range of products to its visitors to play and enjoy their spent time here. Your favourite games and matches. Here you can find them all. Due to our professional and experienced specialists we assure you that you will find convenience and comfort while playing and enjoying Your time. Also, our VIP Team are available by direct message and email.`,

  "Responsible Gaming & Underage Gambling",
  "We see online gambling as a legitimate form of entertainment that is enjoyed by millions of adults worldwide. We advertise and operate responsibly to protect minors, and we assist those that may have problems to help identify their issues.",

  "We encourage you to PLAY RESPONSIBLY at OceanBet. If you find yourself with a compulsive gambling problem, please seek professional help. You can find your local branch of Gamblers-Anonymous at: www.gamblersanonymous.org",

  "Safe, secure, and private",

  `We use 128-bit encryption, provided by Thawte Security, to ensure the security and privacy of your data. We keep all of your information confidential, and we will never share it or sell it to third parties, except in accordance with our privacy policy. 
  Payment processing`,

  `We offer a variety of easy deposit options and guarantee fast cash outs. All payment processing transactions are transmitted by, and stored in, our secure, proprietary cashier system. 
Access to OceanBet
By accessing, continuing to use, or navigating throughout this site you accept that we will utilise certain browser cookies to improve the experience which you receive with us. OceanBet do not use any cookies which interfere with your privacy, but only ones which will improve your experience whilst using our site.`,
];
