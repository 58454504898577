import { Tooltip } from "antd";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Images } from "../../../../Images";

const Nav = ({
  navEl,
  url,
  setUrl,
  selectedCurrency,
  navMobile,
  setNavMobile,
}) => {
  const { info } = Images;
  let ClientBalance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data
  );

  let unused = ClientBalance?.Balances
    ? ClientBalance?.Balances[0]?.Balance
    : 0;
  let used = ClientBalance?.Balances ? ClientBalance?.Balances[1]?.Balance : 0;
  let clientBooking = 0;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between nav nav-main-container">
        <div className={`d-flex align-items-center nav-navEl ${navMobile ? "nav-El-respnose"  : ""}`}>
          {/* <img
                className={`${
                  navMobile ? "none500" : "downArrow-nav-mobile rotate-90"
                } downArrow-nav-mobile`}
                src={Images.downArrowGray}
                alt="downArrow"
                onClick={() => {
                  setUrl("Settings");
                  setNavMobile(true);
                }}
              /> */}
          {navEl.map((el, i) => (
            <div data-e2e={t(el)}
              key={i}
              onClick={() => {
                setUrl(el);
              }}
              className={`${url === el ? "" : "text-gray"} pointer ${
                navMobile && el === "Responsible Gaming" ? "none500" : ""
              } ${!navMobile && el !== "Responsible Gaming" ? "none500" : ""}`}
            >
              {t(el)}
              <div
                className={`${url === el ? "title-border-nav" : "text-gray"}`}
              ></div>
            </div>
          ))}
          {/* <img
                className={`${
                  !navMobile ? "down-arrow none500" : "downArrow-nav-mobile rotate90"
                }`}
                src={Images.downArrowGray}
                alt="downArrow"
                onClick={() => {
                  setNavMobile(false);
                  setUrl("Responsible Gaming");
                }}
              /> */}
        </div>
        <div className="d-flex align-items-center gap-2 info-accounts-img account-info-mobile-view">
          <Tooltip
            placement="bottomRight"
            title={
              <div className="info-tooltip-text">
                <div>
                  Client Unused Balance: {unused} {selectedCurrency}
                </div>
                <hr className="info-tooltip" />
                <div>
                  Client Used Balance: {used} {selectedCurrency}
                </div>
                <hr className="info-tooltip" />
                <div>
                  Clinet Booking: {clientBooking} {selectedCurrency}
                </div>
              </div>
            }
          >
            <img src={info} alt="info" className="info-accounts" data-e2e="accounts-info" />
          </Tooltip>
          <div className="info-accounts">Accounts</div>
        </div>
      </div>
    </>
  );
};

export default Nav;
