import { Images } from "../../../../../Images";
import { contactSupport } from "../../../lib/constants";
import "./ContactSocial.css";

const ContactSocial = () => {
  const {
    blackPhoneIcon,
    blackEmailIcon,
    blackAddressIcon,
  } = Images;

  return (
    <div className="row mx-0">
      <div className="col-12 col-md-6 col-lg-12 contact-sup contact-box">
        <div className="contact-sup-title">Oceanbet casino</div>
        <div className="blackIcon">
          {contactSupport.map((cont, i) => {
            return (
              <div
                key={i}
                className="d-flex align-items-center"
              >
                <div className="blackIcon-container d-flex align-items-center gap-2">
                  <img
                    className="blackIcon-img"
                    src={
                      cont.title === "Phone:"
                        ? blackPhoneIcon
                        : cont.title === "Email:"
                        ? blackEmailIcon
                        : blackAddressIcon
                    }
                    alt="blackIcons"
                  />
                  <div className="cont-title">{cont.title}</div>
                </div>
                <div className="cont-text text-start w-50">{cont.text}</div>
              </div>
            );
          })}
          {/* <div className="help-page ">
            <div className="d-flex align-items-center gap-1">
              <p className="mb-0"> </p>
              <span className="help-a"></span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactSocial;
