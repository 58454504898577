import { BonusActions } from "../types";

const initialState = {
  bonusStatusList: [],
  bonuses: []
};

const BonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case BonusActions.GET_BONUS_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        bonusStatusList: action?.payload?.data,
      };
    }
    case BonusActions.GET_BONUS__STATUS_LIST_ERROR: {
      return {
        ...state,
        bonusStatusList: action.payload,
      };
    }
    case BonusActions.GET_BONUSES_SUCCESS: {
      return {
        ...state,
        bonuses: action?.payload?.data,
      };
    }
    case BonusActions.GET_BONUSES_ERROR: {
      return {
        ...state,
        bonuses: action.payload,
      };
    }
    default:
      return state;
  }
};

export default BonusReducer;
