import { Alert, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Images } from "../../../Images/index";
import { checkLoginStatus } from "../../../modules/Games/actions";
import { rsaEncryption } from "../../../utils/encrytion.js";
import { forgotPassword, login, loginModal } from "../../Login/actions";
import "./Login.css";

const Login = (props) => {
  const {
    Description,
    ResponseCode,
    loginModal,
    checkLoginStatus,
    loginStatus,
    setregisterModal,
    forgotPassword,
    recoveryData,
  } = props;
  const [form] = Form.useForm();
  const [isLoggedInProcess, setIsLoggedInProcess] = useState(false);
  const publicKey =
    useSelector((state) => state?.config?.REACT_APP_LOGIN_API_PUBLIC_KEY) || "";
  const modal = useSelector((state) => state?.loginReducer?.modal);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [recoveryStatus, setRecoveryStatus] = useState(false);

  const onFinish = async (values) => {
    const { login } = props;
    if (values) {
      const encryptedData = rsaEncryption(values, publicKey);
      await login(encryptedData);
      await checkLoginStatus(false);
    }
  };

  const handleRecoveryPassword = async () => {
    setRecoveryStatus(true)
    if (!emailOrMobile.length || emailOrMobile === "")
      return;
    await forgotPassword({
      EmailOrMobile: emailOrMobile,
      LanguageId: "en",
      PartnerId: 43,
      TimeZone: 5.5,
    });
  };

  const onSubmitCapture = () => {
    setIsLoggedInProcess(true);
    setTimeout(() => {
      setIsLoggedInProcess(false);
    }, 3000);
  };
  const { t } = useTranslation();

  const handleClose = async () => {
    loginModal(false);
    setForgotPasswordModal(false);
    setRecoveryStatus(false);
    setEmailOrMobile("")
    await checkLoginStatus(false);
  };

  return (
    <Modal
      visible={loginStatus ? loginStatus : modal}
      footer={null}
      width="700px"
      centered
      className="login"
      onCancel={() => handleClose()}
    >
      {forgotPasswordModal ? (
        <div className="row m-0 custom-modal">
          <div className="col-6 col-md-6 p-0">
            <img src={Images.login} height="100%" alt="" className="login-bg" />
          </div>
          <div className="col-6 col-md-6 p-0 login-body" id="login-body">
            <div className="row mx-0 ">
              <div className="col-12 login-header">
                <img src={Images.SidebarLogo} width="100px" alt="" />
              </div>
              <div className="col-12">
                <div className="container pt-4">
                  {recoveryStatus && recoveryData?.ResponseCode === 0 ? (
                    <div className="col-12 mt-10 recovery-password-container">
                      <div className="recovery-outer-circle">
                        <div className="recover-inner-circle">
                          <img src={Images.recoveryIcon} alt="logo" width={25} height={25} />
                        </div>
                      </div>
                      Recovery code successfully sent to email/mobile:{" "}
                      <span className="me-5 recovery-email">
                        {emailOrMobile}
                      </span>
                      <div className="col-12 pb-3 forgot-password mt-20">
                        <Link to="/ocean-bet/support">Support</Link>
                      </div>
                    </div>
                  ) : (
                    <div className="row recovery-password-container">
                      <div className="col-12 password-recovery">
                        Password recovery
                      </div>
                      <div className="col-12 ant-input-recovery-wrapper">
                        <Input
                          placeholder="Email/Phone number"
                          autoFocus
                          name="EmailOrMobile"
                          value={emailOrMobile}
                          // autoComplete="off"
                          className={`${
                            themeMode === "light"
                              ? "white-content-bg"
                              : "login-body-input"
                          }`}
                          onChange={(e) => setEmailOrMobile(e.target.value)}
                        />
                      </div>
                      <div className="col-12 mt-10">
                        {(emailOrMobile === "" || !emailOrMobile.length) &&
                          recoveryStatus && (
                            <div className="recovery-error-text">
                              Please enter recovery email/mobile
                            </div>
                          )}
                      </div>
                      <div className="col-12 mt-10">
                        Number format: + country code(area code) (phone number).
                      </div>
                      <div className="col-12 mt-10">
                        Example: +7 212 533 39 09
                      </div>
                      <div className="col-12 mt-10">
                        We can help you reset your password using your mobile
                        number or the email address linked to your account.
                      </div>
                      <div className="col-12 pb-3 forgot-password mt-10">
                        <Link to="/ocean-bet/support">Support</Link>
                      </div>
                      {ResponseCode && ResponseCode !== 0 ? (
                        <Alert
                          message={Description || "Something Went wrong"}
                          type="error"
                          // closable
                          style={{ margin: "10px 0px" }}
                          showIcon
                        />
                      ) : null}
                      <div className="col-12 pb-2">
                        <button
                          className={`w-100 ${
                            isLoggedInProcess ? "cursor-not-allowed" : ""
                          }`}
                          onClick={() => {
                            handleRecoveryPassword();
                          }}
                          disabled={isLoggedInProcess}
                        >
                          <span>Send me recovery link</span>
                        </button>
                      </div>
                      <div className="col-12 mt-10 mb-10">
                        <h1>
                          {t("Already_have_an_account")}{" "}
                          <span
                            className="cursor-pointer login-footer-span-register"
                            onClick={() => setForgotPasswordModal(false)}
                          >
                            {t("Sign In")}
                          </span>
                        </h1>
                      </div>
                      <div className="col-12 forgot-password pb-2 mt-10">
                        <h1>
                          {t("Dont_have_an_account")}{" "}
                          <span
                            className="login-footer-span-register"
                            onClick={() => setregisterModal(true)}
                          >
                            {t("Sign Up")}
                          </span>
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Form
          name="login"
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onSubmitCapture={onSubmitCapture}
          // autoComplete="off"
        >
          <div className="row m-0 custom-modal">
            <div className="col-6 col-md-6 p-0">
              <img
                src={Images.login}
                height="100%"
                alt=""
                className="login-bg"
              />
            </div>
            <div className="col-6 col-md-6 p-0 login-body" id="login-body">
              <div className="row mx-0 ">
                <div className="col-12 login-header">
                  <img src={Images.SidebarLogo} width="100px" alt="" />
                </div>
                <div className="col-12">
                  <div className="container pt-4">
                    <div className="login-lable" data-e2e="login-button">Log in</div>
                    <div className="row">
                      <div className="col-12 ">
                        <Form.Item
                          id="login-input"
                          name="ClientIdentifier"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Username!",
                            },
                          ]}
                        >
                          <Input
                            data-e2e="user-name"
                            placeholder={t("Login1")}
                            autoFocus
                            // autoComplete="off"
                            className={`${
                              themeMode === "light"
                                ? "white-content-bg"
                                : "login-body-input"
                            }`}
                            prefix={<img src={Images.mail} alt="" />}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item
                          id="login-input"
                          name="Password"
                          autoComplete="off"
                          rules={[
                            {
                              required: true,
                              type: "password",
                              message: "Please enter your Password!",
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("Password")}
                            className={`${
                              themeMode === "light"
                                ? "white-content-bg"
                                : "login-body-input"
                            }`}
                            type="password"
                            prefix={<img src={Images.lock} alt="" />}
                            data-e2e="pass-word"
                          />
                        </Form.Item>
                      </div>
                      <div
                        className="col-12 pb-3 forgot-password forgot-password-text"
                        onClick={() => setForgotPasswordModal(true)}
                      >
                        {t("Forgot")}
                      </div>
                      {ResponseCode && ResponseCode !== 0 ? (
                        <Alert
                          message={Description || "Something Went wrong"}
                          type="error"
                          // closable
                          style={{ margin: "10px 0px" }}
                          showIcon
                        />
                      ) : null}
                      <div className="col-12 pb-2">
                        <button
                        data-e2e="log-in"
                          className={`w-100 ${
                            isLoggedInProcess ? "cursor-not-allowed" : ""
                          }`}
                          onClick={() => {}}
                          disabled={isLoggedInProcess}
                        >
                          <span>{t("Sign In")}</span>
                        </button>
                      </div>
                      <div className="col-12 pb-1 forgot-password">
                        <p>
                          By accessing the site, I confirm that I am 18 years
                          olf and I have read the{" "}
                          <Link
                            to="/ocean-bet/terms&conditions"
                            onClick={handleClose}
                          >
                            {t("Terms and Conditions")}
                          </Link>
                        </p>
                      </div>
                      <div className="col-12 forgot-password pb-2">
                        <h1>
                          {t("Dont_have_an_account")}{" "}
                          <span
                            className="login-footer-span-register"
                            onClick={() => setregisterModal(true)}
                          >
                            {t("Sign Up")}
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    loginStatus: state?.gamesReducer?.loginStatus,
    recoveryData: state?.loginReducer?.recoveryData,
  };
};

export default connect(mapStateToProps, {
  login,
  loginModal,
  checkLoginStatus,
  forgotPassword,
})(Login);
