import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Images } from "../../Images/index";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { loginModal } from "../Login/actions";
import "./Footer.css";

const Footer = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  const footerList = [
    {
      list: [
        { title: t("About us"), path: "/about-us" },
        { title: t("Contact Us"), path: "/support" },
        // { title: t("Help"), path: "/help" },
      ],
      title: t("OCEAN BET"),
    },
    {
      list: [
        { title: t("Sports"), path: "/sports" },
        { title: t("Casino"), path: "/casino" },
        { title: t("Live Casino"), path: "/live-casino" },
      ],
      title: t("PRODUCTS"),
    },
    {
      list: [
        { title: t("Pragmatic-Play"), path: "/live-casino" },
        { title: t("Evolution"), path: "/live-casino" },
        { title: t("Ezugi"), path: "/live-casino" },
        { title: t("Vivo Gaming"), path: "/live-casino" },
      ],
      title: t("Live Casino"),
    },
    {
      list: [
        { title: t("Deposits"), path: "/casher/finances" },
        { title: t("Withdrawals"), path: "/casher/finances" },
      ],
      title: t("SECURE BANKING"),
    },
    {
      list: [
        { title: t("Terms and Conditions"), path: "/terms&conditions" },
        { title: t("Responsible Gaming"), path: "/responsible-gaming" },
        { title: t("Self-Exclusion"), path: "/self-exclusion" },
        { title: t("disputeresolutions"), path: "/dispute-resolution" },
        { title: t("AML"), path: "/aml-policy" },
        { title: t("FAQ"), path: "/fairness-rng-testing" },
        { title: t("Privacy"), path: "/privacy-policy" },
        { title: t("accountpayoutsandbonus"), path: "/accounts-payouts-bonus" },
      ],
      title: t("TERMS & REGULATIONS"),
    },
  ];
  const handleClick = (path) => {
    const Token = getItemFromLocalStorage("Token");
    let ispresent = path.includes("/casher");
    if (!Token && ispresent) {
      props.loginModal(true);
    } else history.push(`/ocean-bet${path}`);
  };
  return (
    <div className="main-footer w-100">
      <div className="main-footer-container">
        <div className="custom-hr-line"></div>
        <div className="d-flex justify-content-center align-items-center gap-5">
          <div className="footerPayment-img-container">
            <img className="footerPayment-img" src={Images.mastercard} alt="" />
            <img className="footerPayment-img" src={Images.visa} alt="" />
            <img className="footerPayment-img" src={Images.neteller} alt="" />
            <img
              className="footerPayment-img"
              src={Images.paysafecard}
              alt=""
            />
            <img className="footerPayment-img" src={Images.skrill} alt="" />
            <img className="footerPayment-img" src={Images.paypal} alt="" />
          </div>
          <div className="custom-hr-right"></div>
          <div className="text-center">
            <img
              className="footerImages-social"
              src={Images.age_limit}
              alt=""
            />
            <p className="icons-text">Age Limit</p>
          </div>
        </div>
        <div className="custom-hr-line"></div>
        <div className="footer-links">
          {footerList?.map((list, i) => {
            return (
              <ul key={i} className="footer-ul-list">
                <div className="">
                  <div className="">
                    <div className="footer-links-title">
                      <li className="footer-links-header">{list?.title}</li>
                      <img
                        className="footer-downArrowGray"
                        src={Images.downArrowGray}
                        alt=""
                      />
                    </div>
                    {list?.list?.map(({ title, path }) => {
                      return (
                        <div>
                          <li
                            onClick={() => handleClick(path)}
                            className={`${
                              window?.location?.pathname?.includes(path)
                                ? "active-clr"
                                : ""
                            } footer-title-size`}
                          >
                            {title}
                          </li>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ul>
            );
          })}
        </div>
        <div
          className="footer-part2 d-flex  align-items-center "
          style={{
            background: `url(${
              themeMode === "light"
                ? Images.safeBetterBgLight
                : Images.casino_2_bg
            }) center no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <div className="px-4">
            <img
              className="casino_2-img"
              src={Images.SidebarLogo}
              alt="logo"
              height={60}
            />
          </div>
          <div className="">
            <div className="saferBetter">SAFER.BETTER.TOGETHER</div>
            <div className="playingResponsible">
              Playing responsible with limits
            </div>
          </div>
        </div>
        <div className="py-5">
          <p className="pb-3 footer-infoText">{t("CopyRight")}</p>
          <p className="pb-3 footer-infoText">{t("InfoText")}</p>
          {/* <p className="pb-3">
            We only use cookies which will enhance your experience and which
            will not interfere with your privacy.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  loginModal,
})(Footer);
