import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Images } from "../../Images";
import BasicLayout from "../../OceanBetBasicLayout";
import Subscribe from "../Home/Subscibe/Subscribe";
import "./style.css";

const promotionCategories = [
  {
    category: "Promos",
    icon: "",
    index: 0,
  },
  {
    category: "Sports",
    icon: "",
    index: 1,
  },
  {
    category: "Casino bonuses",
    icon: "",
    index: 2,
  },
];

const Promotions = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const PromotionBanner = {
    background: `${Images.HomeBanner}`,
    backgroundSize: "cover",
    height: "475px",
    position: "relative",
  };

  return (
    <BasicLayout>
      <div style={PromotionBanner}>
        <div className="promotions-card-container">
          <div className="promotions-card-heading">Promotions</div>
          <div className="promotions-card-hr-line"></div>
          <p className="promotions-card-content">
            We offer our members the best online gaming promotions available on
            the market. Members are offered an excellent welcome bonus and the
            opportunity to collect additional bonuses almost every day. Generous
            promotions and offers regularly appear in the calendar. With daily
            and weekly opportunities to win free spins, bonuses and other great
            prizes, the fun of online gaming is endless!
          </p>
        </div>
      </div>
      <div className="jackpot p-10">
        <div className="container pt-5">
          <div className="jackpot-header d-flex justify-content-start align-items-center" id="jackpot-header">
            <div className="leftstyle"></div>
            <div className="promotion-categories d-flex">
              {promotionCategories?.map(({ category, icon, index }) => (
                <div
                  className={`d-flex promotion-category-container ${
                    index === activeCategory
                      ? "promotion-category-container-active"
                      : ""
                  }`}
                  key={category}
                  onClick={() => setActiveCategory(index)}
                >
                  <img src="" alt="" width={28} height={27} />
                  <div>{category}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="subscribe py-4">
        <div className="promotion-category-section">
          <div className="d-flex flex-wrap gap-5 justify-content-between">
            {[0, 1, 2, 3, 4, 5].map((item, index) => (
              <Link to={`promotions/${index}`}>
                <img
                  src="https://via.placeholder.com/360"
                  alt=""
                  className=""
                  key={index}
                />
              </Link>
            ))}
          </div>
          <div className="promotion-section-hr-line"></div>
          <Subscribe bg={true} />
        </div>
      </div>
    </BasicLayout>
  );
};

export default Promotions;
