import { SearchOutlined } from "@ant-design/icons";
import { Spin, Switch } from "antd";
import browser from "browser-detect";
import { t } from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../Components/Common/getIp";
import { Images } from "../../../Images";
import { replaceGamesKeysNames } from "../../../utils/helper";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { games } from "../../Games/actions";
import { topresponsive } from "../../Home/NewGame/Responsive";
import { responsive } from "../lib/constants";
import GameCards from "./GameCards";
let providerBaseUrl = "https://oceanbet.io/assets/images/providers/";
const browserInfo = browser();

const GameNavbar = (props) => {
  const { games, gamesData, loading, searchText, setSearchText } = props;
  const listInnerRef = useRef();
  const [pageSize, setPageSize] = useState(24);
  const [favouriteStatus, setFavouriteStatus] = useState(false);
  const [showGameProviders, setShowGameProviders] = useState(true);
  const [highlightGame, setHighLightGame] = useState(0);
  const [providerId, setProviderId] = useState(null);
  const [open, setOpen] = useState(false);
  const themeMode = useSelector((state) => state.themeReducer.theme);
  const contentWrapper1 = useRef(null);
  const [show, setShow] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const Thememode = getItemFromLocalStorage("Theme");

  const Background = {
    background: `url(${
      Thememode === "dark"
        ? Images.Homebackgrounddark
        : Images.Homebackgroundlight
    }) no-repeat`,
  };
  const [gamesdata, setgamesdata] = useState([]);
  const { star, cherry, stars, dice, heart, Games4, ActiveGames } = Images;
  const Theme = useSelector((state) => state?.themeReducer?.theme);
  const [displayCategoryName, setDisplayCategoryName] = useState("AllGames");
  const [displayCategoryImg, setDisplayCategoryImg] = useState(
    Theme === "dark" ? Games4 : ActiveGames
  );

  const handleFavoruiteStatus = () => {
    setFavouriteStatus((prev) => !prev);
  };

  let allGames = gamesData?.Games;

  let allProviders = gamesData?.Providers;

  const [categoryId, setCategoryId] = useState(null);

  const Token = getItemFromLocalStorage("Token");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageSize((prev) => prev + 24);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: pageSize,
      WithWidget: false,
      CategoryId: categoryId,
      IsForMobile: false,
      Name: searchText,
      LanguageId: LanguageId,
      ProviderIds: providerId ? [providerId] : null,
    };
    if (Token) {
      payload = {
        ...payload,
        ClientId: ClientId || "",
        Token: Token || "",
      };
    }
    await games(payload);
  };

  const handleSetProviders = (id) => {
    setProviderId(id);
  };

  const gamesCategory = [
    {
      categoryName: "AllGames",
      categoryImage: Games4,
      category_id: null,
      categoryNo: 0,
    },
    {
      categoryName: "TopGames",
      categoryImage: star,
      category_id: 16,
      categoryNo: 1,
    },
    {
      categoryName: "ClassicSlots",
      categoryImage: cherry,
      category_id: 1,
      categoryNo: 2,
    },
    {
      categoryName: "Specials",
      categoryImage: stars,
      category_id: 8,
      categoryNo: 3,
    },
    {
      categoryName: "TableGames",
      categoryImage: dice,
      category_id: 4,
      categoryNo: 4,
    },
    {
      categoryName: "MyFavorites",
      categoryImage: heart,
      category_id: 0,
      categoryNo: 5,
    },
  ];

  const Games = () => {
    try {
      return (
        <Carousel
          responsive={false ? responsive : topresponsive}
          customButtonGroup={
            <CustomButtonGroupAsArrows
              next={props.next}
              previous={props.previous}
              rest={props.rest}
            />
          }
          arrows={false}
          className="py-1 carol-position"
        >
          {allProviders?.length > 0 &&
            allProviders?.map(({ Id, Name }, i) => {
              return (
                <div
                  ref={contentWrapper1}
                  key={i}
                  className="position-relative text-center"
                  onClick={() => handleSetProviders(Id)}
                >
                  <div>
                    <img
                      src={`${providerBaseUrl}${Id}.png`}
                      height={50}
                      width={100}
                      alt={Name}
                    />
                  </div>
                </div>
              );
            })}
        </Carousel>
      );
    } catch (error) {
      console.error("Error in Games funtion", error);
    }
  };

  const CustomButtonGroupAsArrows = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    return (
      <div className="position-absolute icon-container provider-mobile">
        <img
          onClick={previous}
          className={
            currentSlide === totalItems - slidesToShow
              ? "display-none"
              : "prev_icon"
          }
          src={themeMode === "light" ? Images.prevLight : Images.Prev}
          alt="prev icon"
        />
        <img
          onClick={next}
          className={
            currentSlide === totalItems - slidesToShow
              ? "display-none ms-1"
              : "next_icon ms-1"
          }
          src={themeMode === "light" ? Images.nextLight : Images.Next}
          alt="next icon"
        />
      </div>
    );
  };

  const activityTrack = async () => {
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Casino",
      EventType: "Search",
      attribute1:getItemFromLocalStorage("UserName") || "",
      attribute2: searchText || "",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };

  useEffect(() => {
    getGamesApi();
    activityTrack();
    // eslint-disable-next-line
  }, [searchText, categoryId, favouriteStatus, pageSize, providerId]);

  useEffect(() => {
    setgamesdata(gamesCategory);
    //eslint-disable-next-line
  }, [Theme]);

  return (
    <div className="" style={windowSize.innerWidth < 550 ? Background : {}}>
      <div className="header-input ">
        <input
          type="text"
          className="p-2"
          placeholder={t("Search_game")}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <SearchOutlined className="position-absolute top-0 end-0 p-3 search-icon" />
      </div>
      <div className="provider-mobile-container">
        <div className="provider-mobile-heading">
          <div className="nav-border"></div>
          <div className="provider-content-heading">All Providers</div>
        </div>
        <div ref={contentWrapper1}>{Games()}</div>
        {/* <div className="casino-hr ms-4" /> */}
      </div>
      <div className="casino-game mx-4">
        <div className="flex drop-down-mobile">
          <div className="nav-border"></div>
          <div className="dropDown-navMobile">
            <div
              onClick={() => setShow((prev) => !prev)}
              // className={`${navURL === el.title ? "nav-dropBtn" : "dropNone"}`}
              className="nav-dropBtn nav-dropBtn-padd"
            >
              <div>
                <img
                  src={displayCategoryImg}
                  className="nav-img gap-navBar mobile-finance-sidebar-img"
                  alt=""
                  style={{ width: "20px" }}
                />
                {t(displayCategoryName)}
              </div>
              <img
                src={Images.Prev}
                className={
                  show
                    ? "mobile-finance-sidebar-img casino-game-nav-arr-up"
                    : "mobile-finance-sidebar-img casino-game-nav-arr-down"
                }
                alt="prev icon"
              />
            </div>
            {gamesdata?.map(
              ({ categoryImage, categoryName, category_id, categoryNo }, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setHighLightGame(categoryNo);
                    setCategoryId(category_id);
                    setDisplayCategoryImg(categoryImage);
                    setDisplayCategoryName(categoryName);
                    setShow((prev) => !prev);
                  }}
                  className={`${
                    displayCategoryName !== categoryName
                      ? "dropShow"
                      : "dropNone"
                  }`}
                >
                  <div
                    className={`nav-dropContent ${
                      show ? "dropShow" : "dropNone"
                    }`}
                  >
                    <div>{t(categoryName)}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <ul className="d-flex justify-content-around p-0 m-0 casino-games-nav">
          {gamesdata?.map(
            ({ categoryImage, categoryName, category_id, categoryNo }, i) => (
              <div
                key={i}
                onClick={() => {
                  if (i === 0) {
                    setOpen(!open);
                  }
                }}
                className={`casino-game-menu ${
                  !open && i !== 0 ? "casino-game-menu-el" : ""
                }
                ${i !== 0 && "casino-game-menu-el-b"}
                ${i === 0 && "casino-game-menu-title-p"}`}
              >
                <li
                  className={`cursor-pointer ${
                    categoryNo === highlightGame
                      ? "games-category"
                      : "games-category-hover"
                  }`}
                  key={category_id}
                  onClick={() => {
                    setHighLightGame(categoryNo);
                    setCategoryId(category_id);
                  }}
                >
                  <img
                    src={categoryImage}
                    width="auto"
                    height="auto"
                    alt={t(categoryName)}
                  />
                  <h2
                    id={`${
                      categoryNo === highlightGame
                        ? "games-category-h2"
                        : "games-category-hover-h2"
                    }`}
                  >
                    {t(categoryName)}
                  </h2>
                </li>
                {i === 0 && (
                  <img
                    src={Images.Prev}
                    className={
                      open && i === 0
                        ? "casino-game-nav-arr-up"
                        : "casino-game-nav-arr-down"
                    }
                    alt="prev icon"
                  />
                )}
                {i !== 0 && (
                  <div className={`white-arr ${!open && "d-none"}`}>{">"}</div>
                )}
              </div>
            )
          )}
          <li className="position-relative">
            <input
              type="text"
              className="p-2 casino-hide-input"
              placeholder={t("Search_game")}
              data-e2e="search-game"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <SearchOutlined className="position-absolute end-0 p-3 search-icon casino-hide-input" />
          </li>
        </ul>
      </div>
      <div className="game-body" id="game-body">
        <div className="provider-desktop">
          <div className="d-flex justify-content-between align-items-center px-2 py-2">
            <div className="">
              <h1 className="m-0">{t("Game_Providers")}</h1>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <p className="mb-0 px-3">{t("Show_Providers")}</p>
              <Switch
                defaultChecked
                onChange={() => setShowGameProviders((prev) => !prev)}
              />
            </div>
          </div>
          <div className="casino-hr" />
          {showGameProviders && (
            <>
              <div className="p-1 text-center navbar-carousel">
                <div className="position-relative">
                  <Carousel responsive={responsive}>
                    {allProviders?.length > 0 &&
                      allProviders?.map(({ Id, Name }, i) => {
                        return (
                          <div
                            key={i}
                            className={`provider-image provider-img-alt-text ${Id === providerId ? "seleceted-provider" : ""}`}
                            onClick={() => handleSetProviders(Id)}
                          >
                            <img
                            data-e2e={Name}
                              src={`${providerBaseUrl}${Id}.png`}
                              className="provider-img"
                              alt={Name || "providers"}
                            />
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              </div>
              <div className="casino-hr" />
            </>
          )}
        </div>
        <div className="container-fluid">
          <div
            className="row-container"
            ref={listInnerRef}
            id="infinite-list"
            style={{ overflow: "auto" }}
          >
            {loading ? (
              <div className="game-provider-loader">
                <Spin tip="Loading..." data-e2e="games-present" />
              </div>
            ) : allGames && allGames?.length === 0 ? (
              <div className="data-not-available" data-e2e="data not available">Data not available</div>
            ) : (
              allGames?.map((game, i) => {
                let result = replaceGamesKeysNames(game);
                if (allGames?.length === i + 1) {
                  return (
                    <div
                    data-e2e={i}
                      key={i}
                      ref={lastBookElementRef}
                      className="col-sm-6 col-md-3 d-flex justify-content-center align-items-center  px-2 py-2"
                    >
                      <GameCards
                        data={result}
                        handleFavoruiteStatus={handleFavoruiteStatus}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                    data-e2e={i}
                      key={i}
                      className="col-sm-6 col-md-3 d-flex justify-content-center align-items-center  px-2 py-2"
                    >
                      <GameCards
                        data={result}
                        handleFavoruiteStatus={handleFavoruiteStatus}
                      />
                    </div>
                  );
                }
              })
            )}
          </div>
        </div>
        {/* {allGames && allGames.length > 0 && pageSize <= allGames?.length && (
          <div className="show-more-container">
            <button
              className="card-play-btn border-gradient-1 show-more-btn"
              onClick={() => {
                setPageSize((prev) => prev + 24);
              }}
            >
              {t("See More")}
            </button>
          </div>
        )} */}
        <div>{loading && "Loading..."}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.gamesReducer?.gamesData,
    loading: state?.config?.loading,
  };
};

export default connect(mapStateToProps, {
  games,
})(GameNavbar);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
