import axios from "axios";

class HeaderApi {
  getClientBalance = (data) =>
    axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetClientBalance",
      data
    );
}

export default new HeaderApi();
