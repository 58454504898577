import { Button, Select } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Images } from "../../../../../Images";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { verificationList } from "../../../lib/constants";
import {
  documentType, getdocumentHistory, submitDocument
} from "../../Services/actions";
import "./Verification.css";

const { Option } = Select;

const Verification = (props) => {
  const { chooseFile } = Images;
  const hiddenFileInput = useRef(null);
  const [Base64, setBase64] = useState();
  const [ImageName, setImageName] = useState();
  const [ImageType, setImageType] = useState();
  const [DocumentTypeId, setDocumentTypeId] = useState();
  const { documentType, submitDocument, getdocumentHistory } = props;
  const DocumentType = useSelector(
    (state) => state?.profileReducer?.documentType?.data?.ResponseObject
  );

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    getBase64(fileUploaded).then((res) => {
      let base64result = res.split(",")[1];
      const type = res.substring("data:image/".length, res.indexOf(";base64"));
      setImageType(type);
      setBase64(base64result);
      setImageName(fileUploaded.name);
    });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let PartnerId = getItemFromLocalStorage("PartnerId");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  const HandleKycDocumentsType = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetKYCDocumentTypesEnum",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await documentType(payload);
  };

  const GetDocumentHistory = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetClientIdentityModels",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    await getdocumentHistory(payload);
  };

  useEffect(() => {
    GetDocumentHistory();
    HandleKycDocumentsType();
    //eslint-disable-next-line
  }, []);

  const HandleSubmitDocument = async () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "UploadImage",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      Name: ImageName,
      RequestData: JSON.stringify({
        ClientId: ClientId,
        Name: ImageName,
        ImageData: Base64,
        Extension: ImageType,
        DocumentTypeId: DocumentTypeId,
        Status: "",
      }),
      ClientId: ClientId,
      Token: Token,
    };
    await submitDocument(payload);
    setTimeout(() => {
      GetDocumentHistory();
    }, 2000);
  };

  return (
    <div className="ver">
      <div className="ver-title">{t("DocumentIdentificationMessage")}</div>
      <div className="ver-list">
        <ul>
          {verificationList.map((list, i) => {
            return (
              <li key={i} className="ul-list">
                {t(list)}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="d-flex justify-content-start align-items-center gap-4 px-4 py-4 ver-mobile">
        <div className="d-flex flex-column choose-cont">
          <label className="select-label">{t("Choose document")}</label>
          <Select
            className="custom-select"
            placeholder={t("Choose document")}
            onChange={(e) => setDocumentTypeId(e.value)}
            labelInValue
          >
            {DocumentType &&
              DocumentType?.map((e, i) => {
                return <Option value={e.Id}>{e.Name}</Option>;
              })}
          </Select>
        </div>
        <div className="custom-btn-flex gap-3">
          <div className="custom-btn">
            <Button onClick={handleClick} className="border-gradient">
              <img src={chooseFile} alt="" /> {t("SelectFile")}
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          {ImageName && <h3 className="image-text">{ImageName}</h3>}

          <button className="verify-btn" id="register-btn-clr" onClick={HandleSubmitDocument}>
            {t("Verify")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  documentType,
  submitDocument,
  getdocumentHistory,
})(Verification);
