import Nav from "../_sharedComponents/SubNav/Nav";
import CasherNav from "../context/context";
import { useContext, useEffect, useState } from "react";
import { bonusesNavEl } from "../lib/constants";
import MainHistory from "../History/Bets/Main/MainHistory";
import BonusesForms from "./BonusesForms/BonusesForms";
import { useDispatch, useSelector } from "react-redux";
import { bonusList, bonuses } from "./Services/actions";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { t } from "i18next";
import "./bonuses.css";
import BasicLayout from "../../../OceanBetBasicLayout";
import NavBar from "../_sharedComponents/NavBar/NavBar";

const Bonuses = (props) => {
  const dispatch = useDispatch();

  const { bonusUrls, setBonusUrl } = useContext(CasherNav);
  const bonusStatusList = useSelector(
    (state) => state?.bounsReducer?.bonusStatusList?.ResponseObject
  );
  const [statusSelected, setStatusSelected] = useState("");
  const [bonusStatus, setBonusStatus] = useState();

  const navElBonuses = [
    {
      title: t("Id"),
      dataIndex: "Id",
      key: "Id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Name"),
      dataIndex: "Name",
      key: "Name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Type"),
      dataIndex: "Type",
      key: "Type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Amount"),
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
    {
      title: t("AwardingTime"),
      dataIndex: "Awarding Time",
      key: "Awarding Time",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
    {
      title: t("Final_amount"),
      dataIndex: "Final Amount",
      key: "Final Amount",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
    {
      title: t("Status"),
      dataIndex: "Status",
      key: "Status",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
    {
      title: t("CalculationTime"),
      dataIndex: "Calculation Time",
      key: "Calculation Time",
      render: (text) => <p>{text}</p>,
      responsive: ["sm"],
    },
  ];

  const main = () => {
    if (bonusUrls === "Bonuses")
      return (
        <>
          <div className="d-flex flex-column gap-4">
            <div className="title-border"></div>
            <BonusesForms
              bonusStatusList={bonusStatusList}
              statusSelected={statusSelected}
              setStatusSelected={setStatusSelected}
              setBonusStatus={setBonusStatus}
            />

            <div className="title-border"></div>
          </div>
          <MainHistory
            empty={"Your bonuses table is Empty"}
            navEl={navElBonuses}
          />
        </>
      );
  };

  const PartnerId = getItemFromLocalStorage("PartnerId");
  const Token = getItemFromLocalStorage("Token");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const getBounses = () => {
    const payload = {
      Loader: true,
      Controller: "Document",
      Method: "GetBonuses",
      ProductId: statusSelected?.status || 1,
      Token: Token,
      ClientId: ClientId,
      PartnerId: 43,
      RequestData: JSON.stringify({
        ProductId: statusSelected?.status || 1,
      }),
    };
    dispatch(bonuses(payload));
  };

  const getBonusStatusList = () => {
    let payload = {
      Loader: true,
      PartnerId: PartnerId,
      TimeZone: 5.5,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "GetBonusStatusesEnum",
      Controller: "Main",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Code: null,
      RequestData: "{}",
      ClientId: ClientId,
      Token: Token,
    };
    dispatch(bonusList(payload));
  };

  useEffect(() => {
    getBonusStatusList(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBounses(); // eslint-disable-next-line
  }, [bonusStatus]);
  return (
    <BasicLayout>
      <div className="main-container">
        <NavBar />
        <div className="bonus-main d-flex flex-column gap-4">
          <div className="bonus-nav-mob">
            <Nav navEl={bonusesNavEl} url={bonusUrls} setUrl={setBonusUrl} />
          </div>
          <div className="d-flex flex-column">{main()}</div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Bonuses;
