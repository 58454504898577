import { Select } from "antd";
import { t } from "i18next";
import React from "react";

const ResponsibleGame = ({ editStatus, handleEditStatus }) => {
  const { Option } = Select;

  return (
    <div className="set-main d-flex flex-column gap-3">
      <div className="d-flex align-items-center justify-items-center gap-4 respGame-col">
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Limit Type")}</div>
          <div className="w-100">
            <Select
              className="amount-input with-input-w fontSize-12"
              defaultValue="select"
            >
              <Option value="USDT">Total Deposit</Option>
            </Select>
          </div>
        </div>
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Minutes")}</div>
          <input
            className="profile-input fontSize-12"
            placeholder={t("Minutes")}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="fontSize-12">{t("Daily")}</div>
        <input className="profile-input fontSize-12" placeholder={t("Daily")} />
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="fontSize-12">{t("Weekly")}</div>
        <input
          className="profile-input fontSize-12"
          placeholder={t("Weekly")}
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="fontSize-12">{t("Monthly")}</div>
        <input
          className="profile-input fontSize-12"
          placeholder={t("Monthly")}
        />
      </div>
      <div className="d-flex align-items-end justify-items-center gap-4">
        <div className="set-save-btn fontSize-12" id="register-btn-clr">{t("Save-Changes")}</div>
        <div className="set-canc-btn-border">
          <div className="set-canc-btn" id="register-btn-clr">{t("Cancel-changes")}</div>
          <div className="set-canc-btn set-canc-btn-mobile fontSize-12">
            {t("X")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsibleGame;
