import { InfoCircleFilled } from "@ant-design/icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loginModal } from "../../Components/Login/actions";
import { checkLoginStatus, playNowGame } from "../../modules/Games/actions";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import CasinoCardInfoModel from "../Models/CasinoCardInfoModel";
import CasioCardGameModal from "../Models/CasioCardGameModal";
import "./gamesCard.css";

const GamesCard = (props) => {
  const [infoModal, setinfoModal] = useState(false);
  const [gamemodel, setgamemodel] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [gameUrl, setgameUrl] = useState(false);

  // eslint-disable-next-line
  const { loginModal, playNowGame, checkLoginStatus, isloggedIn } = props;

  const { gameImage, name, productId } = props?.result;

  let showgameUrl = useSelector(
    (state) => state?.gamesReducer?.playNowGame?.data
  );

  const handlehostname = () => {
    if (showgameUrl) {
      const hostName = window?.location?.host;
      showgameUrl.ResponseObject = showgameUrl?.ResponseObject?.replace(
        hostName,
        "oceanbet.io"
      );
      setgameUrl(showgameUrl);
    }
  };
  useEffect(() => {
    if (showgameUrl) {
      handlehostname();
    }
    //eslint-disable-next-line
  }, [showgameUrl]);

  const PartnerId = getItemFromLocalStorage("PartnerId");
  const Token = getItemFromLocalStorage("Token");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const handlePlayNow = async () => {
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: PartnerId,
        TimeZone: 5.5,
        LanguageId: LanguageId,
        ProductId: productId,
        Method: "GetProductUrl",
        Controller: "Main",
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: "{}",
        IsForDemo: false,
        IsForMobile: false,
        Position: "",
        DeviceType: 1,
        ClientId: ClientId,
        Token: Token,
      };
      await playNowGame(payload);
    } else {
      await loginModal(true);
    }
  };

  const handledemobtn = async () => {
    setgamemodel(true);
    handleDemo();
  };

  const handleDemo = async () => {
    let payload = {
      Loader: true,
      TimeZone: 5.5,
      Controller: "Main",
      LanguageId: "en",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Email: null,
      Code: null,
      RequestData: "{}",
      PartnerId: 43,
      ProductId: productId,
      Method: "GetProductUrl",
      IsForDemo: true,
      IsForMobile: false,
      Position: "",
      DeviceType: 1,
    };
    await playNowGame(payload);
  };
  const handlePlaybtn = async () => {
    if (Token) {
      setgamemodel(true);
      handlePlayNow();
    } else {
      await checkLoginStatus(true);
      await setinfoModal(false);
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <div onClick={() => windowSize.innerWidth < 500 && setinfoModal(true)}>
        <div className="position-relative">
          <div className="gamesCard_img-container">
            <img
              src={gameImage}
              alt="games-card"
              // height={182}
              // width={215}
              loading="lazy"
              className="gamesCard_img d-flex justify-content-center align-content-center m-auto w-100 "
            />
          </div>
          <div className="text-light casino-section-body">
            <div className="d-flex justify-content-center  py-5 ">
              <button
                className="card-play-btn border-gradient-1"
                id="register-btn-clr"
                onClick={handlePlaybtn}
              >
                {t("Play Now")}
              </button>
            </div>
            <div className="d-flex justify-content-between ">
              <Link onClick={() => setgamemodel(true)}>{t("Demo")}</Link>
              <div
                className="d-flex gap-1 info-model"
                onClick={() => setinfoModal(true)}
              >
                <InfoCircleFilled className="lh-sm" />
                <p className="m-0">Info</p>
              </div>
            </div>
          </div>
        </div>
        <div className="gamesCard-container-line d-flex flex-column">
          <div className="games-card-header mt-20">{name} </div>
          <p className="games-card-horizontal-line mt-10"></p>
        </div>
      </div>

      <CasinoCardInfoModel
        infoModal={infoModal}
        setinfoModal={setinfoModal}
        data={props?.result}
        handlePlaybtn={handlePlaybtn}
        handledemobtn={handledemobtn}
      />
      <CasioCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        result={props?.result}
        showgameUrl={gameUrl}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // gamesData: state?.gamesReducer?.gamesData,
    isloggedIn: state.loginReducer?.isloggedIn,
  };
};

export default connect(mapStateToProps, {
  playNowGame,
  loginModal,
  checkLoginStatus,
})(GamesCard);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
