import {
  ClockCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Select } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../Images/index";
import { Localisation } from "../../locale/Localisation";
import CasherNav from "../../modules/Casher/context/context";
import routes from "../../Route/URLs";
import { setTheme } from "../../theme/actions";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { setThemeMode } from "../Common/theme";
import { loginModal, logout } from "../Login/actions";
import Login from "../Models/Login/Login";
import NewTicket from "../Models/Mail/NewTicket";
import Register from "../Models/Register/Register";
import { getClientBalance } from "./actions";
import MobileLogged from "./MobileLogged/MobileLogged";
import MobileLogIn from "./MobileLogIn/MobileLogIn";
import "./Navbar.css";
import SideBar from "./SideBar/SideBar";
import { currency_symbols } from "./constantNavBar";
const { Option } = Select;

const Navbar = (props) => {
  const dispatch = useDispatch();
  const { setNavURL, setFinUrl } = useContext(CasherNav);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [siderMenu, setSiderMenu] = useState(false);
  const [Time, setTime] = useState("");
  const [mobLogIn, setMobLogin] = useState(false);
  const [mobLogged, setMobLogged] = useState(false);
  const history = useHistory();
  const { getClientBalance, loginModal, logout } = props;
  const [registerModal, setregisterModal] = useState(false);
  const loggedIn = getItemFromLocalStorage("Token");
  const [showamount, setshowamount] = useState(false);
  const [userlogin, setuserlogin] = useState(false);
  const firstName = getItemFromLocalStorage("FirstName");
  const lastName = getItemFromLocalStorage("LastName");
  const userName = firstName ? `${firstName} ${lastName}` : "N/A";
  const [mailmodel, setmailmodel] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { ChangeLanguage } = Localisation;
  const { t } = useTranslation();
  const closeModal = useSelector((state) => state?.loginReducer?.closeModal);
  const ResponseCode = useSelector(
    (state) => state?.loginReducer?.loginData?.data?.ResponseCode
  );

  const Description = useSelector(
    (state) => state?.loginReducer?.loginData?.data?.Description
  );
  const AvailableBalance = useSelector(
    (state) =>
      state?.headerReducer?.getClientBalanceData?.data?.AvailableBalance
  );
  const clientBalance = useSelector(
    (state) =>
      state?.headerReducer?.getClientBalanceData?.data?.AvailableBalance
  );
  const bonusBalance =
    clientBalance?.Balances?.find((balance) => balance.TypeId === 12).Balance ||
    0;

  useEffect(() => {
    if (loggedIn) {
      setuserlogin(true);
      loginModal(false);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (closeModal === true) {
      loginModal(false);
    }
  }, [closeModal, loginModal]);

  const CurrencyId = getItemFromLocalStorage("CurrencyId");

  useEffect(() => {
    const PartnerId = getItemFromLocalStorage("PartnerId");
    const ClientId = getItemFromLocalStorage("Id");
    const Token = getItemFromLocalStorage("Token");
    let payload = {
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
    };

    if (Token) {
      getClientBalance(payload);
    }
    let interval1 = null;
    if (!initialized && !interval1) {
      interval1 = setInterval(() => {
        setIntervalCalls();
      }, 30000);
    }
    return () => {
      clearInterval(interval1);
    }; // eslint-disable-next-line
  }, []);

  const setIntervalCalls = async () => {
    const PartnerId = getItemFromLocalStorage("PartnerId");
    const CurrencyId = getItemFromLocalStorage("CurrencyId");
    const ClientId = getItemFromLocalStorage("Id");
    const Token = getItemFromLocalStorage("Token");
    let payload = {
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
    };
    setInitialized(true);
    if (Token) {
      getClientBalance(payload);
    }
  };

  const showRegisterModal = () => {
    setregisterModal(true);
  };

  const showLoginModal = () => {
    loginModal(true);
  };

  const HandleLogout = () => {
    setuserlogin(false);
    logout(false);
    history.push(routes.HOME_PAGE);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                history.push(routes.CASHER_FINANCES);
                setFinUrl("Deposit");
              }}
            >
              <span data-e2e={t("Deposits")}>{t("Deposits")}</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                history.push(routes.CASHER_FINANCES);
                setFinUrl("Withdraw");
              }}
            >
              <span data-e2e={t("Withdraw")}> {t("Withdraw")}</span>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                history.push(routes.CASHER_FINANCES);
                setFinUrl("Bank_Accounts");
              }}
            >
              <span data-e2e={t("BankAccounts")}> {t("BankAccounts")}</span>
            </div>
          ),
        },
        {
          key: "4",
          label: (
            <div
              onClick={() => {
                history.push(routes.CASHER_HISTORY);
                setNavURL("History");
              }}
            >
              <span data-e2e={t("History")}> {t("History")}</span>
            </div>
          ),
        },

        {
          key: "5",
          label: (
            <div data-e2e="profile"
              onClick={() => {
                history.push(routes.CASHER_PROFILE);
                setNavURL("Profile");
              }}
            >
              <span data-e2e={t("Settings")}> {t("Settings")}</span>
            </div>
          ),
        },
        {
          key: "6",
          label: (
            <div
              onClick={() => {
                history.push(routes.CASHER_TICKET);
                setNavURL("Ticket");
              }}
            >
              <span data-e2e={t("My_tickets")}> {t("My_tickets")}</span>
            </div>
          ),
        },
        {
          key: "7",
          label: (
            <p className="m-0" onClick={HandleLogout}>
              <span className="pe-1 logout" data-e2e={t("Logout")}>
                <img src={Images.logout} alt="" />
              </span>
              {t("Logout")}
            </p>
          ),
        },
      ]}
    />
  );

  const HandleSelectLanguage = (e) => {
    ChangeLanguage(e);
    // localStorage.setItem("key",e)
    window.location.reload();
  };
  const languageDropdown = [
    { key: "da", img: Images.da, value: "Dansk" },
    { key: "de", img: Images.de, value: "Deutsche" },
    { key: "en", img: Images.en, value: "English" },
    { key: "es", img: Images.es, value: "Español" },
    { key: "fr", img: Images.fr, value: "Français" },
    { key: "it", img: Images.it, value: "Italiano" },
    { key: "nn", img: Images.nn, value: "Norsk" },
    { key: "ru", img: Images.ru, value: "Русский" },
    { key: "zh", img: Images.zh, value: "漢語" },
  ];

  const handleTheme = () => {
    if (getItemFromLocalStorage("Theme") === "dark") {
      setThemeMode("light");
      dispatch(setTheme("light"));
    } else {
      setThemeMode("dark");
      dispatch(setTheme("dark"));
    }
  };

  const time = moment().format("HH:mm");
  let date = new Date();
  date = date.toTimeString().split(" ", 2)[1];
  let timeNow = `${time} (${date})`;

  useEffect(() => {
    setInterval(() => {
      const time = moment().format("HH:mm");
      let date = new Date();
      date = date.toTimeString().split(" ", 2)[1];
      setTime(`${time} (${date})`);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-navbar ">
        <div className="main-navbar-responsiveMenu">
          <div className="burger-menu">
            <img
              src={Images.burgerMenu}
              alt="burgerMenu"
              onClick={() => setSiderMenu((prev) => !prev)}
            />
          </div>
          <div className={` ${firstName ? "ps-5" : ""}`}>
            <Link to="/">
              <img
                className={`sidebarLogo-navbar`}
                src={Images.SidebarLogo}
                alt="sidebarLogo"
              />
            </Link>
          </div>
          <SideBar
            siderMenu={siderMenu}
            setSiderMenu={setSiderMenu}
            userlogin={userlogin}
            userName={userName}
            HandleSelectLanguage={HandleSelectLanguage}
          />
          {userlogin && mobLogged ? (
            <MobileLogged
              mobLogged={mobLogged}
              setMobLogged={setMobLogged}
              AvailableBalance={AvailableBalance}
              HandleLogout={HandleLogout}
            />
          ) : (
            <MobileLogIn
              mobLogIn={mobLogIn}
              setMobLogin={setMobLogin}
              userlogin={userlogin}
              mobLogged={mobLogged}
              showLoginModal={showLoginModal}
              setregisterModal={setregisterModal}
            />
          )}
          {userlogin === true ? (
            <div className="d-flex align-items-center">
              <div className="navBarlogIn-account">
                {Number(AvailableBalance) > 0 ? AvailableBalance : 0 || 0}{" "}
                {currency_symbols[CurrencyId] || "€"}
              </div>
              <div
                className="navBarlogIn-name d-flex justify-content-center align-items-center"
                onClick={() => setMobLogged(true)}
              >
                <div>{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</div>
              </div>
            </div>
          ) : (
            <div className="user_2-menu">
              <img
                src={Images.user_2}
                alt="user_2"
                onClick={() => setMobLogin((prev) => !prev)}
              />
            </div>
          )}
        </div>
        <div className="row h-100 align-items-center me-3 navbar-mobile-view">
          <div className="col-2 col-sm-2">
            <Select
              className="navbar-select w-65"
              suffixIcon={
                <img
                  src={
                    themeMode === "light"
                      ? Images.dropdownLight
                      : Images.dropdownDark
                  }
                  alt="icon"
                />
              }
              defaultValue={
                getItemFromLocalStorage("i18nextLng") === "en-GB"
                  ? "en"
                  : getItemFromLocalStorage("i18nextLng")
              }
              onChange={HandleSelectLanguage}
            >
              {languageDropdown?.map((e, i) => {
                return (
                  <>
                    <Option value={e.key}>
                      <span className="pe-2 language-dropdown">
                        <img className="" src={e.img} alt="" />
                      </span>
                      {e.value}
                    </Option>
                  </>
                );
              })}
            </Select>
          </div>
          <div className="navbar-right col-10 col-sm-10">
            <ul className="list-inline mb-0">
              {userlogin ? (
                <>
                  <li className="list-inline-item">
                    <button className="custom-btn border-gradient-2 d-flex cursor-unset">
                      <ClockCircleOutlined style={{ fontSize: "16px" }} />{" "}
                      <span className="ps-2">{Time || timeNow}</span>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button className="custom-btn border-gradient-2 cursor-unset">
                      <span>
                        {t("Bonus_Balance")} {CurrencyId}{" "}
                        <span className="ps-3">{bonusBalance || 0}</span>
                      </span>
                    </button>
                  </li>
                  <li className="list-inline-item ">
                    <button className="custom-btn border-gradient-2 d-flex cursor-unset">
                      <span>
                        {" "}
                        {t("Balance")} {CurrencyId}{" "}
                        <span className="ps-3">
                          {showamount ? AvailableBalance || "0" : "******"}
                        </span>
                        {showamount ? (
                          <EyeOutlined
                            style={{ fontSize: "18px" }}
                            className="ps-3"
                            onClick={() => setshowamount(false)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            style={{ fontSize: "18px" }}
                            className="ps-3"
                            onClick={() => setshowamount(true)}
                          />
                        )}
                      </span>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button
                      className="custom-btn border-gradient custom-img-white"
                      onClick={() => setmailmodel(true)}
                    >
                      <span>
                        <img src={Images.mail} alt="" />
                      </span>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <div onClick={() => history.push(routes.CASHER_FINANCES)}>
                      <button
                        className="custom-btn border-gradient-1"
                        id="register-btn-clr"
                      >
                        <span data-e2e="deposit-button">{t("Deposits")}</span>
                      </button>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <Dropdown
                      overlayClassName="custom-dropdown"
                      overlay={menu}
                      trigger={["click"]}
                    >
                      <button className="custom-btn border-gradient custom-img-white">
                        <div>
                          <img src={Images.user} alt="" />
                          <span data-e2e="user-profile-icon" className="px-1">{userName}</span>
                        </div>
                      </button>
                    </Dropdown>
                  </li>
                  <li className="list-inline-item">
                    <button
                      onClick={() => {
                        handleTheme();
                      }}
                      className="custom-btn border-gradient-2"
                    >
                      <span>
                        {themeMode === "light" ? t("Dark") : t("Light")}
                      </span>
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="list-inline-item cursor-unset">
                    <button className="custom-btn border-gradient-2 d-flex cursor-unset">
                      <img
                        src={
                          themeMode === "light"
                            ? Images.timeLight
                            : Images.timeDark
                        }
                        alt="time_icon"
                        style={{ fontSize: "16px" }}
                      />{" "}
                      <span className="ps-2">{Time || timeNow}</span>
                    </button>
                  </li>
                  {userlogin ? (
                    <>
                      <li className="list-inline-item">
                        <button className="custom-btn border-gradient-2 cursor-unset">
                          <span>
                            Bonus Balance {CurrencyId}{" "}
                            <span className="ps-3">{bonusBalance || 0}</span>
                          </span>
                        </button>
                      </li>
                      <li className="list-inline-item ">
                        <button className="custom-btn border-gradient-2 d-flex">
                          <span>
                            {" "}
                            Balance EUR{" "}
                            <span className="ps-3">
                              {showamount ? AvailableBalance || "0" : "******"}
                            </span>
                            {showamount ? (
                              <EyeOutlined
                                style={{ fontSize: "18px" }}
                                className="ps-3"
                                onClick={() => setshowamount(false)}
                              />
                            ) : (
                              <EyeInvisibleOutlined
                                style={{ fontSize: "18px" }}
                                className="ps-3"
                                onClick={() => setshowamount(true)}
                              />
                            )}
                          </span>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <button
                          className="custom-btn border-gradient custom-img-white"
                          onClick={() => setmailmodel(true)}
                        >
                          <span>
                            <img src={Images.mail} alt="" />
                          </span>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <div
                          onClick={() => {
                            history.push(routes.CASHER_FI);
                          }}
                        >
                          <button className="custom-btn border-gradient-1">
                            <span data-e2e={t("Deposits")}>
                              {t("Deposits")}
                            </span>
                          </button>
                        </div>
                      </li>
                      <li className="list-inline-item">
                        <Dropdown
                          overlayClassName="custom-dropdown"
                          overlay={menu}
                          trigger={["click"]}
                        >
                          <button className="custom-btn border-gradient custom-img-white">
                            <img src={Images.user} alt="" />
                            <span className="px-1" data-e2e={userName}>
                              {userName}
                            </span>
                          </button>
                        </Dropdown>
                      </li>
                      <li className="list-inline-item">
                        <button
                          onClick={() => {
                            handleTheme();
                          }}
                          className="custom-btn border-gradient-2"
                        >
                          <span>
                            {themeMode === "light" ? "Dark" : "Light"}
                          </span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="list-inline-item">
                        <button
                          className="custom-btn border-gradient"
                          onClick={() => showLoginModal()}
                        >
                          <span>
                            <span data-e2e={t("Sign In")}>{t("Sign In")}</span>
                          </span>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <button
                          className="custom-btn border-gradient-1"
                          id="register-btn-clr"
                          onClick={() => {
                            showRegisterModal();
                          }}
                        >
                          <span>
                            <span data-e2e={t("Sign Up")}>{t("Sign Up")}</span>
                          </span>
                        </button>
                      </li>
                    </>
                  )}

                  <li className="list-inline-item">
                    <button
                      onClick={() => {
                        handleTheme();
                      }}
                      className="custom-btn border-gradient-2"
                    >
                      <span
                        data-e2e={
                          themeMode === "light" ? t("Dark") : t("Light")
                        }
                      >
                        {themeMode === "light" ? t("Dark") : t("Light")}
                      </span>
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Login
        ResponseCode={ResponseCode}
        Description={Description}
        setregisterModal={setregisterModal}
      />
      <Register
        registerModalStatus={registerModal}
        setregisterModal={setregisterModal}
      />
      <NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
    </>
  );
};

export default connect(null, {
  getClientBalance,
  loginModal,
  logout,
})(Navbar);
