import { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getItemFromLocalStorage } from "../../../utils/localStorage";

const CasherNav = createContext();

export const CasherProvider = ({ children }) => {
  const [navURL, setNavURL] = useState("Finances");
  const [finUrl, setFinUrl] = useState("Deposit");
  const [histUrl, setHistUrl] = useState("Bets");
  const [bonusUrls, setBonusUrl] = useState("Bonuses");
  const [ticketUrl, setTicketUrl] = useState("My_tickets");
  const [profileUrl, setProfileUrl] = useState("Settings");
  const [balanceUrl, setBalanceUrl] = useState("Balance");
  const [partnerPayment, setPartnerPayment] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);

  const location = window.location.pathname;

  const fetchData = async () => {
    const res = await axios.post(
      "https://websitewebapi.oceanbet.io/43/api/Main/GetPartnerPaymentSystems",
      {
        params: {
          PartnerId: 43,
          ClientId: 1630677,
          LanguageId:
            getItemFromLocalStorage("i18nextLng") === "en-GB"
              ? "en"
              : getItemFromLocalStorage("i18nextLng"),
        },
      }
    );
    setPartnerPayment(res?.data?.PartnerPaymentSystems);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setNavURL(
      location?.split("/")[3]?.charAt(0)?.toUpperCase() +
        location.split("/")[3]?.slice(1)
    );
  }, [location]);

  return (
    <CasherNav.Provider
      value={{
        navURL,
        setNavURL,
        finUrl,
        setFinUrl,
        histUrl,
        setHistUrl,
        bonusUrls,
        setBonusUrl,
        ticketUrl,
        setTicketUrl,
        profileUrl,
        setProfileUrl,
        balanceUrl,
        setBalanceUrl,
        partnerPayment,
        setPartnerPayment,
        selectedCurrency,
        setSelectedCurrency,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
      }}
    >
      {children}
    </CasherNav.Provider>
  );
};

export default CasherNav;
