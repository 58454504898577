import BasicLayout from "../../../OceanBetBasicLayout";
import { accountPayoutBonus } from "../lib/constants";
import "./termsCondition.css";

const AccountPayoutAndBonus = () => {
  return (
    <BasicLayout>
      <div className="term-main-container">
        <div className="d-flex flex-column w-100">
          <div className="term-title">Terms of Service</div>
          <div className="term-terms">
            {
              // eslint-disable-next-line
              accountPayoutBonus?.map((term, i) => {
                if (i < 2)
                  return (
                    <div id={i}>
                      <div className={`term-terms-title`}>
                        {term.title}
                      </div>
                      {accountPayoutBonus[0]?.expl?.map((e, j) => (
                        <div className="d-flex align-items-baseline gap-4 mb-3">
                          <div>{j + 1}.</div>
                          <div>{e}</div>
                        </div>
                      ))}
                    </div>
                  );
                if (i === 2)
                  return (
                    <div id={i}>
                      <div className="term-terms-title">
                        {term.title}
                      </div>
                      {accountPayoutBonus[0]?.expl?.map((e, j) => (
                        <div className="mb-3">
                          <div>{e}</div>
                        </div>
                      ))}
                    </div>
                  );
              })
            }
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default AccountPayoutAndBonus;