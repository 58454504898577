import { StarFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { t } from "i18next";
import React from "react";
import "./Casinomodals.css";

const CasinoCardInfoModel = (props) => {
  const { data, handlePlaybtn, handledemobtn } = props;
  const { gameImage, name, rating, hasDemo, providerName, jackpotValue } = data;
  const modalBackground = {
    background: `url(${gameImage}) center no-repeat`,
    backgroundSize: "cover",
  };

  return (
    <Modal
      visible={props.infoModal}
      footer={null}
      width="700px"
      className="info-modal"
      onCancel={() => props.setinfoModal(false)}
    >
      <div className="info-modal-desktop">
        <div className="casino-info-model ">
          <div className="row m-0">
            <div className="col-12 col-md-12 p-0">
              <div className="model-header">
                <h1>{name}</h1>
              </div>
            </div>
            <div className="col-12 col-md-12 p-0">
              <div className="model-background backgroud-filter position-relative"></div>
              <div
                className="model-background backgroud-filter position-relative"
                style={modalBackground}
                id="modal-middle-bg"
              >
                <div
                  className={`row py-4 m-0 non-blurred ${
                    gameImage.includes("https://s3-eu-west-1.amazonaws.com/")
                      ? "info-modal-background"
                      : ""
                  } `}
                >
                  <div className="col-5 col-md-5">
                    <div className="text-center">
                      <div className="pb-4">
                        <img
                          src={gameImage}
                          width="100%"
                          height="142px"
                          alt=""
                          className="border-0"
                        />
                      </div>
                      <div className="">
                        <button
                          className="card-play-btn border-gradient-1"
                          style={{ width: "231px", height: "44px" }}
                          onClick={() => handlePlaybtn()}
                        >
                          {t("Play Now")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-7 col-md-7 model-body">
                    <div className="d-flex justify-content-between ">
                      <h2>Features</h2>
                      <div className="games-rating">
                        {Array.from(Array(rating).keys()).map(() => {
                          return (
                            <>
                              <StarFilled
                                style={{ color: "yellow", padding: "2px" }}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="">
                      <table>
                        <tr>
                          <td className="td-left">Provider Name:</td>
                          <td className="td-right">{providerName}</td>
                        </tr>
                        <tr>
                          <td className="td-left">Jackpot Value:</td>
                          <td className="td-right">
                            {jackpotValue === "" ? 0 : jackpotValue}
                          </td>
                        </tr>
                      </table>
                    </div>
                    {hasDemo && (
                      <p onClick={() => handledemobtn()} className="demo-click">
                        {t("Demo")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-modal-mobile">
        <div className="casino-info-model ">
          <div className="row m-0">
            <div className="col-12 col-md-12 p-0">
              <div className="model-header">
                {Array.from(Array(rating).keys()).map(() => {
                  return (
                    <>
                      <StarFilled style={{ color: "yellow", padding: "2px" }} />
                    </>
                  );
                })}
              </div>
            </div>

            <div
              className="col-12 col-md-12 p-0 info-modal-background "
              //  style={modalBackground}
            >
              <div className="model-background backgroud-filter position-relative"></div>
              <div
                className="model-background backgroud-filter position-relative"
                style={modalBackground}
                id="modal-middle-bg"
              >
                <div className="info-modal-game-name non-blurred">
                  <h1>{name}</h1>
                </div>
                <div className="info-modal-body non-blurred">
                  <div className="row m-0 px-3">
                    <div className="col-5 col-md-5 text-center p-0">
                      <img
                        src={gameImage}
                        width="100%"
                        height="142px"
                        alt=""
                        className="border-0"
                      />
                    </div>
                    <div className="col-2 col-md-2"></div>
                    <div className="col-5 col-md-5 text-center p-0">
                      <div className="d-flex flex-column ">
                        <button
                          className="info-play-btn info-border-gradient"
                          onClick={() => handlePlaybtn()}
                        >
                          {t("Play Now")}
                        </button>
                        {hasDemo && (
                          <button
                            className="info-play-btn info-border-gradient-1"
                            onClick={() => handledemobtn()}
                          >
                            {t("Demo")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container info-table non-blurred">
                  <h3>Features</h3>
                  <div className="">
                    <table>
                      <tr>
                        <td className="td-left">Provider Name:</td>
                        <td className="td-right">{providerName}</td>
                      </tr>
                      <tr>
                        <td className="td-left">Jackpot Value:</td>
                        <td className="td-right">
                          {jackpotValue === "" ? 0 : jackpotValue}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-12  model-footer">
              <div className="container">
                <h3>Description</h3>
                <p className="m-0">aa</p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CasinoCardInfoModel;
