// import { Progress } from 'antd'
import { HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Images } from "../../Images";
import { CasinoHeader } from "./style";

const Header = ({ searchText, setSearchText }) => {
  const [connection, setConnection] = useState(null);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [win, setWin] = useState();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(
        "https://websitewebapi.oceanbet.io/basehub?PartnerId=43&Token=&LanguageId=en&TimeZone=5.5"
      )
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {})
        .catch((error) => console.log(error));
      connection.on("onWin", (data) => {
        setWin(data);
      });
    }
  }, [connection]);

  useEffect(() => {
    if (win !== undefined) {
      setdata([...data, win]);
    }
    //eslint-disable-next-line
  }, [win]);

  return (
    <>
      <div className="row m-0 casino top-win-contatiner-desktop">
        <div
          className="col-9 col-md-9 header-img"
          style={CasinoHeader}
          id="casino-header-img"
        ></div>
        <div className="col-3 col-md-3  casino-header-body">
          <div className="container ">
            <h1 className="pb-3">Top Wins</h1>
            <div className="row casino-scroll">
              <div className="">
                {data.length !== 0 ? (
                  data?.map((e, i) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-between align-content-center"
                          key={i}
                        >
                          <div className="">
                            <h2>{e.GameName}</h2>
                            <img
                              src={e.ImageUrl}
                              width="40px"
                              height="30px"
                              alt=""
                            />
                          </div>
                          <div className="">
                            <h3>ID {e.ProductId}</h3>
                            <p>
                              {e.CurrencyId} {e.Amount}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h2 className="">Data not Found</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3 col-md-3  casino-header-body top-win-container-mobile">
        <div
          className=" d-flex justify-content-between align-items-center"
          onClick={() => setOpen(!open)}
        >
          <h1 className="px-2 py-2">Top Wins </h1>
          <div className="pt-2">
            <img
              src={themeMode === "dark" ? Images.Prev : Images.downArrowGray}
              className={
                open ? "casino-game-nav-arr-up" : "casino-game-nav-arr-down"
              }
              alt="prev icon"
            />
          </div>
        </div>
        {open && (
          <>
            <div className="casino-right-hr-top-win " />
            <div className="">
              <div className="d-flex justify-content-between align-items-center py-2 mt-10">
                <h1 className="pb-1">Game</h1> <h1 className="pb-1">Player</h1>
                <h1 className="pb-1">Payout</h1>
              </div>
              <div className="casino-right-hr-top-win m-10" />
              <div className="row casino-scroll casino-scroll-mobile">
                {data.length !== 0 ? (
                  data.map((e, i) => {
                    return (
                      <>
                        <div
                          className="col-4 col-md-4 d-flex flex-column align-items-start"
                          key={i}
                        >
                          <div className="top-win-games">
                            <h2>{e.GameName}</h2>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 d-flex flex-column align-items-center ">
                          <div className="">
                            <h3>ID {e.ProductId}</h3>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 d-flex flex-column align-items-end ">
                          <div className="">
                            <h3>
                              {e.CurrencyId} {e.Amount}
                            </h3>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h2 className="">Data not Found</h2>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="casino-right-hr " />
    </>
  );
};

export default Header;
