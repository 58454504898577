import {
  HeartFilled,
  HeartOutlined,
  InfoCircleFilled,
  StarFilled
} from "@ant-design/icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import { loginModal } from "../../../Components/Login/actions";
import CasinoCardInfoModel from "../../../Components/Models/CasinoCardInfoModel";
import CasioCardGameModal from "../../../Components/Models/CasioCardGameModal";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import {
  checkLoginStatus,
  gameMarkFavourite,
  playNowGame
} from "../../Games/actions";
import "../Casino.css";

const GameCards = (props) => {
  const [infoModal, setinfoModal] = useState(false);
  const [gamemodel, setgamemodel] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const screenWidth = window.innerWidth;

  // eslint-disable-next-line
  const { gameMarkFavourite, loginModal, playNowGame, checkLoginStatus } =
    props;
  const { gameImage, name, rating, favourite, productId } = props?.data;

  let PartnerId = getItemFromLocalStorage("PartnerId");
  let ClientId = getItemFromLocalStorage("Id");
  let Token = getItemFromLocalStorage("Token");
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  const showgameUrl = useSelector(
    (state) => state?.gamesReducer?.playNowGame?.data
  );
  const markGameFavourite = async (method) => {
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: PartnerId,
        TimeZone: 5.5,
        LanguageId: LanguageId,
        Method: method,
        Controller: "Product",
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: productId,
        ClientId: ClientId,
        Token: Token,
        p: productId,
      };
      await gameMarkFavourite(payload);
      setTimeout(() => {
        props?.handleFavoruiteStatus();
      }, 1000);
    } else {
      await checkLoginStatus(true);
    }
  };

  const handlePlaybtn = async () => {
    if (Token) {
      setgamemodel(true);
      handlePlayNow();
    } else {
      await checkLoginStatus(true);
      await setinfoModal(false);
    }
  };

  const handlePlayNow = async () => {
    saveActivity({
      EventPage: "Casino",
      EventType: "GameLaunch",
      attribute2: name || "",
    });
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: PartnerId,
        TimeZone: 5.5,
        LanguageId: LanguageId,
        ProductId: productId,
        Method: "GetProductUrl",
        Controller: "Main",
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: "{}",
        IsForDemo: false,
        IsForMobile: false,
        Position: "",
        DeviceType: 1,
        ClientId: ClientId,
        Token: Token,
      };
      await playNowGame(payload);
    } else {
      await loginModal(true);
    }
  };

  const handledemobtn = async () => {
    setgamemodel(true);
    handleDemo();
  };
  const handleDemo = async () => {
    let payload = {
      Loader: true,
      TimeZone: 5.5,
      Controller: "Main",
      LanguageId: "en",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Email: null,
      Code: null,
      RequestData: "{}",
      PartnerId: 43,
      ProductId: productId,
      Method: "GetProductUrl",
      IsForDemo: true,
      IsForMobile: false,
      Position: "",
      DeviceType: 1,
    };
    await playNowGame(payload);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <div
        className="card games-cards position-relative border-0"
        onClick={() => windowSize.innerWidth < 500 && setinfoModal(true)}
      >
        <div className="">
          <img src={gameImage} className="card-img-top" alt={name} />
        </div>
        <div className="games-cards-footer">
          <div className="d-flex justify-content-between align-content-center">
            {favourite ? (
              <HeartFilled
                className="heart-size"
                onClick={() => markGameFavourite("RemoveClientFavoriteProduct")}
              />
            ) : (
              <HeartOutlined
                className="heart-size2"
                onClick={() => markGameFavourite("AddToFavoriteList")}
              />
            )}
            <h5 className=" m-0 ">
              {screenWidth > 480 ? name : name.substring(0, 5) + "..."}
            </h5>
            <div className="flex">
              <StarFilled className="text-warning" />
              <h5 className="card-text mx-1">{rating}</h5>
            </div>
          </div>
          <div className="demo-mobile d-flex justify-content-center  py-5">
            <button
              className="card-play-btn border-gradient-1"
              id="register-btn-clr"
              onClick={() => handlePlaybtn()}
            >
              {t("Play Now")}
            </button>
          </div>
          <div className="demo-text d-flex justify-content-between ">
            <p onClick={() => handledemobtn()} className="demo-click">
              {t("Demo")}
            </p>
            <div
              className="d-flex gap-1 info-model"
              onClick={() => setinfoModal(true)}
            >
              <InfoCircleFilled />
              <p>Info</p>
            </div>
          </div>
        </div>
      </div>
      <CasinoCardInfoModel
        infoModal={infoModal}
        setinfoModal={setinfoModal}
        data={props?.data}
        handlePlaybtn={handlePlaybtn}
        handledemobtn={handledemobtn}
      />
      <CasioCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        result={props?.data}
        showgameUrl={showgameUrl}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isloggedIn: state.loginReducer?.isloggedIn,
  };
};

export default connect(mapStateToProps, {
  gameMarkFavourite,
  loginModal,
  playNowGame,
  checkLoginStatus,
})(GameCards);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
