import { useContext } from "react";
import CasherNav from "../../context/context";
import RightResponsible from "./ResponsibleGame/ResponsibleGame";
import "./rightSection.css";
import RightSettings from "./Settings/RightSettings";

const RightSection = ({ editStatus, handleEditStatus, settingSelect }) => {
  const { profileUrl } = useContext(CasherNav);
  const main = () => {
    if (profileUrl === "Settings")
      return <RightSettings settingSelect={settingSelect} />;
    if (profileUrl === "Responsible Gaming")
      return (
        <RightResponsible
          editStatus={editStatus}
          handleEditStatus={handleEditStatus}
        />
      );
  };

  return (
    <div
      className={`${
        profileUrl === "Settings" ? "prof-r-hidden" : "prof-r-section"
      } text-white-color profile-right-section`}
    >
      {main()}
    </div>
  );
};

export default RightSection;
