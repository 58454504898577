import BasicLayout from "../../../OceanBetBasicLayout";
import "./Help.css";
import SupportRight from "../Support/SupportRigth/SupportRight";
import HelpSocial from "./HelpSocial/HelpSocial";

const Help = () => {
  return (
    <BasicLayout>
      <div className="main-help-container">
        <div className="help-container d-flex justify-content-between">
          <div className="help-left">
            <SupportRight />
            <div className="contact-us">
              Or go to the page: <span className="help-a">Contact Us</span>
            </div>
          </div>
          <div className="help-right">
            <div className="contact-details">Contact details</div>
            <div className="title-border-right-short"></div>
            <HelpSocial />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Help;
