import { put, takeLatest, call } from "redux-saga/effects";
import { CasherActions } from "../types";
import { getError, openNotificationWithIcon } from "../../../../utils/helper";
import CasherApi from "../api";

function* withdrawCash(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    if (response?.data?.ResponseCode === 0) {
    }
    yield put({
      type: CasherActions.WITHDRAW_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.WITHDRAW_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* depositCash(action) {
  try {
    const response = yield call(CasherApi.casherDeposit, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: CasherActions.DEPOSIT_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.DEPOSIT_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getStatusOptions(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.STATUS_OPTIONS_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.STATUS_OPTIONS_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getProductOptions(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.PRODUCT_OPTIONS_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.PRODUCT_OPTIONS_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getBetHistory(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.BET_HISTORY_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.BET_HISTORY_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getTransactionTypes(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.TRANSACTION_TYPES_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.TRANSACTION_TYPES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}
function* getTransactionHistory(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.TRANSACTION_HISTORY_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.TRANSACTION_HISTORY_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getPaymentType(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.PAYMENT_TYPE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.PAYMENT_TYPE_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getPaymentHistory(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.PAYMENT_HISTORY_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.PAYMENT_HISTORY_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* changePassword(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.CHANGE_PASSWORD_SUCCESS,
      payload: response,
    });
    if (response?.data?.ResponseCode === 0) {
    }
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.CHANGE_PASSWORD_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* newTicket(action) {
  try {
    const response = yield call(CasherApi.newTicket, action.payload);
    if (response?.data?.ResponseCode === 0) {
    }
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getTicketHistory(action) {
  try {
    const response = yield call(CasherApi.casherWithdraw, action.payload);
    yield put({
      type: CasherActions.TICKET_HISTORY_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: CasherActions.TICKET_HISTORY_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* casherSaga() {
  yield takeLatest(CasherActions.WITHDRAW, withdrawCash);
  yield takeLatest(CasherActions.DEPOSIT, depositCash);
  yield takeLatest(CasherActions.STATUS_OPTIONS, getStatusOptions);
  yield takeLatest(CasherActions.BET_HISTORY, getBetHistory);
  yield takeLatest(CasherActions.PRODUCT_OPTIONS, getProductOptions);
  yield takeLatest(CasherActions.TRANSACTION_TYPES, getTransactionTypes);
  yield takeLatest(CasherActions.TRANSACTION_HISTORY, getTransactionHistory);
  yield takeLatest(CasherActions.PAYMENT_TYPE, getPaymentType);
  yield takeLatest(CasherActions.PAYMENT_HISTORY, getPaymentHistory);
  yield takeLatest(CasherActions.CHANGE_PASSWORD, changePassword);
  yield takeLatest(CasherActions.NEW_TICKET, newTicket);
  yield takeLatest(CasherActions.TICKET_HISTORY, getTicketHistory);

}

