import { Form, Input, Modal } from "antd";

const AddAccount = (props) => {
  const { addAccount, setAddAccount } = props;

  return (
    <Modal
      visible={addAccount}
      footer={null}
      width="700px"
      destroyOnClose
      className=" login "
      onCancel={() => setAddAccount(false)}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      >
      <div className="row m-0 mail-modal">
        <div className="col-12 col-md-12 mail-modal-header ">
          <h1>Add Account</h1>
        </div>
        <div className="col-12 col-md-12 mail-body ">
          <div className="container">
            <div className=" row py-4">
              <Form name="login" autoComplete="off">
                <div className="col-12 col-md-12">
                  <h2>ID</h2>
                  <Form.Item name="id">
                    <Input placeholder="Text" data-e2e="id-field" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <h2>Nick Name</h2>
                  <Form.Item name="nick_name">
                    <Input placeholder="Text" data-e2e="nick-name" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <h2>Coin</h2>
                  <Form.Item name="coin">
                    <Input placeholder="Text" data-e2e="coin-text-box" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <h2>Token</h2>
                  <Form.Item name="token">
                    <Input placeholder="Text" data-e2e="token-text-field" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <h2>Address</h2>
                  <Form.Item name="address">
                    <Input placeholder="Text" data-e2e="address-text-field" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-12">
                  <Form.Item>
                    <button className="w-100 border-0" data-e2e="add-btn">ADD</button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAccount;
