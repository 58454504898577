import BasicLayout from "../../../OceanBetBasicLayout";
import { links, terms } from "../lib/constants";
import "./termsCondition.css";

const TermsCondition = () => {
  return (
    <BasicLayout>
      <div className="term-main-container">
        <div className="d-flex flex-column w-100">
          <div className="term-title">Terms of Service</div>
          <div className="term-links">
            <div>
              {
                // eslint-disable-next-line
                links?.map((link, i) => {
                  if (i < 20)
                    return (
                      <div key={i}>
                        {i + 1}.{" "}
                        <a href={"#" + i} className="term-li">
                          {link}
                        </a>
                      </div>
                    );
                })
              }
            </div>
            <div>
              {
                // eslint-disable-next-line
                links.map((link, i) => {
                  if (i >= 20)
                    return (
                      <div key={i}>
                        {i + 1}.{" "}
                        <a href={i} className="term-li">
                          {link}
                        </a>
                      </div>
                    );
                })
              }
            </div>
          </div>
          <div className="term-terms">
            {
              // eslint-disable-next-line
              terms.map((term, i) => {
                if (i < 2)
                  return (
                    <div id={i}>
                      <div className={`term-terms-title ${i > 0 && "mt-5"}`}>
                        {i + 1 + ") "}
                        {term.title}
                      </div>
                      {term.expl.map((e, j) => (
                        <div className="d-flex align-items-baseline gap-4 mb-3">
                          <div>{j + 1}.</div>
                          <div>{e}</div>
                        </div>
                      ))}
                    </div>
                  );
                if (i === 2)
                  return (
                    <div id={i}>
                      <div className="term-terms-title mt-5">
                        {i + 1 + ") "}
                        {term.title}
                      </div>
                      {term.expl.map((e, j) => (
                        <div className="mb-3">
                          <div>{e}</div>
                        </div>
                      ))}
                    </div>
                  );
              })
            }
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default TermsCondition;