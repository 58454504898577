import { useContext } from "react";
import CasherNav from "../../context/context";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import "./rightSection.css";
import Withdraw from "./WithdrawInfo/Withdraw";

const RightSection = () => {
  const { finUrl } = useContext(CasherNav);

  const main = () => {
    if (finUrl === "Deposit") return <PaymentInfo />;
    if (finUrl === "Withdraw") return <Withdraw />;
  };

  return <div className="right-section text-white-color">{main()}</div>;
};

export default RightSection;
