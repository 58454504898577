import { Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import React from "react";

const { Option } = Select;

const TransectionHistory = ({
  settransectionSelectValue,
  transectionSelectValue,
  TypeOption,
}) => {
  const handleDateChange = (e) => {
    let copyobj = { ...e };
    if (e.value === "24 hours") {
      copyobj.value = "1";
    } else if (e.value === "1 month") {
      copyobj.value = "30";
    }
    settransectionSelectValue({
      ...transectionSelectValue,
      time: e.value,
      date: moment(new Date(Date.now() + 24 * 60 * 60 * 1000)).format(
        "YYYY-MM-DD hh:mm"
      ),
      fromDate: moment(
        new Date(Date.now() - copyobj.value.split(" ")[0] * 24 * 60 * 60 * 1000)
      ).format("YYYY-MM-DD hh:mm"),
    });
  };

  return (
    <div className="d-flex px-4 betHistory-container">
      <div className="d-flex flex-column">
        <label className="select-label">Period of time</label>
        <Select
          className="custom-select"
          defaultValue={{
            value: transectionSelectValue.time,
          }}
          onChange={(e) => handleDateChange(e)}
          labelInValue
        >
          <Option value="24 hours">{t("24 hours")}</Option>
          <Option value="3 days">{t("3 days")}</Option>
          <Option value="7 days">{t("7 days")}</Option>
          <Option value="1 month">{t("1 month")}</Option>
        </Select>
      </div>
      <div className="d-flex flex-column">
        <label className="select-label"> Type</label>
        <Select
          className="custom-select"
          placeholder="Select Type"
          labelInValue
          defaultValue={{
            value: "all",
            label: "All",
          }}
          onChange={(e) =>
            settransectionSelectValue({
              ...transectionSelectValue,
              type: e.value,
            })
          }
        >
          <Option value="all">All</Option>
          {TypeOption &&
            TypeOption?.map((e, i) => {
              return <Option value={e.Id}>{e.Name}</Option>;
            })}
        </Select>
      </div>
    </div>
  );
};

export default TransectionHistory;
