import { put, takeLatest, call } from "redux-saga/effects";
import { HomeActions } from "../types";
import HomeApi from "../api";
import { getError, openNotificationWithIcon } from "../../../../utils/helper";

function* getGameURL(action) {
  try {
    const response = yield call(HomeApi.getGames, action.payload);
    if (response?.data && response?.data?.ResponseCode === 0) {
    }
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    yield put({
      type: HomeActions.GET_TOP_GAMES_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in gameurl saga :: ", error);
    const customizeError = getError(error);
    yield put({ type: HomeActions.GET_TOP_GAMES_ERROR, payload: error });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

export function* HomeSaga() {
  yield takeLatest(HomeActions.GET_TOP_GAMES, getGameURL);
}
