import { Images } from "../../../../Images";
import { contactSupport } from "../../lib/constants";
import "./HelpSocial.css";

const HelpSocial = () => {
  const {
    blackPhoneIcon,
    blackEmailIcon,
    blackAddressIcon,
  } = Images;

  return (
    <div className="contactSocial-container d-flex flex-column">
      <div className="">
        <div className="contact-help-title">Oceanbet casino</div>
        <div className="contact-help-container">
          {contactSupport.map((cont, i) => {
            return (
              <div className="contact-help d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <img
                    className="blackIcon-img"
                    src={
                      cont.title === "Phone"
                        ? blackPhoneIcon
                        : cont.title === "Email"
                        ? blackEmailIcon
                        : blackAddressIcon
                    }
                    alt=""
                  />
                  <div className="cont-title">{cont.title}</div>
                </div>
                <div className="cont-text-help">{cont.text}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="contactSocial-border"></div>
      {/* <div className="social-help">
        <div className="additional-info">Additional Information</div>
        <div className="contact-sup-subtitle">
          You can also contact us on social networks
        </div>
        <div className="social-container d-flex">
          <div className="">
            <img className="blue-icon-img" src={bueTelegramIcon} alt="" />
            <div className="social-name">Telegram</div>
          </div>
          <div className="">
            <img className="blue-icon-img" src={bueDiscordIcon} alt="" />
            <div className="social-name">Discord</div>
          </div>
          <div className="">
            <img className="blue-icon-img" src={bueTwitterIcon} alt="" />
            <div className="social-name">Twitter</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HelpSocial;
