import { Col, Row } from "antd";
import browser from "browser-detect";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveActivity } from "../../Components/ActivityTracking/activityTrackingService";
import GamesCard from "../../Components/Common/GamesCard";
import { getDeviceIP } from "../../Components/Common/getIp";
import { Images } from "../../Images";
import BasicLayout from "../../OceanBetBasicLayout";
import { replaceGamesKeysNames } from "../../utils/helper";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { games } from "./actions/index";
import "./style.css";
const browserInfo = browser();

const Games = (props) => {
  const { games, gamesData } = props;
  let allGames = gamesData?.Games;
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const Theme = getItemFromLocalStorage("Theme");

  const Background = {
    background: `url(${
      Theme === "dark" ? Images.Homebackgrounddark : Images.Homebackgroundlight
    }) no-repeat`,
  };
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: 100,
      WithWidget: false,
      CategoryId: 5,
      ProviderIds: null,
      IsForMobile: false,
      Name: "",
      LanguageId: LanguageId,
    };
    await games(payload);
  };
  useEffect(() => {
    getGamesApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let path = window?.location?.pathname;
    let pageName = path?.split("/")[2] || "";
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: pageName,
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "GetGames",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicLayout>
      <div className="gamesBannerImage-container">
        <img className="gamesBannerImage" src={Images.GamesBanner} alt="" />
      </div>
      <div className="games-card-container">
        <Row
          gutter={[24, 32]}
          className="p-10 gamesRow"
          style={windowSize.innerWidth < 550 ? Background : {}}
        >
          {allGames?.map((game) => {
            let result = replaceGamesKeysNames(game);
            return (
              <Col className="gutter-row gamesCol" key={game?.p}>
                <GamesCard result={result} />
              </Col>
            );
          })}
        </Row>
      </div>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.gamesReducer?.gamesData,
  };
};

export default connect(mapStateToProps, {
  games,
})(Games);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
