import { Images } from "../../Images";
export const topDailyCard = {
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
  gap: "25px",
  paddingTop: "50px",
  justifyContent: "center",
  alignItems: "center",
};
export const topDailyCardContent = {
  bottom: "8px",
  left: "16px",
  zIndex: 10,
  marginTop: "-6em",
  marginLeft: "1em",
};
export const HomeBanner = {
  background: `url(${Images.HomeBanner}) no-repeat`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};
export const SubscribeBackground = {
  background: `url(${Images.subscribe1}) no-repeat`,
};
