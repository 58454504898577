import { t } from "i18next";
import React, { useContext } from "react";
import CasherNav from "../../context/context";
import { profileNavEl } from "../../lib/constants";
import Nav from "../../_sharedComponents/SubNav/Nav";
import ResponsibleGame from "./ResponsibleGame/ResponsibleGame";
import SettingsMain from "./Settings/SettingsMain";
const MainSection = (
  {
    editStatus,
    handleEditStatus,
    settingSelect,
    setSettingSelect,
    navMobile,
    setNavMobile,
  },
  props
) => {
  const { profileUrl, setProfileUrl } = useContext(CasherNav);

  const main = () => {
    if (profileUrl === "Settings")
      return (
        <SettingsMain
          editStatus={editStatus}
          handleEditStatus={handleEditStatus}
          settingSelect={settingSelect}
        />
      );
    if (profileUrl === "Responsible Gaming")
      return (
        <ResponsibleGame
          editStatus={editStatus}
          handleEditStatus={handleEditStatus}
        />
      );
  };

  return (
    <div className="profile-main-section d-flex flex-column">
      <div className="d-flex flex-column gap-2">
        <Nav
          navEl={profileNavEl}
          url={profileUrl}
          setUrl={setProfileUrl}
          navMobile={navMobile}
          setNavMobile={setNavMobile}
        />
        <div className="d-flex flex-column gap-4 main-section-shadow">
          <div className="title-border"></div>
          <div className="flex align-items-center justify-content-between padding-left">
            {profileUrl === "Settings" ? (
              <div className="flex gap-5 mt-3 settingSelect">
                <div
                  onClick={() => setSettingSelect(true)}
                  className={`${
                    settingSelect ? "text-white-color" : " text-gray"
                  }`}
                >
                  {t("PersonalDetails")}
                </div>
                <div
                  onClick={() => setSettingSelect(false)}
                  className={`${
                    !settingSelect ? "text-white-color" : " text-gray"
                  } mobile-block`}
                >
                  {t("ChangePassword")}
                </div>
              </div>
            ) : (
              <div className="mobile-mt">{t("Limitation")}</div>
            )}
            {/* <div
              className="flex align-items-center gap-2 cursor-pointer mobile-none"
              onClick={() => handleEditStatus()}
            >
              <img src={edit} alt="" />
              <div className="edit">{t("Edit")}</div>
            </div> */}
          </div>
          <div className="mobile-border"></div>
          <div className="title-border"></div>
        </div>
      </div>
      {main()}
    </div>
  );
};

export default MainSection;
