import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../../Images/index";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage
} from "../../../utils/localStorage";

const Step1 = ({ registerPayload, setRegisterPayload, setFormSteps }) => {
  const [form] = Form.useForm();
  const themeMode = getItemFromLocalStorage("Theme") || "";
  const { t } = useTranslation();
  const [type, setType] = useState("text");
  const [dateCheck, setDateCheck] = useState("");
  const onFinish = async (values) => {
    let dob = values?.dob.split("-");
    let obj = {
      UserName: values.UserName,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      dob: values.dob,
    };
    setRegisterPayload({
      ...registerPayload,
      UserName: values.UserName,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      BirthDay: dob[2],
      BirthYear: dob[0],
      BirthMonth: dob[1],
    });
    addItemToLocalStorage("step1", obj);
    setFormSteps(2);
  };

  let step1Data = getItemFromLocalStorage("step1");

  return (
    <>
      <div className="row">
        <Form
          name="register"
          initialValues={{
            UserName: step1Data?.UserName,
            FirstName: step1Data?.FirstName,
            LastName: step1Data?.LastName,
            Email: step1Data?.Email,
            dob: step1Data?.dob,
          }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          // onValuesChange={() =>
          //   changeTheme(getItemFromLocalStorage("Theme") || "")
          // }
        >
          <div className="col-12">
            <div className="row ">
              <div className="name-input col-6">
                <Form.Item
                  name="FirstName"
                  rules={[
                    {
                      required: true,
                      message: "First name required",
                    },
                  ]}
                >
                  <Input
                    placeholder="First name"
                    data-e2e="first-name"
                    className={`${
                      themeMode === "light"
                        ? "white-content-bg"
                        : "login-body-input"
                    }`}
                    prefix={<img src={Images.user} alt="" />}
                  />
                </Form.Item>
              </div>
              <div className="name-input col-6">
                <Form.Item
                  name="LastName"
                  rules={[
                    {
                      required: true,
                      message: "last name required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last name"
                    data-e2e="last-name"
                    className={`${
                      themeMode === "light"
                        ? "white-content-bg"
                        : "login-body-input"
                    }`}
                    prefix={<img src={Images.user} alt="" />}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Form.Item
              name="UserName"
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
            >
              <Input
                placeholder="Username"
                data-e2e="user-name"
                className={`${
                  themeMode === "light"
                    ? "white-content-bg"
                    : "login-body-input"
                }`}
                prefix={<img src={Images.user} alt="" />}
              />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              name="Email"
              rules={[
                {
                  required: true,
                  message: t("Email-placeholder"),
                },
              ]}
            >
              <Input
                placeholder={t("Email")}
                data-e2e="email"
                className={`${
                  themeMode === "light"
                    ? "white-content-bg"
                    : "login-body-input"
                }`}
                prefix={<img src={Images.mail} alt="" />}
              />
            </Form.Item>
          </div>
          <div
            className={`col-12 ${
              themeMode === "light" ? "input-cal-dark" : "input-cal-light"
            }`}
          >
            <Form.Item
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please enter your DOB!",
                },
              ]}
            >
              <Input
              data-e2e="date-of-birth"
                placeholder={type === "date" ? "" : "Date Of Birth"}
                type={type}
                onChange={(e) => setDateCheck(e.target.value)}
                onFocus={() => {
                  setType("date");
                }}
                onBlur={() => {
                  dateCheck === "" ? setType("text") : setType("date");
                }}
                id="session-date"
                className={`${
                  themeMode === "light"
                    ? "white-content-bg"
                    : "login-body-input"
                }`}
                prefix={<img src={Images.calendar} alt="" />}
              />
            </Form.Item>
          </div>

          <button className="w-100">
            <span data-e2e={t("Next")}>{t("Next")}</span>
          </button>
        </Form>
      </div>
    </>
  );
};

export default Step1;
