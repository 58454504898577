import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { responsibleGamesData } from "../lib/constants";
import "./termsCondition.css";

const ResponsibleGames = () => {
  return (
    <CommonFooterTermsAndConditions
      title={responsibleGamesData[0]?.title}
      data={responsibleGamesData}
    />
  );
};

export default ResponsibleGames;
