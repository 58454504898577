import browser from "browser-detect";
import { t } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { formsEl } from "../../../lib/constants";
import Forms from "../../DateForm/DateForm";
import {
  sendVerificationCode,
  updateProfileInfo
} from "../../Services/actions";
import "./settingsMain.css";
const browserInfo = browser();

const SettingsMain = ({ settingSelect }) => {
  const dispatch = useDispatch();
  const { formElDays, formElMonths, formElYears } = formsEl;
  const LanguageId =
    getItemFromLocalStorage("i18nextLng") === "en-GB"
      ? "en"
      : getItemFromLocalStorage("i18nextLng");

  let userData = getItemFromLocalStorage("userData");
  let DD = moment(userData?.BirthDate).format("DD");
  let YY = moment(userData?.BirthDate).format("YYYY");
  let MM = moment(userData?.BirthDate).format("MMMM");

  const [date, setDate] = useState(DD);
  const [month, setMonth] = useState(MM);
  const [year, setYear] = useState(YY);

  const [profileData, setProfileData] = useState({
    mobileNo: userData?.MobileNumber || "",
    email: userData?.Email || "",
    fName: userData?.FirstName || "",
    lName: userData?.LastName || "",
    address: userData?.Address || "",
    currency: userData?.CurrencyId || "",
    documentNo: userData?.DocumentNumber || "",
    documentIssuedBy: userData?.DocumentIssuedBy || "",
  });

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSendVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: userData?.PartnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToMobileNumber",
      RequestData: JSON.stringify({
        MobileNumber: profileData?.mobileNo,
      }),
      TimeZone: 5.5,
    };
    dispatch(sendVerificationCode(payload));
  };

  const handleUpdate = async () => {
    let payload = {
      Loader: true,
      PartnerId: userData?.PartnerId,
      TimeZone: userData?.TimeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "ChangeClientDetails",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: profileData?.mobileNo,
      Code: null,
      RequestData: JSON.stringify({
        ClientId: userData?.Id,
        Email: profileData?.email,
        IsEmailVerified: userData?.IsEmailVerified,
        CurrencyId: profileData?.currency,
        BirthDate: moment(`${date}-${month}-${year}`).format("YYYY-MM-DD"),
        FirstName: profileData?.fName,
        LastName: profileData?.lName,
        DocumentNumber: profileData?.documentNo,
        DocumentIssuedBy: profileData?.documentIssuedBy,
        Info: null,
        Address: profileData?.address,
        MobileNumber: profileData?.mobileNo,
        IsMobileNumberVerified: userData?.IsMobileNumberVerified,
        LanguageId: LanguageId,
        IsDocumentVerified: userData?.IsDocumentVerified,
        ZipCode: userData?.ZipCode,
        EmailOrMobile: profileData?.email,
        Gender: userData?.Gender,
        CountryId: userData?.CountryId,
        RegionId: userData?.RegionId,
        Citizenship: userData?.Citizenship,
      }),
      ClientId: userData?.Id,
      Token: userData?.Token,
    };
    dispatch(updateProfileInfo(payload));
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Profile_SaveChanges",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };

  return (
    <div
      className={`${
        !settingSelect ? "none" : "set-main d-flex flex-column gap-3"
      } `}
    >
      <div className="d-flex align-items-center justify-items-center gap-4 set-main-col">
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("FirstName")}</div>
          <input
            className="profile-input fontSize-12"
            type="text"
            placeholder={t("Firstname-placeholder")}
            data-e2e="first-name"
            name="fName"
            value={profileData.fName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("LastName")}</div>
          <input
            className="profile-input fontSize-12"
            type="text"
            placeholder={t("Lastname-placeholder")}
            data-e2e="last-name"
            name="lName"
            value={profileData.lName}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-items-center gap-4 set-main-col">
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">Date of birth</div>
          <div className="d-flex align-items-center gap-2 w-100">
            <Forms
              formEl={formElDays}
              D={DD}
              editStatus={true}
              selectedDate={setDate}
            />
            <Forms
              formEl={formElMonths}
              D={MM}
              editStatus={true}
              selectedDate={setMonth}
            />
            <Forms
              formEl={formElYears}
              D={YY}
              editStatus={true}
              selectedDate={setYear}
            />
          </div>
        </div>
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Address")}</div>
          <input
            className="profile-input fontSize-12"
            placeholder={t("Address-placeholder")}
            data-e2e="address"
            type="text"
            name="address"
            value={profileData.address}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-items-center gap-4 set-main-col">
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Email-verify")}</div>
          <div className="set-inp-btn-box d-flex align-items-center justify-content-between">
            <input
              className="set-box-input fontSize-12"
              data-e2e="email"
              type="email"
              name="email"
              placeholder={t("Email-placeholder")}
              value={profileData.email}
              onChange={(e) => handleChange(e)}
            />
            <div className="sett-send-btn fontSize-12" id="register-btn-clr" data-e2e={t("Verify")}>
              {t("Verify")}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Mobile-number-verify")}</div>
          <div className="set-inp-btn-box d-flex align-items-center justify-content-between">
            <input
              className="set-box-input fontSize-12"
              type="text"
              placeholder={t("Mobile-number-placeholder")}
              value={profileData?.mobileNo}
              name="mobileNo"
              onChange={(e) => handleChange(e)}
              data-e2e="mobile-number"
            />
            <div
              className="sett-send-btn cursor-pointer fontSize-12"
              id="register-btn-clr"
              onClick={() => handleSendVerification()}
              data-e2e={t("Send")}
            >
              {t("Send")}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-end justify-items-center gap-4 set-main-col">
        <div className="d-flex flex-column set-main-half gap-2">
          <div className="fontSize-12">{t("Document number")}</div>
          <input
            className="profile-input fontSize-12"
            placeholder={t("Document number")}
            name="documentNo"
            data-e2e="document-number"
            value={profileData.documentNo}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="d-flex flex-column set-main-half gap-">
          <div className="fontSize-12">{t("Document issued by")}</div>
          <input
            className="profile-input fontSize-12"
            placeholder="Text"
            name="documentIssuedBy"
            value={profileData.documentIssuedBy}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="fontSize-12" data-e2e={t("Currency")}>{t("Currency")}</div>
        <input
          className="profile-input fontSize-12"
          placeholder="Eur"
          value={profileData?.currency}
          name={t("Currency")}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="d-flex align-items-end justify-items-center gap-4">
        <div
          className="set-save-btn fontSize-12"
          id="register-btn-clr"
          onClick={() => handleUpdate()}
          data-e2e={t("Save-Changes")}
        >
          {t("Save-Changes")}
        </div>
        <div className="set-canc-btn-border">
          <div
            className="set-canc-btn fontSize-12 mobile-none"
            id="register-btn-clr"
            data-e2e={t("Cancel-changes")}
          >
            {t("Cancel-changes")}
          </div>
          <div className="set-canc-btn set-canc-btn-mobile fontSize-12 mobile-block">
            {t("X")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsMain;
