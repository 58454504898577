import { Link } from "react-router-dom";
import { Images } from "../../../Images";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { currency_symbols, loggedInMenu } from "../constantNavBar";
import "./MobileLogged.css";

const MobileLogged = ({
  mobLogged,
  setMobLogged,
  HandleLogout,
  AvailableBalance,
}) => {
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  return (
    <div
      className={`${
        mobLogged ? "ModalloginOpen" : "d-none"
      } mobileLogged-main d-flex align-items-end flex-column`}
    >
      <div className="mobileLogged-container">
        <div className="mobileLogged-header d-flex justify-content-center">
          <div className="mobileLogged-pathImg-container d-flex justify-content-center align-items-center">
            <img
              className="mobileLogged-pathImg rotate_90-right"
              src={Images.downArrowGray}
              alt="close-modal"
              width="15px"
              onClick={() => setMobLogged(false)}
              height="15px"
            />
          </div>
          <div className="mobileLogged-client-container">
            <div className="mobileLogged-client">Client Unused Balance</div>
            <div className="mobileLogged-client-balance">
              {AvailableBalance || 0}
              {currency_symbols[CurrencyId] || "€"}
            </div>
          </div>
        </div>
        <div className="sideBar-border sideBar-border-mobile"></div>
        <div>
          <div className="mobileLogged-menu-container">
            {loggedInMenu?.map((list, i) => {
              return (
                <Link
                  key={i}
                  to={list.url}
                  className="navbar-rightside-section-mobile"
                >
                  <div className="sideBar-menu d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        className="sideBar-menu-img mobile-finance-sidebar-img"
                        src={list.icon}
                        alt="close-modal-img"
                      />
                      <div className="sideBar-menu-content loggedIn-listName">
                        {list.name}
                      </div>
                    </div>
                    <div>
                      <img
                        src={Images.downArrowGray}
                        alt="arrow to right"
                        className="rotate_90-right"
                      />
                    </div>
                  </div>
                </Link>
              );
            })}
            <div
              className="navbar-rightside-section-mobile"
              onClick={() => HandleLogout()}
            >
              <div className="sideBar-menu d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    className="sideBar-menu-img mobile-finance-sidebar-img"
                    src={Images.logOutIcon}
                    alt="close-modal-img"
                  />
                  <div className="sideBar-menu-content loggedIn-listName">
                    Logout
                  </div>
                </div>
                <div>
                  <img
                    src={Images.downArrowGray}
                    alt="arrow to right"
                    className="rotate_90-right"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mobile-footer-sidebar">
            <div className="sideBar-border sideBar-border-mobile-footer"></div>
            <div
              onClick={() => HandleLogout()}
              className="mobileLogged-footer d-flex align-items-center"
            >
              <img
                src={Images.logOutIcon}
                alt="logout"
                className="mobile-finance-sidebar-img"
              />
              <div className="mobileLogged-logOut">{logOutFooter}</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default MobileLogged;
