import { Images } from "../../../Images";
import routes from "../../../Route/URLs";
import { getItemFromLocalStorage } from "../../../utils/localStorage";

const {
  luxon,
  crypto,
  netellerCa,
  skrillCa,
  luxonIn,
  cryptoIn,
  netellerCaIn,
  skrillCaIn,
  finances,
  history,
  bonuses,
  profile,
  ticket,
  balance,
  financesIn,
  historyIn,
  bonusesIn,
  profileIn,
  ticketIn,
  balanceIn,
  american,
  americanIn,
  visaCa,
  visaCaIn,
  nowPay,
  nowPayIn,
  luxonlight,
  skrillCalight,
  netellerCalight,
  cryptolight,
} = Images;

const theme = getItemFromLocalStorage("Theme");

export const formsEl = {
  formElStatus: [
    { label: "Status", option: ["Select Status", "Option2", "Option3"] },
  ],
  formsElLimit: [{ label: "", option: ["Total Deposit"] }],
  formsElExcl: [{ label: "", option: ["Temporary"] }],
  formElDays: [
    {
      option: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
    },
  ],
  formElMonths: [
    {
      option: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Septembre",
        "October",
        "November",
        "December",
      ],
    },
  ],
  formElYears: [
    {
      option: [
        "1980",
        "1981",
        "1982",
        "1983",
        "1984",
        "1985",
        "1986",
        "1985",
        "1986",
        "1987",
        "1988",
        "1989",
        "1990",
        "1991",
        "1992",
        "1993",
        "1994",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
    },
  ],
  formElPhone: [
    {
      img: Images.en,
      value: "+44",
    },
    {
      img: Images.fr,
      value: "+39",
    },
  ],
};

export const financesNavEl = ["Deposit", "Withdraw", "Bank_Accounts"];

export const navEl = [
  {
    img: finances,
    imgIn: financesIn,
    title: "Finances",
    route: routes.CASHER_FINANCES,
  },
  {
    img: history,
    imgIn: historyIn,
    title: "History",
    route: routes.CASHER_HISTORY,
  },
  {
    img: bonuses,
    imgIn: bonusesIn,
    title: "Bonuses",
    route: routes.CASHER_BONUSES,
  },
  {
    img: profile,
    imgIn: profileIn,
    title: "Profile",
    route: routes.CASHER_PROFILE,
  },
  {
    img: ticket,
    imgIn: ticketIn,
    title: "Ticket",
    route: routes.CASHER_TICKET,
  },
  {
    img: balance,
    imgIn: balanceIn,
    title: "Balance",
    route: routes.CASHER_BALANCE,
  },
];

export const historyNavEl = ["Bets", "Transactions", "Payments"];
export const bonusesNavEl = ["Bonuses"];
export const accountsEl = [
  "Id",
  "NickName",
  "User_BankName",
  "Iban",
  "Address",
];
export const ticketNavEl = ["My_tickets"];
export const profileNavEl = [
  "Settings",
  "AccountVerification",
  "Responsible Gaming",
];

export const balanceNavEl = ["Balance"];

export const verificationList = [
  "DocumentFormatMessage",
  "DocumentValidityPeriod",
  "DocumentValidityColor",
  "DocumentValidityOriginal",
  "DocumentValiditySignature",
  "DocumentValidityPresent",
];

export const currencyId = (type, partnerPayment, formEl, setFormEl) => {
  const options = [];
  partnerPayment
    // eslint-disable-next-line
    .filter((pay) => {
      if (pay.Type === type) {
        return pay;
      }
    })
    // eslint-disable-next-line
    .map((pay) => {
      if (!options.includes(pay.CurrencyId)) {
        options.push(pay.CurrencyId);
      }
    });

  setFormEl({ ...formEl, option: options });
};

export const currencyToSymbol = (currency) => {
  if (currency === "AUD") return "A$";
  if (currency === "CAD") return "C$";
  if (currency === "EUR") return "€";
  if (currency === "GBP") return "£";
  if (currency === "USD") return "$";
};

export const addPoint = (amount) => {
  if (amount / 1000 >= 1) {
    // eslint-disable-next-line
    return amount / 1000 + "." + "000";
  }
};

export const chooseImg = (name, sel, i) => {
  if (name === "Skrill" && sel === i) {
    return theme === "light" ? skrillCalight : skrillCa;
  } else if (name === "Skrill") return skrillCaIn;

  if (name === "Neteller" && sel === i) {
    return theme === "light" ? netellerCalight : netellerCa;
  } else if (name === "Neteller") return netellerCaIn;

  if (name === "Skrill" && sel === i) {
    return theme === "light" ? skrillCalight : skrillCa;
  } else if (name === "Skrill") return skrillCaIn;

  if (name === "NOWPay" && sel === i) {
    return nowPay;
  } else if (name === "NOWPay") return nowPayIn;

  if (name.includes("PaymentIQL") && sel === i) {
    return theme === "light" ? luxonlight : luxon;
  } else if (name.includes("PaymentIQL")) return luxonIn;

  if (name.includes("PaymentIQC") && sel === i) {
    return theme === "light" ? cryptolight : crypto;
  } else if (name.includes("PaymentIQC")) return cryptoIn;

  if (name.includes("MoneyPayAmerican") && sel === i) {
    return american;
  } else if (name.includes("MoneyPayAmerican")) return americanIn;

  if (name.includes("MoneyPayVisa") && sel === i) {
    return visaCa;
  } else if (name.includes("MoneyPayVisa")) return visaCaIn;
};

export const cardName = (PaymentSystemName) => {
  if (PaymentSystemName?.includes("PaymentIQ")) {
    return PaymentSystemName?.substring(9);
  } else if (PaymentSystemName?.includes("MoneyPayVisa")) {
    return (
      PaymentSystemName?.substring(8, 12) +
      "/" +
      PaymentSystemName?.substring(12)
    );
  } else if (PaymentSystemName?.includes("MoneyPayAmerican")) {
    return (
      PaymentSystemName?.substring(8, 16) +
      " " +
      PaymentSystemName?.substring(16)
    );
  } else if (
    PaymentSystemName === "Neteller" ||
    PaymentSystemName === "Skrill"
  ) {
    return "Please contact your VIP Manager";
  } else {
    return PaymentSystemName;
  }
};
