import { Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { formsEl } from "../../../lib/constants";
import Forms from "../../DateForm/DateForm";

const RightResponsible = ({ editStatus }) => {
  const { formElDays, formElMonths, formElYears } = formsEl;
  const { Option } = Select;

  let DD = moment(new Date()).format("DD");
  let YY = moment(new Date()).format("YYYY");
  let MM = moment(new Date()).format("MMMM");

  const [date, setDate] = useState(DD);
  const [month, setMonth] = useState(MM);
  const [year, setYear] = useState(YY);
  console.log(date, month, year);

  return (
    <div className="d-flex flex-column align-items-center resp-game-main">
      <div className="align-self-end d-flex align-items-center r-nav r-resp"></div>
      <div className="self-exclusion">
        <div className="align-self-start title-border"></div>
        <div className="p-4 prof-r-title">{t("Self-Exclusion")}</div>
        <div className="align-self-start title-border"></div>
      </div>
      <div className="self-exclusion-mobile">
        <div className="mx-3">Self-Exclusion</div>
        <div className="mobile-border mb-2"></div>
      </div>
      <div className="set-main d-flex flex-column items-center gap-4 w-100">
        <div className="d-flex flex-column set-main-half gap-2 w-100">
          <div className="fontSize-12">{t("Type of Exclusion")}</div>
          <Select
            className="amount-input with-input-w fontSize-12"
            defaultValue="select"
          >
            <Option value="USDT">Temporary</Option>
          </Select>
        </div>
        <div className="d-flex flex-column set-main-half gap-2 w-100">
          <div className="fontSize-12">{t("Until Date")}</div>
          <div className="d-flex align-items-center gap-2 w-100">
            <Forms
              formEl={formElDays}
              D={DD}
              editStatus={editStatus}
              selectedDate={setDate}
            />
            <Forms
              formEl={formElMonths}
              editStatus={editStatus}
              D={MM}
              selectedDate={setMonth}
            />
            <Forms
              formEl={formElYears}
              editStatus={editStatus}
              D={YY}
              selectedDate={setYear}
            />
          </div>
        </div>
        <div className="set-change-btn r-mt-120 fontSize-12" id="register-btn-clr">
          {t("Confirm")}
        </div>
      </div>
    </div>
  );
};

export default RightResponsible;
