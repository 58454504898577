import { Checkbox, Form, Select } from "antd";
import React, { useState } from "react";
import { Images } from "../../../Images/index";

import { Input } from "antd";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import {
  addItemToLocalStorage,
  getItemFromLocalStorage
} from "../../../utils/localStorage";
import { registerModal, signup } from "../../Login/actions";

const { Option } = Select;

const Step3 = ({
  registerPayload,
  setregisterModal,
  setFormSteps,
  setRegisterPayload,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const themeMode = getItemFromLocalStorage("Theme") || "";

  const onFinish = async (values) => {
    if (values?.Password !== values.confirmPassword) {
      return setErrMsg("The password confirmation does not match.");
    } else if (!termsAndConditions) {
      return setErrMsg("Please accpet Terms & Conditions!");
    } else {
      setErrMsg("");
      let obj = {
        CurrencyId: values.CurrencyId,
        Password: values.Password,
        confirmPassword: values.confirmPassword,
        AcceptTerms: true,
        TermsConditionsAccepted: true,
        LanguageId: "en",
        TimeZone: "5.5",
        AffiliatePlatformId: "",
        PromoCode: values.PromoCode,
      };
      addItemToLocalStorage("step3", obj);
      await dispatch(signup({ ...registerPayload, ...obj }));
      dispatch(registerModal(true))
    }
  };

  let step3Data = getItemFromLocalStorage("step3");

  return (
    <>
        <div className="row register-body">
          <Form
            name="register"
            initialValues={{
              CurrencyId: step3Data?.CurrencyId,
              Password: step3Data?.Password,
              confirmPassword: step3Data?.confirmPassword,
              AcceptTerms: true,
              TermsConditionsAccepted: true,
              LanguageId: "en",
              TimeZone: "5.5",
              AffiliatePlatformId: "",
              PromoCode: step3Data?.PromoCode,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            // onValuesChange={() =>
            //   changeTheme(getItemFromLocalStorage("Theme") || "")
            // }
          >
            <div className="col-12  position-relative">
              <Form.Item
                name="CurrencyId"
                rules={[
                  {
                    required: true,
                    message: "Please select your Currency!",
                  },
                ]}
              >
                <Select
                  className={`amount-input-currency ${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  placeholder="Currency"
                >
                  <Option value="EUR">EUR</Option>
                  <Option value="AUD">AUD</Option>
                  <Option value="USD">USD</Option>
                  <Option value="CAD">CAD</Option>
                  <Option value="GBP">GBP</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 ">
              <Form.Item
                name="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter passowrd",
                  },
                ]}
              >
                <Input
                  placeholder={t("Password-placeholder")}
                  className={`${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  prefix={<img src={Images.lock} alt="" />}
                />
              </Form.Item>
            </div>
            <div className="col-12 ">
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
              >
                <Input
                  placeholder={t("Confirm-password-placeholder")}
                  className={`${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  prefix={<img src={Images.lock} alt="" />}
                />
              </Form.Item>
            </div>
            <div className="col-12 pb-2">
              <Form.Item
                name="PromoCode"
                rules={[
                  {
                    required: false,
                    message: "Please input your Promo code.!",
                  },
                ]}
              >
                <Input
                  placeholder={t("PromoCode")}
                  className={`${
                    themeMode === "light"
                      ? "white-content-bg"
                      : "login-body-input"
                  }`}
                  prefix={<img src={Images.discount} alt="" />}
                />
              </Form.Item>
            </div>
            <div className="col-12 pb-3">
              <Checkbox
                className={`${
                  themeMode === "light"
                    ? "white-content-bg"
                    : "login-body-input"
                }`}
                checked={termsAndConditions}
                onChange={() => setTermsAndConditions((prev) => !prev)}
              >
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: t("AcceptTerms") }}
                ></div>
              </Checkbox>
            </div>
            {errMsg !== "" && <div className="error-message">{errMsg}</div>}
            <button className="w-100">
              <span>{t("Sign Up")}</span>
            </button>
          </Form>
        </div>
    </>
  );
};

export default Step3;
